import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('bankorder', {
      params: query 
    })
  },
  show (bankorderId) {
    return Api().get(`bankorder/show/${bankorderId}`)
  },
  post (newbankorder) {
    return Api().post('bankorder', newbankorder)
  },
  saveitems(items) {
    if (items) {
      return Api().post('bankorder/saveitems', items)
    }
    return
  },
  put (bankorder) {
    return Api().put(`bankorder/${bankorder.id}`, bankorder)
  },
  delete (bankorderId) {
    return Api().delete(`bankorder/${bankorderId}`)
  },
  deletebatch (salarieID) {
    return Api().delete(`bankorder/deletebatch/${salarieID}`)
  },
  createboform(data) {
    return Api().post(`bankorder/createboform`, data)
  },
  uploadboform(query) {
    return Api().get(`bankorder/uploadboform`, {
      params: query
    }, {
      responseType: 'blob'
    })
  },
}