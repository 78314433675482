<template>

  <v-container fluid class="grey lighten-4">
        <v-row dense>
            <v-col cols="12" sm="2" md="4" lg="3" xl="3">
              <h1>{{lang.SalarieDetail}} {{Month}}/{{Year}} - {{Order}}</h1>
            </v-col>

            <v-col cols="12" sm="2" md="2" lg="2" xl="2">

            </v-col>
            <v-col cols="12" sm="2" md="2" lg="2" xl="2">
              <v-btn text color="red" @click="salarieDelete" class="mr-1">
                    {{langC.Delete}}
                </v-btn>
            </v-col>
            <v-col cols="12" sm="1" md="1" lg="1" xl="1" >
            </v-col>
            <v-col cols="12" sm="1" md="1" lg="1" xl="1" class="text-right">
               
            </v-col>
            <v-col cols="12" sm="1" md="1" lg="1" xl="1" >
               
            </v-col>
             <v-col cols="12" sm="1" md="1" lg="1" xl="1"> 
              <v-btn text color="blue" @click="saveData" class="mr-1">
                  {{langC.Save}}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="1" md="1" lg="1" xl="1">
              <v-btn color="green" @click="salarielist" class="mr-1">
                  {{langC.Back}}
              </v-btn>
           </v-col>
           <v-progress-circular v-if="this.saving"
              indeterminate
              color="green"
            ></v-progress-circular>
        </v-row>
        <v-row>
          <v-col cols="12" xd="12" sm="12" md="2" lg="3" xl="3">
              <v-card
                class="mx-auto"
                max-width="500"
                >
                    <v-toolbar
                      color="indigo"
                      dark
                    >
                      <v-toolbar-title>Punjenje</v-toolbar-title>
                    </v-toolbar>
                    <v-row dense>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                           <v-row class="mt-3 pr-4" dense>
                              <v-col cols="12" xs="7" sm="7" md="7" lg="7" xl="7" class="ml-2">
                                {{selectType.text}} - {{Month}}/{{Year}} - {{Order}}
                              </v-col>
                              <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4" class="ml-2">
                                 <v-text-field class="ma-0"
                                    label="Naziv dodatni"
                                    v-model="salarie.FreeF8"
                                  ></v-text-field>
                              </v-col>
                          </v-row>
                           <v-row dense>
                              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <v-btn text color="blue" @click="salarieFillAll(null, 'activ')" class="mr-1" :disabled="locked">
                                  Napuni SVE akt.zap.
                                </v-btn>
                              </v-col>
                           </v-row>
                           <v-row dense>
                              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <v-btn text color="blue" @click="salarieFillAll(null, 'black')" class="mr-1" :disabled="locked">
                                  Napuni SVE Honorarne
                                </v-btn>
                              </v-col>
                           </v-row>
                           <v-row dense>
                              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-2">
                                <v-autocomplete
                                  :items="allEmp"
                                  v-model="selectEmp"
                                  :label="lang.SelectEmployee"
                                  item-text="employeeFullName"
                                  item-value="id"
                                  return-object
                                  persistent-hint
                                  clearable
                                ></v-autocomplete>
                              </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                              <v-btn text color="blue" @click="salarieFillOne" class="mr-1" :disabled="locked">
                                Napuni pojedniog
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                    </v-row>
              </v-card>

          </v-col>

          <v-col cols="12" xd="12" sm="12" md="3" lg="3" xl="3">
             <v-card
                class="mx-auto"
                max-width="500"
                >
                    <v-toolbar
                      color="green"
                      dark
                    >
                    <v-toolbar-title>Obrada</v-toolbar-title>
                    </v-toolbar>
                    <v-row dense class="pt-4 pb-0">
                        <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" class="pl-2">
                            Isplata sa računa:
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" class="pr-2">
                          <v-autocomplete
                            :items="banks"
                            v-model="selectBank"
                            label="Banka isplate"
                            item-text="BankName"
                            item-value="id"
                            return-object
                            persistent-hint
                          ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" class="pl-2">
                          Virman datum:
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                          <input type="date" v-model="salarie.bankPaymentDate" />
                        </v-col>
                    </v-row>
                     <v-row dense>
                        <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" class="pl-2">
                          JOPP datum:
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                          <input type="date" v-model="salarie.JoppdDate" />
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="mr-4">
                          <v-btn  color="pink" @click="preDoTotal"  >
                            OBRAČUAJ TOTAL
                          </v-btn>
                        </v-col>
                        <v-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5" class="pl-4">
                          <v-text-field class="pr-3"
                            label="BrojJoppd"
                            v-model="salarie.BrojJoppd"
                            disabled
                          ></v-text-field>
                        </v-col>
                    </v-row>   
                    <v-row dense>
                        <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
                          <v-btn text color="green" @click="salarieprint" class="mr-1" >
                              {{langC.Print}}
                          </v-btn> 
                        </v-col>
                         <v-col cols="12" xs="" sm="4" md="4" lg="4" xl="4">
                          <v-btn text color="green" @click="salarieprintall" class="mr-1" >
                            Print SVI
                          </v-btn> 
                        </v-col>
                        <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
                          <v-switch
                            v-model="printGerman"
                            label="de"
                            class="pt-1 ma-0"
                          ></v-switch>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
                          <v-btn text color="blue" @click="displayBankOrders" class="mr-1">
                            Virmani
                          </v-btn>
                        </v-col>
                         <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
                          <v-btn text color="green" @click="salarieprintsigns(1)" class="mr-1" >
                            Potpis
                          </v-btn> 
                         </v-col>
                          <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
                          <v-btn text color="green" @click="salarieprintsigns(2)" class="mr-1" >
                            Lista
                          </v-btn>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
                          <v-btn text color="blue" @click="salarieprintsigns(3)" class="mr-1">
                            Sintetika
                          </v-btn>
                        </v-col>
                         <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
                          <v-btn text color="green" @click="salarieprintsigns(4)" class="mr-1" >
                            Sin.Excel
                          </v-btn> 
                         </v-col>
                          <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4">

                        </v-col>
                    </v-row>
             </v-card>
          </v-col>
          <v-col cols="12" xd="12" sm="12" md="4" lg="3" xl="3">

               <v-card
                class="mx-auto"
                max-width="500"
                >
                    <v-toolbar
                      color="deep-purple"
                      dark
                    >
                      <v-toolbar-title>Bilješke</v-toolbar-title>
                    </v-toolbar>
                    <v-row dense>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pt-5">
                          <v-btn text color="primary" @click="showNote1()" class="pb-3 pl-0">Bilješka <v-icon v-if="!showNote">mdi-chevron-down</v-icon><v-icon v-if="showNote">mdi-chevron-up</v-icon></v-btn>
                          <v-textarea
                            background-color="amber lighten-5"
                            label="Billješka"
                            v-model="salarie.Notes"
                            v-if="showNote"
                          ></v-textarea>
                        </v-col>
                    </v-row>
                     <v-row dense>
                        <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                          <v-checkbox
                            v-model="salarie.JoppdPoslan"
                            label="Jopp poslan"
                            class="pa-0 ma-0"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                          <input type="date" v-model="salarie.JoppdPoslanDate" />
                        </v-col>
                    </v-row>
                     <v-row dense>
                        <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                           <v-checkbox
                            v-model="salarie.NetoIsplacen"
                            label="Neto isplaćen"
                            class="pa-0 ma-0"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                          <input type="date" v-model="salarie.NetoIsplaceDate" />
                        </v-col>
                    </v-row>
                     <v-row dense>
                      <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                         <v-checkbox
                            v-model="salarie.DoprinosiPlaceni"
                            label="Doprinosi plaćeni"
                            class="pa-0 ma-0"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                          <input type="date" v-model="salarie.DoprinosiPlaceniDate" />
                        </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" xs="6" sm="6" md="9" lg="9" xl="9">
                         <v-btn text color="primary" @click="finallTax()" class="pb-3 pl-0" v-if="Month === 11">Godišnji obračun poreza napravi</v-btn>
                      </v-col>
                      <v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3">
  
                        </v-col>
                    </v-row>
             </v-card>
          </v-col>
          <v-col cols="12" xd="12" sm="12" md="3" lg="3" xl="3">
             <v-card
                class="mx-auto"
                max-width="500"
                >
                    <v-toolbar
                      color="orange"
                      dark
                    >
                      <v-toolbar-title>Digitalna arhiva</v-toolbar-title>
                    </v-toolbar>


                  <v-card class="pl-2 pr-2">
                      <DigitalDocShortList />
                  </v-card>
             </v-card>
          
          </v-col>

        </v-row>

        <v-row v-if="showBankOrder">
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <BankOrderIndex virmanFor="salarie"/>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <SalarieDetailList :key="refresh" :locked="locked"/>
          </v-col>
        </v-row>

       
        <v-dialog
          v-model="showAlert"
          max-width="290"
        >
          <v-card>
            <v-card-title class="headline">
             {{lang.Question1}}
            </v-card-title>

            <v-card-text>
              {{lang.Question2}}
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="showAlert = false"
              >
                {{langC.Cancel}}
              </v-btn>

              <v-btn
                color="blue"
                text
                @click="haveAlert2()"
              >
                {{langC.Continue}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-row>
          <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
            <SalariePrintTotal ref="prnt" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
            <SalariePrintMonthAll ref="prnt2" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
            <SalariePrintSigns ref="prnt3" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
            <SalariePrintSignsList ref="prnt4" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
            <SalariePrintFinalTax ref="prnt5" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
            <SalariePrintSyntetic ref="prnt6" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
            <SalariePrintSynteticExcel ref="prnt7" />
          </v-col>
        </v-row>
        
        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          bottom
          :color="color"
          >
          {{ text }}
        </v-snackbar>
  </v-container>

</template>

<script>

import ledgerEN from './salarieDescEn'
import ledgerHR from './salarieDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import SalarieDetailList from './SalarieDetailList'
import SalarieService from '@/services/SalarieService'
import SalarieDetailService from '@/services/SalarieDetailService'
import SalarieTotalService from '@/services/SalarieTotalService'
import EmployeeService from '@/services/EmployeeService'
import CityService from '@/services/CityService'
import DigitalDocShortList from '../DigitalDoc/DigitalDocShortList'
import SalariePrintTotal from './SalariePrintTotal'
import SalariePrintFinalTax from './SalariePrintFinalTax'
import SalariePrintMonthAll from './SalariePrintMonthAll'
import SalariePrintSigns from './SalariePrintSigns'
import SalariePrintSignsList from './SalariePrintSignsList'
import SalariePrintSyntetic from './SalariePrintSyntetic'
import BankOrderIndex from '../BankOrder/BankOrderIndex'
import BankOrderService from '@/services/BankOrderService'
import BankAccountService from '@/services/BankAccountService'
import JoppdAService from '@/services/JoppdAService'
import JoppdBService from '@/services/JoppdBService'
import { parseNum, dynamicSortMultiple} from '@/snippets/allSnippets'
import {defBankOrder, defNewBankOrder, defJoppdA, defNewJoppdA, defJoppdB, defNewJoppdB} from './salarieHelper'
import dayjs from 'dayjs'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import moneyFormat from '@/snippets/moneyFormat'
import SalariePrintSynteticExcel from './SalariePrintSynteticExcel'

export default {
  name: 'salariedetail',
  data () {
    return {
      lang: {},
      langC: {},
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      msg: '',
      retPath: 'salarie',
      show: 1,
      showAlert: false,
      saving: false,
      error: '',
      mainQuery: {
        CompanyId: 1,
        MLType: 1,
      },
      mainQuery2: {},
      card: {},
      refresh: 0,
      allEmp: [],
      haveIt: 0,
      selectEmp: null,
      haveItems: [],
      aFrom: [],
      aTax: [],
      aAdd: [],
      aTo: [],
      aBankOrders: [],
      cities: [],
      Order: 0,
      Month: 0,
      Year: 0,
      showBankOrder: false,
      showJoppd: false,
      joppdAid: 0,
      joppdA: {},
      salarie: {},
      banks: [],
      selectBank: {},
      showNote: false,
      printGerman: false,
      allType: [],
      selectType: {},
      allYear: [],
      reportArr: [],
      currSal: [],
      allSalaries: [],
      locked: false,
      fullAdmin: false,
      notaxaddition: [],
      oneNotaxaddition: {},
      twoNotaxaddition: {},
      threeNotaxaddition: {},
      fourNotaxaddition: {},
      do2024: false,
      imam2025: false
    }
  },
  async mounted () {
    if (this.$store.state.user.Admin) {
      this.fullAdmin = this.$store.state.user.Admin
    }
    this.notaxaddition = this.$store.state.joppdNotax
    dayjs.extend(dayOfYear)
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    this.Order = this.$store.state.documentActivHead.Order
    this.Month = this.$store.state.documentActivHead.Month
    this.Year = this.$store.state.documentActivHead.Year

    this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
    this.allType = this.$store.state.salariesType ? this.$store.state.salariesType : []

    this.banks = (await BankAccountService.choose(this.mainQuery)).data.bankAccounts
    const {data} = await CityService.index(this.mainQuery)
      if (data && data.cities !== undefined){
        this.cities = data.cities
      }
   
    await EmployeeService.index(this.mainQuery)
    .then(res => {
      if (res.data && res.data.employees) {
        this.allEmp = res.data.employees
      }
    })
    .catch (err => {
      // eslint-disable-next-line
      console.log(err)
    })
    this.getData()
  },
  methods: {

    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    showNote1() {
      this.showNote = !this.showNote
    },
    async getData() {
      await SalarieService.show(this.$store.state.databaseDocID)
        .then(res => {
          this.salarie = res && res.data &&  res.data.salarie ? res.data.salarie : {}
          this.salarie.JoppdPoslanDate = this.salarie.JoppdPoslanDate ? this.salarie.JoppdPoslanDate.substring(0,10) : null
          this.salarie.NetoIsplaceDate = this.salarie.NetoIsplaceDate ? this.salarie.NetoIsplaceDate.substring(0,10) : null
          this.salarie.DoprinosiPlaceniDate = this.salarie.DoprinosiPlaceniDate ? this.salarie.DoprinosiPlaceniDate.substring(0,10) : null
          this.selectBank = this.salarie.bankAccountId ? this.banks.find(bank => (bank.id === parseInt(this.salarie.bankAccountId))) : null
          this.selectType = this.salarie.FreeF7 ? this.allType.find(type => (type.text === (this.salarie.FreeF7))) : null
          if (this.salarie.JoppdPoslan === true && this.salarie.NetoIsplacen === true && this.salarie.DoprinosiPlaceni === true) {
            this.locked = true
            // if (this.fullAdmin === false) {
            //   this.locked = true
            // }
          }
        })
        .catch(err => {
          console.log('no salaries ', err)
        })
    },
    forceRerender() {
      this.componentKey += 1;  
    },
    previtems() {
      this.havePrevious = !this.havePrevious
    },
    displayBankOrders() {
      this.showBankOrder = !this.showBankOrder
    },
    // displayJoppd () {
    //   console.log('this.joppdAid', this.joppdAid)
    //   if (this.joppdAid && this.joppdAid > 0)
    //   {
    //       this.showJoppd = !this.showJoppd

    //       this.$store.dispatch('seteditItem', 1)
    //       this.$store.dispatch('setretPath', 'joppda')
    //       this.$store.dispatch('setDocumentid', this.joppdAid)
    //       this.$router.push({
    //         name: 'joppdadetail'
    //       })
    //   }
    // },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },
    findBank (bankId) {
      if (bankId) {
        const locBank = bankId ? this.banks.find(bank => (bank.id === bankId)) : null
        this.salarie.bankAccountId = locBank.id ? parseInt(locBank.id) : null
        this.salarie.bankAccountName = locBank.BankName ? locBank.BankName : null
        this.salarie.bankAccountNr = locBank.IBAN ? locBank.IBAN : null
      }
    },
    async saveData () {
      // this.salarie.JoppdDate = new Date(this.salarie.JoppdDate)

      await SalarieService.put(this.salarie)
        .then(res => {
          const upSalarie = res && res.data &&  res.data.salarie ? res.data.salarie[0] : {}
          console.log('upSalarie', upSalarie)
          // this.salarie.JoppdPoslanDate = this.salarie.JoppdPoslanDate ? this.salarie.JoppdPoslanDate.substring(0,10) : null
          // this.salarie.NetoIsplaceDate = this.salarie.NetoIsplaceDate ? this.salarie.NetoIsplaceDate.substring(0,10) : null
          // this.salarie.DoprinosiPlaceniDate = this.salarie.DoprinosiPlaceniDate ? this.salarie.DoprinosiPlaceniDate.substring(0,10) : null
          this.$store.dispatch('setDocumentActivHead', this.salarie)
          this.$store.dispatch('setDatabaseDocID', this.salarie.id)
        })
        .catch(err => {
          console.log('no salaries ', err)
        })
    },
    async ftax1() {
        this.reportArr.splice(0, this.reportArr.length)
        this.allYear.splice(0, this.allYear.length)
        this.currSal.splice(0, this.currSal.length)
        this.allSalaries.splice(0, this.allSalaries.length)

        const query = {}
        query.CompanyId = this.$store.state.companyid
        query.databaseDocID = this.$store.state.documentActivHead.id
        const headRes = await SalarieDetailService.choose(query)
        this.currSal = headRes && headRes.data && headRes.data.salariedetails ? headRes.data.salariedetails : []
        const bYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2022
        query.fromDate = dayjs([bYear]).startOf('year').format('YYYY-MM-DD')
        query.toDate = dayjs([bYear]).endOf('year').format('YYYY-MM-DD')
        delete query.databaseDocID
        this.allSalaries = (await SalarieService.choose(query)).data.salaries
    },
    async fTax2() {
        if (this.allSalaries) {
           let results = this.allSalaries.map(async(salarie) => {
              const qry ={}
              qry.CompanyId = this.$store.state.companyid
              qry.databaseDocID = salarie && salarie.id ? salarie.id : 999999999
              const res2 = await SalarieDetailService.choose(qry)
              const detData = res2 && res2.data && res2.data.salariedetails ? res2.data.salariedetails : null
              if (detData) {
                this.allYear.push(...detData)
              }
            })
            results = await Promise.all(results)
          }
    },
    async finallTax() {
        await this.ftax1()
        await this.fTax2()
        const updateArr = []
        const totals = {}
        totals.FinTax = 0
        totals.Porez = 0
        totals.razlikaPoreza = 0
        if (this.currSal) {
          this.currSal.map(emp => {
            let FinBruto2 = 0
            let FinFrom1_Amount = 0
            let FinFrom2_Amount = 0
            let FinFrom3_Amount = 0
            let FinFrom4_Amount = 0
            let FinNeto1ls = 0
            let FinFreeTotalToUse = 0
            let FinTax1Am = 0
            let FinTax2Am = 0
            let Porez20 = 0
            let Porez30 = 0
            let FinTaxOnTaxAm = 0
            const onlyOne = this.allYear.filter((eemp) => parseInt(eemp.empID) === parseInt(emp.empID))
            if (onlyOne && onlyOne.length > 0) {
              let allNumbersPresent = true
               for(let i = 1; i<= 12; i++){
                const haveIt = onlyOne.find(obj => obj.Month === i)
                if (!haveIt) {
                  allNumbersPresent = false
                }
              }

              if (!allNumbersPresent) {
             //   console.log('nemam sve mjesece za ', emp.eemail,emp.First,emp.Last  )
                const reportItem = {}
                reportItem.empID = emp.empID
                reportItem.eemail = emp.eemail
                reportItem.First = emp.First
                reportItem.Last = emp.Last + ',? 12 mjeseci!!'
                this.reportArr.push(reportItem)
              }
  
              if (allNumbersPresent) {
                onlyOne.map(detEmp => {
                  // go calculate
                  FinBruto2 += this.round(parseNum(detEmp.FinBruto2), 2)
                  FinFrom1_Amount += this.round(parseNum(detEmp.FinFrom1_Amount),2)
                  FinFrom2_Amount += this.round(parseNum(detEmp.FinFrom2_Amount),2)
                  FinFrom3_Amount += this.round(parseNum(detEmp.FinFrom3_Amount),2)
                  FinFrom4_Amount += this.round(parseNum(detEmp.FinFrom4_Amount),2)
                  FinNeto1ls += this.round(parseNum(detEmp.FinNeto1ls),2)
                  FinFreeTotalToUse += this.round(parseNum(detEmp.FinFreeTotalToUse),2)
                  FinTax1Am += this.round(parseNum(detEmp.FinTax1Am),2)
                  FinTax2Am += this.round(parseNum(detEmp.FinTax2Am),2)
                  FinTaxOnTaxAm += this.round(parseNum(detEmp.FinTaxOnTaxAm), 2)
                })
                const DoprinosiIZTotal = FinFrom1_Amount + FinFrom2_Amount + FinFrom3_Amount + FinFrom4_Amount
                let OsnovicaZaPorez = FinNeto1ls - this.round(FinFreeTotalToUse,2)
                if (this.round(OsnovicaZaPorez,2) < 0) {
                  OsnovicaZaPorez = 0
                }

                if (OsnovicaZaPorez <= 50400) {
                  Porez20 = this.round((OsnovicaZaPorez * 0.2),2)
                }
                if (OsnovicaZaPorez > 50400) {
                  Porez20 = this.round((50400 * 0.2),2)
                  Porez30 = this.round(((OsnovicaZaPorez - 50400) * 0.3),2)
                }

                const stariPorez = this.round(((FinTax1Am + FinTax2Am) + FinTaxOnTaxAm),2)
                const noviPorez = this.round(((Porez20 + Porez30) + this.round(((Porez20 + Porez30) * (emp.FinTaxOnTaxPer / 100)),2)),2)
                const razlikaPoreza =  stariPorez - noviPorez

                const reportItem = {}
                reportItem.empID = emp.empID
                reportItem.eemail = emp.eemail
                reportItem.First = emp.First
                reportItem.Last = emp.Last
                reportItem.FinBruto2 = FinBruto2
                reportItem.DoprinosiIZTotal = DoprinosiIZTotal
                reportItem.FinNeto1ls = FinNeto1ls
                reportItem.FinFreeTotalToUse = FinFreeTotalToUse
                reportItem.FinTax1Am = this.round(FinTax1Am,2)
                reportItem.FinTax2Am = this.round(FinTax2Am,2)
                reportItem.Porez20 = this.round(Porez20,2)
                reportItem.Porez30 = this.round(Porez30,2)
                reportItem.StariPrirez = this.round(FinTaxOnTaxAm,2)
                reportItem.NoviPrirez = this.round(((Porez20 + Porez30) * (emp.FinTaxOnTaxPer / 100 )),2)
                reportItem.razlikaPoreza = this.round(razlikaPoreza,2)
                this.reportArr.push(reportItem)
                totals.FinTax += this.round((FinTax1Am + FinTax2Am)+ FinTaxOnTaxAm, 2)
                totals.Porez += this.round((noviPorez),2)
                totals.razlikaPoreza += this.round(razlikaPoreza,2)

             //   if (razlikaPoreza !== 0) {
                  //const opis = 'Godišnji porez, plać.:'+this.localMoney(FinTax1Am + FinTax2Am)+ ', obračunati:' + this.localMoney(Porez20 + Porez30) + ', Razlika:'+this.localMoney(razlikaPoreza)
                  //const jop62 = 99
                  const jop141 = this.round((razlikaPoreza),2)
                  
                  emp.FinTax3To = 0
                  //let isAdded = false
                  // za uplatu razlika poreza, treba ga dodati na porez
                  let addTaxReturn = 0
                  let newTotal = 0
                  if (this.round(jop141,2) < 0) {
                    emp.FinTax3To = Math.abs(jop141)
                    newTotal = (emp.FinTax1Am + emp.FinTax2Am + emp.FinTaxOnTaxAm) + Math.abs(jop141)
                  }
                  // za povrat porez, treba ga dodati na neto
                  if (this.round(jop141,2) > 0) {
                    emp.FinTax3To = Math.abs(jop141) * -1
                    addTaxReturn = Math.abs(jop141)
                  }
                  emp.FinTax1To = this.round(((Porez20 + Porez30) * (emp.FinTaxOnTaxPer / 100)),2) // novi prirez
                  emp.FreeF5 = this.round((Porez20 + Porez30),2) // novi porez
                  emp.FinTax2To = OsnovicaZaPorez
                  emp.FinTaxTotal = newTotal
                  emp.FinNeto2 = this.round((emp.FinNeto1ls - emp.FinTaxTotal + addTaxReturn), 2)
                  emp.FinNeto3 = this.round((emp.FinNeto2 + emp.FinAdd1_Amount  + emp.FinAdd2_Amount  + emp.FinAdd3_Amount  + emp.FinAdd4_Amount - (emp.Fin1LoanBankAmount) - (emp.Fin2LoanBankAmount) - (emp.Fin3LoanBankAmount)), 2)
                  emp.Fin1bankAmount = this.round((emp.Fin2bankAmount), 2) > 0 ? emp.FinNeto3 - this.round((emp.Fin2bankAmount), 2) : emp.FinNeto3
                  
                  updateArr.push(emp)
   
              // }

              }
            }
          })
        }
        if (updateArr) {
          console.log('imam update ARR',updateArr)
          let results = updateArr.map(async(emp)=> {
            await SalarieDetailService.put(emp)
          })
          results = await Promise.all(results)
        }
          for (let member in this.card) delete this.card[member]
          this.card = {...this.$store.state.documentActivHead}
          this.card.head1 = this.reportArr
          this.card.head2 = 'OBRAČUN GODIŠNJEG POREZA NA DOHODAK ZA GODINU ' + this.$store.state.documentActivHead.Year.toString()
          this.card.head3 = totals ? totals : {}
          this.card.head4 = null
          this.card.head5 = null

          await this.$store.dispatch('setPrintObj', this.card)
          await this.$refs.prnt5.print()

          this.text = 'Obračun godišnjeg poreza završen.'
          this.color = 'green'
          this.snackbar = true

    },
    async salarieFillAll (haveId, typeEmp) {
      if (this.$store.state.documentActivHead) {

        console.log('dodajem zaposlenika haveId', haveId, typeEmp)
        this.mainQuery = {}
        this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
        this.mainQuery.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear  : 2022
        this.mainQuery.Order = this.$store.state.documentActivHead.Order
        this.mainQuery.Month = this.$store.state.documentActivHead.Month
        this.mainQuery.Year = this.$store.state.documentActivHead.Year
        if (haveId) {
          this.mainQuery.employeeId = haveId
        }
        await this.fillCurrentSalarie()

        this.emplys = []
        if (this.$store.state.documentActivHead.id) {
          this.saving = true
          await EmployeeService.choose3(this.mainQuery)
          .then(async(res) => {
            if (res.data && res.data.employees) {
              
              if (res.data.employees && res.data.employees.length > 0) {
                res.data.employees.map(async(emp) => {
                  let addEmp = true
                  if (this.haveItems) {
                    const inIs = this.haveItems.find(item => parseInt(item.empID) === parseInt(emp.id))
                    if (inIs) {
                      addEmp = false
                    }
                  }
                  let addIt = false
                   if (emp.Activ && addEmp && !typeEmp) {
                    addIt = true
                  }
                  if (emp.Activ && addEmp && typeEmp === 'activ') {
                    addIt = true
                  }
                  if (emp.Black && addEmp) {
                    addIt = true
                  }
   
                  // if is activ then enter it
                  if (addIt) {
                    const empA = JSON.parse(JSON.stringify(emp))
                    empA.empID = empA.id
                    delete empA.id
                    empA.salarieID = this.$store.state.documentActivHead.id
                    empA.RedovanRad = this.$store.state.documentActivHead.HoursNormal
                    empA.BlagdanRad = this.$store.state.documentActivHead.HoursHoliday
                    empA.FinBruto0 = emp.FinBruto1 ? emp.FinBruto1 : 0
                    empA.FinWH = this.$store.state.documentActivHead.HoursNormal + this.$store.state.documentActivHead.HoursHoliday
                    empA.FinWHvalue = (this.$store.state.documentActivHead.HoursNormal + this.$store.state.documentActivHead.HoursHoliday) != 0 ? emp.FinBruto1 / (this.$store.state.documentActivHead.HoursNormal + this.$store.state.documentActivHead.HoursHoliday) : 0
                    empA.RedovanRadPosto = 100
                    empA.RedovanRadIznos = emp.FinBruto1 ? emp.FinBruto1 : 0
                    empA.Order = this.mainQuery.Order
                    empA.Month = this.mainQuery.Month
                    empA.Year = this.mainQuery.Year
                    empA.BusinessYear = this.yearSelected
                    const aa = this.mainQuery.Year.toString() + '-' + (this.mainQuery.Month < 10 ? '0' + this.mainQuery.Month.toString() : this.mainQuery.Month ) + '-01'
                    const salarieDate = dayjs(aa, 'YYYY-MM-DD').toDate()

                    let firstDay = this.Year + '-' + this.Month + '-01'
                    let toDate = (new Date(this.Year, parseInt(this.Month) , 0))
                     let lastDay = toDate.getFullYear() + '-' + ((toDate.getMonth() + 1).toString().length === 1 ? '0' + (toDate.getMonth() + 1).toString() :  (toDate.getMonth() + 1).toString()) + '-' + ((toDate.getDate()).toString().length === 1 ? '0' + (toDate.getDate()).toString() :  (toDate.getDate()).toString())

                    empA.StartInMonth = firstDay
                    empA.FinishInMonth = lastDay
                    console.log('tu trokira ', empA )

                    empA.Fin1bankAmount = emp.FinNeto3 ? emp.FinNeto3 : 0
                    if (emp.Fin2bankAccountNr1 && emp.Fin2bankAccountNr1.trim()>0) {
                      empA.Fin1bankAmount = emp.FinNeto3 ? this.round((emp.FinNeto3 / 3),2) : 0
                      empA.Fin2bankAmount = emp.FinNeto3 ? this.round(((emp.FinNeto3 / 3) * 2) ,2) : 0
                    }

                    if (empA.Fin1LoanLastDate) {
                      const endLoanDate = dayjs(empA.Fin1LoanLastDate, 'YYYY-MM-DD').toDate()
                      if (endLoanDate < salarieDate) {
                        empA.Fin1Loan = null
                        empA.Fin1LoanBankAccountId = null
                        empA.Fin1LoanBankAccountName = null
                        empA.Fin1LoanBankAccountNr1 = null
                        empA.Fin1LoanBankAccountNr2 = null
                        empA.Fin1LoanBankAccountNr3 = null
                        empA.Fin1LoanAmountFore = null
                        empA.Fin1LoanExRate = null 
                        empA.Fin1LoanBankAmount = null 
                        empA.Fin1LoanLastDate = null
                      }
                    }
                    if (empA.Fin2LoanLastDate) {
                      const endLoanDate = dayjs(empA.Fin2LoanLastDate, 'YYYY-MM-DD').toDate()
                      if (endLoanDate < salarieDate) {
                        empA.Fin2Loan = null
                        empA.Fin2LoanBankAccountId = null
                        empA.Fin2LoanBankAccountName = null
                        empA.Fin2LoanBankAccountNr1 = null
                        empA.Fin2LoanBankAccountNr2 = null
                        empA.Fin2LoanBankAccountNr3 = null
                        empA.Fin2LoanAmountFore = null
                        empA.Fin2LoanExRate = null 
                        empA.Fin2LoanBankAmount = null 
                        empA.Fin2LoanLastDate = null
                      }
                    }
                    if (empA.Fin3LoanLastDate) {
                      const endLoanDate = dayjs(empA.Fin3LoanLastDate, 'YYYY-MM-DD').toDate()
                      if (endLoanDate < salarieDate) {
                        empA.Fin3Loan = null
                        empA.Fin3LoanBankAccountId = null
                        empA.Fin3LoanBankAccountName = null
                        empA.Fin3LoanBankAccountNr1 = null
                        empA.Fin3LoanBankAccountNr2 = null
                        empA.Fin3LoanBankAccountNr3 = null
                        empA.Fin3LoanAmountFore = null
                        empA.Fin3LoanExRate = null 
                        empA.Fin3LoanBankAmount = null 
                        empA.Fin3LoanLastDate = null
                      }
                    }
                    const haveCity = this.cities.find(city => city.CityCode === emp.FinTaxOnTaxCityCode)
                    empA.FinTax1Per = haveCity && haveCity.FreeF1 ? haveCity.FreeF1 : 0
                    empA.FinTax2Per = haveCity && haveCity.FreeF2 ? haveCity.FreeF2 : 0
                    empA.FinFree1Indeks = 1
                    if (this.$store.state.documentActivHead.Month === 12 && this.$store.state.documentActivHead.Year === 2023) {
                      this.do2024 = true
                    }
                    if (this.$store.state.documentActivHead.Year === 2024) {
                      this.do2024 = true
                    }
                    if (this.$store.state.documentActivHead.Month === 12 && this.$store.state.documentActivHead.Year === 2024) {
                      this.imam2025 = true
                    }
                    if (this.$store.state.documentActivHead.Year >= 2025) {
                      this.imam2025 = true
                    }
                    if (this.do2024 || this.imam2025) {
                      let childAmount = 0
                      let childFaktor = 0
                      let osnovica = 560
                      if (this.imam2025 === true) {
                        osnovica = 600
                      }
                      if (emp.Child1) {
                        childAmount = this.round((0.5 * osnovica * (emp.ChildPer ? emp.ChildPer/100 : 0)),2)
                        childFaktor = 0.5
                      }
                      if (emp.Child2) {
                        childAmount += this.round((0.7 * osnovica * (emp.ChildPer ? emp.ChildPer/100 : 0)),2)
                        childFaktor += 0.7
                      }
                      if (emp.Child3) {
                        childAmount += this.round((1 * osnovica * (emp.ChildPer ? emp.ChildPer/100 : 0)),2)
                        childFaktor += 1
                      }
                      if (emp.Child4) {
                        childAmount += this.round((1.4 * osnovica * (emp.ChildPer ? emp.ChildPer/100 : 0)),2)
                        childFaktor += 1.4
                      }
                      //const basicAm = this.children.find(child => child.calc === 1)
                      if (osnovica) {
                        empA.FinFree2Indeks = childFaktor
                        empA.FinFree2OnAm = osnovica
                        empA.FinFree2Am = childAmount
                      }
                      empA.FinFreeTotal = this.round(emp.FinFree1OnAm, 2) + this.round(emp.FinFree2Am, 2) + this.round(emp.FinFree3Am, 2) + this.round(emp.FinFree4Am, 2)
                      empA.FinFreeTotalToUse = this.round((emp.FinFreeTotal * (emp.FinFreeTotalPer / 100)) ,2)
                    }
                    console.log(empA)
                    this.emplys.push(empA)
                    this.haveIt +=1
                  }
                })
              }
              
              if (this.emplys && this.emplys.length > 0) {
                await SalarieDetailService.saveitems(this.emplys)
                this.updateObj = this.$store.state.documentActivHead
                this.updateObj.NumberOfEmp = this.haveIt
                // this.updateObj.JoppdPoslanDate = this.updateObj.JoppdPoslanDate ? this.updateObj.JoppdPoslanDate.substring(0,10) : null
                // this.updateObj.NetoIsplaceDate = this.updateObj.NetoIsplaceDate ? this.updateObj.NetoIsplaceDate.substring(0,10) : null
                // this.updateObj.DoprinosiPlaceniDate = this.updateObj.DoprinosiPlaceniDate ? this.updateObj.DoprinosiPlaceniDate.substring(0,10) : null
                await SalarieService.put(this.updateObj)
                await this.doTotal()
                this.text = 'Napunjeno zaposlenika ' +  this.haveIt
                this.color = 'green'
                this.snackbar = true
                //alert('Napunjeno zaposlenika ', this.haveIt)
              }
              
            }
          })
          .catch (err => {
            // eslint-disable-next-line
            console.log(err)
          })
          this.saving = false
        }
        
        this.refresh += 1
      }
    },
    async salarieFillOne() {
      await this.fillCurrentSalarie()
      const alreadyIn = this.haveItems && this.haveItems.length > 0 ? (this.haveItems.find(item => item.empID === this.selectEmp.id)) : false
      if (alreadyIn === 'undefined'  || !alreadyIn) {
        this.salarieFillAll(this.selectEmp.id)
      }
      if (alreadyIn) {
        this.text = 'Greška! Odabrani radnik je već upisan u obračun plaće!'
        this.color = 'red'
        this.snackbar = true
      }
    },
    async fillCurrentSalarie () {
      this.haveItems = []
      this.mainQuery2 = {}
      this.mainQuery2.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
      this.mainQuery2.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2022
      this.mainQuery2.databaseDocID = this.$store.state.databaseDocID ? this.$store.state.databaseDocID : 0
      await SalarieDetailService.choose(this.mainQuery2)
      .then(res => {     
        this.haveItems = res && res.data && res.data.salariedetails ? res.data.salariedetails : []
      })
      .catch(err => {
        // eslint-disable-next-line
        console.log('Error ' + err)
      })
    },
    salarielist() {
     this.saveData()
     this.$router.push({
        name: 'salarie'
      })
    },
    async salarieprint() {
      const newObj = {}
      this.mainQuery2 = {...newObj}
      this.mainQuery2.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
      this.mainQuery2.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear  : 2022
      this.mainQuery2.Order = this.$store.state.documentActivHead.Order
      this.mainQuery2.Month = this.$store.state.documentActivHead.Month
      this.mainQuery2.Year = this.$store.state.documentActivHead.Year
      const resT = await SalarieTotalService.choose(this.mainQuery2)
      const totals = resT && resT.data && resT.data.salarietotals ? resT.data.salarietotals[0] : {}
      
      this.card = {...newObj}
      this.card = {...this.$store.state.documentActivHead}
      this.card.head1 = totals
      this.card.head2 = 'Broj JOPPD: '
      this.card.head3 = null
      this.card.head4 = null
      this.card.head5 = null
      await this.$store.dispatch('setPrintObj', this.card)
      await this.$refs.prnt.print()
    },
    async salarieprintall () {
      const newObj = {}
      this.mainQuery2 = {}
      this.mainQuery2.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
      this.mainQuery2.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2022
      this.mainQuery2.databaseDocID = this.$store.state.databaseDocID ? this.$store.state.databaseDocID : 0
      await SalarieDetailService.choose(this.mainQuery2)
      .then(res => {     
        this.haveItems = res && res.data && res.data.salariedetails ? res.data.salariedetails : []
      })
      .catch(err => {
        // eslint-disable-next-line
        console.log('Error ' + err)
      })

      this.card = {...newObj}
      this.card = {...this.$store.state.documentActivHead}
      this.card.head1 = this.haveItems ? this.haveItems : []
      this.card.head2 = 'Broj JOPPD: '
      this.card.head3 = null
      this.card.head4 = null
      this.card.head5 = null
      await this.$store.dispatch('setPrintObj', this.card)
      await this.$refs.prnt2.print(this.printGerman)

    },
    async salarieprintsigns (type) {
      const newObj = {}
      this.mainQuery2 = {}
      this.mainQuery2.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
      this.mainQuery2.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2024
      this.mainQuery2.databaseDocID = this.$store.state.databaseDocID ? this.$store.state.databaseDocID : 0
      await SalarieDetailService.choose(this.mainQuery2)
      .then(res => {     
        this.haveItems = res && res.data && res.data.salariedetails ? res.data.salariedetails : []
      })
      .catch(err => {
        // eslint-disable-next-line
        console.log('Error ' + err)
      })

      this.card = {...newObj}
      this.card = {...this.$store.state.documentActivHead}
      this.card.head1 = this.haveItems ? this.haveItems : []
      this.card.head2 = null
      this.card.head3 = null
      this.card.head4 = null
      this.card.head5 = null
      await this.$store.dispatch('setPrintObj', this.card)
      if (type === 1){
        await this.$refs.prnt3.print()
      }
      if (type === 2){
        await this.$refs.prnt4.print()
      }
      if (type === 3){
        await this.$refs.prnt6.print()
      }
      if (type === 4){
        await this.$refs.prnt7.exportExcel()
      }

    },
    async salarieDelete() {

      const warningMessage = this.$store.state.documentActivHead.Order + '/' + this.$store.state.documentActivHead.Month + '/' + this.$store.state.documentActivHead.Year
      if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!')) {
        this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
        this.mainQuery.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear  : 2022
        this.mainQuery.Order = this.$store.state.documentActivHead.Order
        this.mainQuery.Month = this.$store.state.documentActivHead.Month
        this.mainQuery.Year = this.$store.state.documentActivHead.Year
        if (this.$store.state.databaseDocID) {
          this.mainQuery.databaseDocID = this.$store.state.databaseDocID
        }
        const {data} = await SalarieService.delete(this.mainQuery.databaseDocID)
        if (data) {
          
          this.salarielist()
        }
        this.refresh += 1
      }
    },
    async joppdDelete() {
      this.joppdAid = null
      this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
      this.mainQuery.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear  : 2022
      this.mainQuery.Order = this.$store.state.documentActivHead.Order
      this.mainQuery.Month = this.$store.state.documentActivHead.Month
      this.mainQuery.Year = this.$store.state.documentActivHead.Year

      // delete if exist ... check first
      if (this.$store.state.documentActivHead.id) {
        this.mainQuery.salarieID = this.$store.state.documentActivHead.id
        const {data} = await JoppdAService.delete(this.$store.state.documentActivHead.id)
        if (data) {
          console.log('Obrisano joppd', data)
        }
      }
      
    },
    async virmaniDelete() {
      this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
      this.mainQuery.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear  : 2022
      this.mainQuery.Order = this.$store.state.documentActivHead.Order
      this.mainQuery.Month = this.$store.state.documentActivHead.Month
      this.mainQuery.Year = this.$store.state.documentActivHead.Year

      // delete if exist ... check first
      if (this.$store.state.documentActivHead.id) {
        const {data} = await BankOrderService.deletebatch(this.$store.state.documentActivHead.id)
        if (data) {
          console.log('Obrisani virmani', data)
        }
      }
      
    },

    async joppdNew() {
      const newA = defJoppdA()
      const head = this.$store.state.documentActivHead
      head.DatumIzvjesca = this.salarie.JoppdDate
      head.OznakaIzvjesca = this.salarie.BrojJoppd
      this.$store.dispatch('setDocumentActivHead', head)
      const newJoppdA = {...newA, ...defNewJoppdA()}
      newJoppdA.JoppdDate = this.JoppdDate
      newJoppdA.JoppdDate = this.JoppdDate
      

      const {data} = await JoppdAService.post(newJoppdA)
      if (data) {
        if (data.newJoppdA && data.newJoppdA.id) {
          this.joppdAid = data.newJoppdA.id
          this.joppdA = data.newJoppdA
        }
      }
    },
    preDoTotal() {
      if (this.locked) {
        if (confirm('Obračun je zaključen. Da li ste stvarno sigurni da želite ponovno napraviti obračun!')) {
          this.doTotal()
        }
      }
      if (!this.locked) {
        this.doTotal()
      }
    },
    async doTotal() {
      this.showBankOrder = false
      // if (this.locked) {
      //   alert('Obračun je zaključan. Nisu moguće izmjene!')
      //   return
      // }

      // const haveYear = dayjs(this.salarie.BookingDate).year()
      // const invYear = dayjs(this.salarie.InvoiceDate).year()
      // const paymYear = dayjs(this.mainledger.InvoicePaymentDeadlineDate).year()
      
      // if (haveYear >= this.$store.state.businessYear) {
      //   this.text = 'Greška!! Godina u datumu knjiženja mora biti manja od tekuće poslovne godine !!'
      //   this.color = 'red'
      //   this.snackbar = true
      //   this.timeout = 3000
      //   return
      // }
      
      await this.saveData()

      await SalarieService.show(this.$store.state.databaseDocID)
      .then(res => {
        this.salarie = res && res.data &&  res.data.salarie ? res.data.salarie : {}
        this.salarie.JoppdPoslanDate = this.salarie.JoppdPoslanDate ? this.salarie.JoppdPoslanDate.substring(0,10) : null
        this.salarie.NetoIsplaceDate = this.salarie.NetoIsplaceDate ? this.salarie.NetoIsplaceDate.substring(0,10) : null
        this.salarie.DoprinosiPlaceniDate = this.salarie.DoprinosiPlaceniDate ? this.salarie.DoprinosiPlaceniDate.substring(0,10) : null
       // this.selectBank = this.salarie.bankAccountId ? this.banks.find(bank => (bank.id === parseInt(this.salarie.bankAccountId))) : null
      })
      .catch(err => {
        console.log('no salaries ', err)
      })

      if (!this.salarie.BrojJoppd)
      { 
        alert('GREŠKA!! Nije upisan broj JOPPDa. Obračun se NE MOŽE napraviti!!')
        return
      }
      if (!this.salarie.bankAccountId)
      { 
        alert('GREŠKA!! Nije određena banka isplate!! Obračun se NE MOŽE napraviti!!!')
        return
      }
      if (this.salarie.FreeF7 === null || this.salarie.FreeF7.length === 0) {
        alert('GREŠKA!! Nije određena vrsta obračuna!! Obračun se NE MOŽE napraviti!!!')
        return
      }
      console.log('prije delete this.$store.state.documentActivHead.BrojJoppd', this.$store.state.documentActivHead.BrojJoppd)
      await this.joppdDelete()
      await this.virmaniDelete()
    
      const tFinal = {}
      tFinal.FinBruto1 = 0
      tFinal.FinBruto2 = 0
      tFinal.FinNeto1ls = 0
      tFinal.FinTaxTotal = 0
      tFinal.FinAddTotal = 0
      tFinal.FinNeto2 = 0
      tFinal.FinNeto3 = 0
      tFinal.addTaxReturn = 0

      this.mainQuery2 = {}
      this.mainQuery2.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
      this.mainQuery2.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2022
      this.mainQuery2.databaseDocID = this.$store.state.databaseDocID ? this.$store.state.databaseDocID : 0
      this.mainQuery2.Order = this.$store.state.documentActivHead.Order
      this.mainQuery2.Month = this.$store.state.documentActivHead.Month
      this.mainQuery2.Year = this.$store.state.documentActivHead.Year
      
      const resT = await SalarieTotalService.choose(this.mainQuery2)
      const totals = resT && resT.data && resT.data.salarietotals ? resT.data.salarietotals[0] : {}

      
      await SalarieDetailService.choose(this.mainQuery2)
      .then(async(res) => {
        const tItems = res && res.data && res.data.salariedetails ? res.data.salariedetails : []
        if (tItems) {
          tItems.map(item => {
            if (item.FinBruto1) {
              tFinal.FinBruto1 += item.FinBruto1
            }
            if (item.FinBruto2) {
              tFinal.FinBruto2 += item.FinBruto2
            }
            if (item.FinNeto1ls) {
              tFinal.FinNeto1ls += item.FinNeto1ls
            }
            if (item.FinNeto2) {
              tFinal.FinNeto2 += item.FinNeto2
            }
            if (item.FinNeto3) {
              tFinal.FinNeto3 += item.FinNeto3
            }
            if (item.FinTaxTotal) {
              tFinal.FinTaxTotal += item.FinTaxTotal
            }
            if (item.FinAdd1_Amount) {
              tFinal.FinAddTotal += item.FinAdd1_Amount
            }
            if (item.FinAdd2_Amount) {
              tFinal.FinAddTotal += item.FinAdd2_Amount
            }
            if (item.FinAdd3_Amount) {
              tFinal.FinAddTotal += item.FinAdd3_Amount
            }
            if (item.FinAdd4_Amount) {
              tFinal.FinAddTotal += item.FinAdd4_Amount
            }
            if (item.FinAdd5_Amount) {
              tFinal.FinAddTotal += item.FinAdd5_Amount
            }
            if (item.NaHZZOiznos) {
              tFinal.FinAddTotal += item.NaHZZOiznos
            }
            if (item.FinTax3To && this.round(item.FinTax3To,2) < 0) {
              tFinal.addTaxReturn += Math.abs(this.round(item.FinTax3To,2))
            }
          })
        }
        await this.joppdNew()
         console.log('idem u virman 0')
        this.doTotalFrom(tItems)
         console.log('idem u virman 01')
        this.doTotalTax(tItems)
         console.log('idem u virman 02')
        this.doTotalNoTax(tItems)
         console.log('idem u virman 03')
        this.doTotalTo(tItems)
        console.log('idem u virman 1')
        await this.doBankOrderPeople(tItems)
        console.log('idem u joppd 2')
        await this.doNewJoppd(tItems)
        console.log('item u total 3')
        await this.doTotalAmounts(tItems)

      })
      .catch(err => {
        // eslint-disable-next-line
        console.log('Error ' + err)
      })

      tFinal.allFrom = this.aFrom ? [...this.aFrom] : []
      tFinal.allTo = this.aTo ? [...this.aTo] : []
      tFinal.allAdd = this.aAdd ? [...this.aAdd] : []
      tFinal.allTax = this.aTax ? [...this.aTax] : []
      console.log('tFinal', tFinal)

      const type = totals ? 1 : 2
      const toWrite = totals ? {...totals} : {...this.mainQuery2}
      toWrite.TotalJson = {...tFinal}
      if (type === 1) {
        await SalarieTotalService.put(toWrite)
      }
      if (type === 2) {
        await SalarieTotalService.post(toWrite)
      }

      this.text = 'Obračunata plaća.  ' 
      this.color = 'green'
      this.snackbar = true
    },
    async doTotalAmounts(tItems) {
      if (tItems && tItems.length > 0) {
        const allEmp = Array.from(tItems.reduce(
          (m, {empID, FinNeto3}) => m.set(empID, (m.get(empID) || 0) + parseNum(FinNeto3,2)), new Map
        ), ([empID, FinNeto3]) => ({empID, FinNeto3}));
          this.updateObj = this.$store.state.documentActivHead
          this.updateObj.NumberOfEmp = allEmp && allEmp.length ? allEmp.length : 0
          await SalarieService.put(this.updateObj)
        }
    },
    doTotalFrom (tItems) {

      // do from 
        const allFrom = []
        const groupFrom1 = Array.from(tItems.reduce(
          (m, {FinFrom1_id, FinFrom1_Amount}) => m.set(FinFrom1_id, (m.get(FinFrom1_id) || 0) + parseNum(FinFrom1_Amount,2)), new Map
        ), ([FinFrom1_id, FinFrom1_Amount]) => ({FinFrom1_id, FinFrom1_Amount}));

        const groupFrom2 = Array.from(tItems.reduce(
          (m, {FinFrom2_id, FinFrom2_Amount}) => m.set(FinFrom2_id, (m.get(FinFrom2_id) || 0) + parseNum(FinFrom2_Amount,2)), new Map
        ), ([FinFrom2_id, FinFrom2_Amount]) => ({FinFrom2_id, FinFrom2_Amount}));

        const groupFrom3 = Array.from(tItems.reduce(
          (m, {FinFrom3_id, FinFrom3_Amount}) => m.set(FinFrom3_id, (m.get(FinFrom3_id) || 0) + parseNum(FinFrom3_Amount,2)), new Map
        ), ([FinFrom3_id, FinFrom3_Amount]) => ({FinFrom3_id, FinFrom3_Amount}));

        const groupFrom4 = Array.from(tItems.reduce(
          (m, {FinFrom4_id, FinFrom4_Amount}) => m.set(FinFrom4_id, (m.get(FinFrom4_id) || 0) + parseNum(FinFrom4_Amount,2)), new Map
        ), ([FinFrom4_id, FinFrom4_Amount]) => ({FinFrom4_id, FinFrom4_Amount}));

        if (groupFrom1) {
          groupFrom1.map(fr1 => {
            if (fr1.FinFrom1_id > 0) {
              const selectedFrom = fr1.FinFrom1_id ? this.$store.state.salariesFrom.find(sfrom => sfrom.value === parseInt(fr1.FinFrom1_id)) : ''
              const addNew = {}
              addNew.FinFrom_id = fr1.FinFrom1_id
              addNew.FinFrom_Desc = selectedFrom.text
              addNew.descHR = selectedFrom.descHR
              addNew.per = selectedFrom.per
              addNew.bank = selectedFrom.bank
              addNew.bank01 = selectedFrom.bank01
              addNew.bank02 = selectedFrom.bank02
              addNew.FinFrom_Amount = fr1.FinFrom1_Amount

              const upFrom = allFrom.find(aFrom => aFrom.FinFrom_id === parseInt(fr1.FinFrom1_id))
              if (!upFrom){allFrom.push(addNew)}
              if (upFrom) {
                const lIndex = allFrom.indexOf(upFrom)
                allFrom[lIndex].FinFrom_Amount += fr1.FinFrom1_Amount
              }
            }
          })
        }

        if (groupFrom2) {
          groupFrom2.map(fr2 => {
            if (fr2.FinFrom2_id > 0) {
              const selectedFrom = fr2.FinFrom2_id ? this.$store.state.salariesFrom.find(sfrom => sfrom.value === parseInt(fr2.FinFrom2_id)) : ''
              const addNew = {}
              addNew.FinFrom_id = fr2.FinFrom2_id
              addNew.FinFrom_Desc = selectedFrom.text
              addNew.descHR = selectedFrom.descHR
              addNew.per = selectedFrom.per
              addNew.bank = selectedFrom.bank
              addNew.bank01 = selectedFrom.bank01
              addNew.bank02 = selectedFrom.bank02
              addNew.FinFrom_Amount = fr2.FinFrom2_Amount

              const upFrom = allFrom.find(aFrom => aFrom.FinFrom_id === parseInt(fr2.FinFrom2_id))
              if (!upFrom){allFrom.push(addNew)}
              if (upFrom) {
                const lIndex = allFrom.indexOf(upFrom)
                allFrom[lIndex].FinFrom_Amount += fr2.FinFrom2_Amount
              }
            }
          })
        }

        if (groupFrom3) {
          groupFrom3.map(fr3 => {
            if (fr3.FinFrom3_id > 0) {
              const selectedFrom = fr3.FinFrom3_id ? this.$store.state.salariesFrom.find(sfrom => sfrom.value === parseInt(fr3.FinFrom3_id)) : ''
              const addNew = {}
              if (selectedFrom) {
                addNew.FinFrom_id = fr3.FinFrom3_id
                addNew.FinFrom_Desc = selectedFrom.text ? selectedFrom.text : ''
                addNew.descHR = selectedFrom.descHR ? selectedFrom.descHR : ''
                addNew.per = selectedFrom.per
                addNew.bank = selectedFrom.bank
                addNew.bank01 = selectedFrom.bank01
                addNew.bank02 = selectedFrom.bank02
                addNew.FinFrom_Amount = fr3.FinFrom3_Amount
              }
              const upFrom = allFrom.find(aFrom => aFrom.FinFrom_id === parseInt(fr3.FinFrom3_id))
              if (!upFrom){allFrom.push(addNew)}
              if (upFrom) {
                const lIndex = allFrom.indexOf(upFrom)
                allFrom[lIndex].FinFrom_Amount += fr3.FinFrom3_Amount
              }
            }
          })
        }

        if (groupFrom4) {
          groupFrom4.map(fr4 => {
            if (fr4.FinFrom4_id > 0) {
              const selectedFrom = fr4.FinFrom4_id ? this.$store.state.salariesFrom.find(sfrom => sfrom.value === parseInt(fr4.FinFrom4_id)) : ''
              const addNew = {}
              if (selectedFrom) {
                addNew.FinFrom_id = fr4.FinFrom4_id
                addNew.FinFrom_Desc = selectedFrom.text ? selectedFrom.text : ''
                addNew.descHR = selectedFrom.descHR ? selectedFrom.descHR : ''
                addNew.per = selectedFrom.per ? selectedFrom.per : ''
                addNew.bank = selectedFrom.bank ? selectedFrom.bank : ''
                addNew.bank01 = selectedFrom.bank01
                addNew.bank02 = selectedFrom.bank02
                addNew.FinFrom_Amount = fr4.FinFrom4_Amount
              }
              const upFrom = allFrom.find(aFrom => aFrom.FinFrom_id === parseInt(fr4.FinFrom4_id))
              if (!upFrom){allFrom.push(addNew)}
              if (upFrom) {
                const lIndex = allFrom.indexOf(upFrom)
                allFrom[lIndex].FinFrom_Amount += fr4.FinFrom4_Amount
              }
            }
          })
        }
        this.aFrom = allFrom ? [...allFrom] : []
        this.doBankOrder(1)
    },
    doTotalTo (tItems) {
      // do to
        const allTo = []
        const groupTo1 = Array.from(tItems.reduce(
          (m, {FinTo1_id, FinTo1_Amount}) => m.set(FinTo1_id, (m.get(FinTo1_id) || 0) + parseNum(FinTo1_Amount,2)), new Map
        ), ([FinTo1_id, FinTo1_Amount]) => ({FinTo1_id, FinTo1_Amount}));

        const groupTo2 = Array.from(tItems.reduce(
          (m, {FinTo2_id, FinTo2_Amount}) => m.set(FinTo2_id, (m.get(FinTo2_id) || 0) + parseNum(FinTo2_Amount,2)), new Map
        ), ([FinTo2_id, FinTo2_Amount]) => ({FinTo2_id, FinTo2_Amount}));

        const groupTo3 = Array.from(tItems.reduce(
          (m, {FinTo3_id, FinTo3_Amount}) => m.set(FinTo3_id, (m.get(FinTo3_id) || 0) + parseNum(FinTo3_Amount,2)), new Map
        ), ([FinTo3_id, FinTo3_Amount]) => ({FinTo3_id, FinTo3_Amount}));

        if (groupTo1) {
          groupTo1.map(fr1 => {
            if (fr1.FinTo1_id > 0) {
              const selectedTo = fr1.FinTo1_id ? this.$store.state.salariesTo.find(sTo => sTo.value === parseInt(fr1.FinTo1_id)) : ''
              const addNew = {}
              addNew.FinTo_id = fr1.FinTo1_id
              addNew.FinTo_Desc = selectedTo.text
              addNew.descHR = selectedTo.descHR
              addNew.per = selectedTo.per
              addNew.bank = selectedTo.bank
              addNew.bank01 = selectedTo.bank01
              addNew.bank02 = selectedTo.bank02
              addNew.FinTo_Amount = fr1.FinTo1_Amount

              const upTo = allTo.find(aTo => aTo.FinTo_id === parseInt(fr1.FinTo1_id))
              if (!upTo){allTo.push(addNew)}
              if (upTo) {
                const lIndex = allTo.indexOf(upTo)
                allTo[lIndex].FinTo_Amount += fr1.FinTo1_Amount
              }
            }
          })
        }
        if (groupTo2) {
          groupTo2.map(fr2 => {
            if (fr2.FinTo2_id > 0) {
              const selectedTo = fr2.FinTo2_id ? this.$store.state.salariesTo.find(sTo => sTo.value === parseInt(fr2.FinTo2_id)) : ''
              const addNew = {}
              addNew.FinTo_id = fr2.FinTo2_id
              addNew.FinTo_Desc = selectedTo.text
              addNew.descHR = selectedTo.descHR
              addNew.per = selectedTo.per
              addNew.bank = selectedTo.bank
              addNew.bank01 = selectedTo.bank01
              addNew.bank02 = selectedTo.bank02
              addNew.FinTo_Amount = fr2.FinTo2_Amount

              const upTo = allTo.find(aTo => aTo.FinTo_id === parseInt(fr2.FinTo2_id))
              if (!upTo){allTo.push(addNew)}
              if (upTo) {
                const lIndex = allTo.indexOf(upTo)
                allTo[lIndex].FinTo_Amount += fr2.FinTo2_Amount
              }
            }
          })
        }
        if (groupTo3) {
          groupTo3.map(fr3 => {
            if (fr3.FinTo3_id > 0) {
              const selectedTo = fr3.FinTo3_id ? this.$store.state.salariesTo.find(sTo => sTo.value === parseInt(fr3.FinTo3_id)) : ''
              const addNew = {}
              addNew.FinTo_id = fr3.FinTo3_id
              addNew.FinTo_Desc = selectedTo.text
              addNew.descHR = selectedTo.descHR
              addNew.per = selectedTo.per
              addNew.bank = selectedTo.bank
              addNew.bank01 = selectedTo.bank01
              addNew.bank02 = selectedTo.bank02
              addNew.FinTo_Amount = fr3.FinTo3_Amount

              const upTo = allTo.find(aTo => aTo.FinTo_id === parseInt(fr3.FinTo3_id))
              if (!upTo){allTo.push(addNew)}
              if (upTo) {
                const lIndex = allTo.indexOf(upTo)
                allTo[lIndex].FinTo_Amount += fr3.FinTo3_Amount
              }
            }
          })
        }
        
        this.aTo = allTo ? [...allTo] : []
        this.doBankOrder(2)
    },
    doTotalTax (tItems) {
      
      // do tax 
        const allTax = []

        const groupTax = Array.from(tItems.reduce(
          (m, {FinTaxOnTaxCityCode, FinTaxTotal}) => m.set(FinTaxOnTaxCityCode, (m.get(FinTaxOnTaxCityCode) || 0) + parseNum(FinTaxTotal,2)), new Map
        ), ([FinTaxOnTaxCityCode, FinTaxTotal]) => ({FinTaxOnTaxCityCode, FinTaxTotal}));
        
        if (groupTax) {
          groupTax.map(fr1 => {
            if (fr1.FinTaxOnTaxCityCode > 0) {
              const selectedTax = fr1.FinTaxOnTaxCityCode ? this.cities.find(scity => scity.CityCode === fr1.FinTaxOnTaxCityCode) : ''
              const addNew = {}
              addNew.FinTax_CityCode = fr1.FinTaxOnTaxCityCode
              addNew.FinTax_CityName = selectedTax.CityName
              addNew.FinTax_CityIBAN = selectedTax.CityIBAN
              addNew.FinTax_CityBank = selectedTax.CityBank
              addNew.FinTaxTotal = fr1.FinTaxTotal

              const upTax = allTax.find(aTax => aTax.FinTax_CityCode === fr1.FinTaxOnTaxCityCode)
              if (!upTax){allTax.push(addNew)}
              if (upTax) {
                const lIndex = allTax.indexOf(upTax)
                allTax[lIndex].FinTaxTotal += fr1.FinTaxTotal
              }
            }
          })
        }
        
        this.aTax = allTax ? [...allTax] : []
        this.doBankOrder(3)
    },
    doTotalNoTax (tItems) {
      // do to

        const allAdd = []
        const groupAdd1 = Array.from(tItems.reduce(
          (m, {FinAdd1_TypeId, FinAdd1_Amount}) => m.set(FinAdd1_TypeId, (m.get(FinAdd1_TypeId) || 0) + parseNum(FinAdd1_Amount,2)), new Map
        ), ([FinAdd1_TypeId, FinAdd1_Amount]) => ({FinAdd1_TypeId, FinAdd1_Amount}));

        const groupAdd2 = Array.from(tItems.reduce(
          (m, {FinAdd2_TypeId, FinAdd2_Amount}) => m.set(FinAdd2_TypeId, (m.get(FinAdd2_TypeId) || 0) + parseNum(FinAdd2_Amount,2)), new Map
        ), ([FinAdd2_TypeId, FinAdd2_Amount]) => ({FinAdd2_TypeId, FinAdd2_Amount}));

        const groupAdd3 = Array.from(tItems.reduce(
          (m, {FinAdd3_TypeId, FinAdd3_Amount}) => m.set(FinAdd3_TypeId, (m.get(FinAdd3_TypeId) || 0) + parseNum(FinAdd3_Amount,2)), new Map
        ), ([FinAdd3_TypeId, FinAdd3_Amount]) => ({FinAdd3_TypeId, FinAdd3_Amount}));

        const groupAdd4 = Array.from(tItems.reduce(
          (m, {FinAdd4_TypeId, FinAdd4_Amount}) => m.set(FinAdd4_TypeId, (m.get(FinAdd4_TypeId) || 0) + parseNum(FinAdd4_Amount,2)), new Map
        ), ([FinAdd4_TypeId, FinAdd4_Amount]) => ({FinAdd4_TypeId, FinAdd4_Amount}));

        const groupAdd5 = Array.from(tItems.reduce(
          (m, {FinAdd5_TypeId, FinAdd5_Amount}) => m.set(FinAdd5_TypeId, (m.get(FinAdd5_TypeId) || 0) + parseNum(FinAdd5_Amount,2)), new Map
        ), ([FinAdd5_TypeId, FinAdd5_Amount]) => ({FinAdd5_TypeId, FinAdd5_Amount}));


        if (groupAdd1) {
          groupAdd1.map(fr1 => {
            if (fr1.FinAdd1_TypeId > 0 && fr1.FinAdd1_TypeId !== 99) {
              const selectedAdd = fr1.FinAdd1_TypeId ? this.$store.state.joppdNotax.find(sAdd => sAdd.value === parseInt(fr1.FinAdd1_TypeId)) : ''
              const addNew = {}
              addNew.FinAdd_id = fr1.FinAdd1_TypeId
              // addNew.FinAdd_Desc = fr1.FinAdd1_Desc
              addNew.text = selectedAdd.text
              addNew.per = selectedAdd.per
              addNew.FinAdd_Amount = fr1.FinAdd1_Amount

              const upAdd = allAdd.find(aAdd => aAdd.FinAdd_id === parseInt(fr1.FinAdd1_TypeId))
              if (!upAdd){allAdd.push(addNew)}
              if (upAdd) {
                const lIndex = allAdd.indexOf(upAdd)
                allAdd[lIndex].FinAdd_Amount += fr1.FinAdd1_Amount
              }
            }
          })
        }

        if (groupAdd2) {
          groupAdd2.map(fr1 => {
            if (fr1.FinAdd2_TypeId > 0 && fr1.FinAdd2_TypeId !== 99) {
              const selectedAdd = fr1.FinAdd2_TypeId ? this.$store.state.joppdNotax.find(sAdd => sAdd.value === parseInt(fr1.FinAdd2_TypeId)) : ''
              const addNew = {}
              addNew.FinAdd_id = fr1.FinAdd2_TypeId
              // addNew.FinAdd_Desc = fr1.FinAdd1_Desc
              addNew.text = selectedAdd.text
              addNew.per = selectedAdd.per
              addNew.FinAdd_Amount = fr1.FinAdd2_Amount

              const upAdd = allAdd.find(aAdd => aAdd.FinAdd_id === parseInt(fr1.FinAdd2_TypeId))
              if (!upAdd){allAdd.push(addNew)}
              if (upAdd) {
                const lIndex = allAdd.indexOf(upAdd)
                allAdd[lIndex].FinAdd_Amount += fr1.FinAdd2_Amount
              }
            }
          })
        }

        if (groupAdd3) {
          groupAdd3.map(fr1 => {
            if (fr1.FinAdd3_TypeId > 0 && fr1.FinAdd3_TypeId !== 99) {
              const selectedAdd = fr1.FinAdd3_TypeId ? this.$store.state.joppdNotax.find(sAdd => sAdd.value === parseInt(fr1.FinAdd3_TypeId)) : ''
              const addNew = {}
              addNew.FinAdd_id = fr1.FinAdd3_TypeId
              // addNew.FinAdd_Desc = fr1.FinAdd1_Desc
              addNew.text = selectedAdd.text
              addNew.per = selectedAdd.per
              addNew.FinAdd_Amount = fr1.FinAdd3_Amount

              const upAdd = allAdd.find(aAdd => aAdd.FinAdd_id === parseInt(fr1.FinAdd3_TypeId))
              if (!upAdd){allAdd.push(addNew)}
              if (upAdd) {
                const lIndex = allAdd.indexOf(upAdd)
                allAdd[lIndex].FinAdd_Amount += fr1.FinAdd3_Amount
              }
            }
          })
        }

        if (groupAdd4) {
          groupAdd4.map(fr1 => {
            if (fr1.FinAdd4_TypeId > 0 && fr1.FinAdd4_TypeId !== 99) {
              const selectedAdd = fr1.FinAdd4_TypeId ? this.$store.state.joppdNotax.find(sAdd => sAdd.value === parseInt(fr1.FinAdd4_TypeId)) : ''
              const addNew = {}
              addNew.FinAdd_id = fr1.FinAdd4_TypeId
              // addNew.FinAdd_Desc = fr1.FinAdd1_Desc
              addNew.text = selectedAdd.text
              addNew.per = selectedAdd.per
              addNew.FinAdd_Amount = fr1.FinAdd4_Amount

              const upAdd = allAdd.find(aAdd => aAdd.FinAdd_id === parseInt(fr1.FinAdd4_TypeId))
              if (!upAdd){allAdd.push(addNew)}
              if (upAdd) {
                const lIndex = allAdd.indexOf(upAdd)
                allAdd[lIndex].FinAdd_Amount += fr1.FinAdd4_Amount
              }
            }
          })
        }
        if (groupAdd5) {
          groupAdd5.map(fr1 => {
            if (fr1.FinAdd5_TypeId > 0 && fr1.FinAdd5_TypeId !== 99) {
              const selectedAdd = fr1.FinAdd5_TypeId ? this.$store.state.joppdNotax.find(sAdd => sAdd.value === parseInt(fr1.FinAdd5_TypeId)) : ''
              const addNew = {}
              addNew.FinAdd_id = fr1.FinAdd5_TypeId
              // addNew.FinAdd_Desc = fr1.FinAdd1_Desc
              addNew.text = selectedAdd.text
              addNew.per = selectedAdd.per
              addNew.FinAdd_Amount = fr1.FinAdd5_Amount

              const upAdd = allAdd.find(aAdd => aAdd.FinAdd_id === parseInt(fr1.FinAdd5_TypeId))
              if (!upAdd){allAdd.push(addNew)}
              if (upAdd) {
                const lIndex = allAdd.indexOf(upAdd)
                allAdd[lIndex].FinAdd_Amount += fr1.FinAdd5_Amount
              }
            }
          })
        }

        this.aAdd = allAdd ? [...allAdd] : []
    },
    async doBankOrder(params) {
      const vrstaIsplate = this.salarie.FreeF7 ? (this.allType.find(type => (type.text === (this.salarie.FreeF7)))).value : 0
      if(params === 1) {
        const empAr = []
        this.aBankOrders = [...empAr]
      }
      // from
      if(params === 1) {
        if (this.aFrom) {
          this.aFrom.map(item => {
            if (item.FinFrom_Amount > 0) {
              const newB = defBankOrder()
              const newBO = {...newB, ...defNewBankOrder()}
              newBO.PlatiteljIBAN = this.salarie.bankAccountNr
              newBO.PlatiteljModel = 'HR99'
              newBO.PlatiteljPoziv = ''
              newBO.bankAccountName = this.salarie.bankAccountName
              newBO.bankAccountNr = this.salarie.bankAccountNr
              newBO.DatumIzvrsenja = this.salarie.bankPaymentDate
              newBO.Opis1 = item.descHR
              newBO.Opis2 = item.per ? item.per : ''
              newBO.Opis3 = item.descHR
              newBO.Iznos = item.FinFrom_Amount
              newBO.Primatelj1 = (item.FinFrom_Desc ? item.FinFrom_Desc : '')
              newBO.PrimateljIBAN = item.bank
              newBO.PrimateljModel = item.bank01
              newBO.PrimateljPoziv = item.bank02 + this.$store.state.companyActiv.CompanyVATID + '-' + this.$store.state.documentActivHead.BrojJoppd
              newBO.Grupa = 1
              newBO.FreeF2 = 1
              this.aBankOrders.push(newBO)
            }
          })
        }
      }
      // to
      if(params === 2) {
        if (this.aTo) {
          this.aTo.map(item => {
            if (item.FinTo_Amount> 0) {
              const newB = defBankOrder()
              const newBO = {...newB, ...defNewBankOrder()}
              newBO.PlatiteljIBAN = this.salarie.bankAccountNr
              newBO.PlatiteljModel = 'HR99'
              newBO.PlatiteljPoziv = ''
              newBO.bankAccountName = this.salarie.bankAccountName
              newBO.bankAccountNr = this.salarie.bankAccountNr
              newBO.DatumIzvrsenja = this.salarie.bankPaymentDate
              newBO.Opis1 = item.descHR
              newBO.Opis2 = item.per ? item.per : ''
              newBO.Opis3 = item.descHR
              newBO.Iznos = item.FinTo_Amount
              if (item.FinTo_Desc) {
                newBO.Primatelj1 = item.FinTo_Desc === 'HEALTH1' || item.FinTo_Desc === 'HEALTH2' || item.FinTo_Desc === 'HEALTH3'  || item.FinTo_Desc === 'HEALTH4'? 'ZDRAVSTENO' :  item.FinTo_Desc 
              }
              newBO.PrimateljIBAN = item.bank
              newBO.PrimateljModel = item.bank01
              newBO.PrimateljPoziv = item.bank02 + this.$store.state.companyActiv.CompanyVATID + '-' + this.$store.state.documentActivHead.BrojJoppd
              newBO.Grupa = 1
              newBO.FreeF2 = 1
              this.aBankOrders.push(newBO)
            }
          })
        }
      }
      // tax
      if(params === 3) {
        if (this.aTax) {
          this.aTax.map(item => {
            if (item.FinTaxTotal > 0) {
              const newB = defBankOrder()
              const newBO = {...newB, ...defNewBankOrder()}
              newBO.PlatiteljIBAN = this.salarie.bankAccountNr
              newBO.PlatiteljModel = 'HR99'
              newBO.PlatiteljPoziv = ''
              newBO.bankAccountName = this.salarie.bankAccountName
              newBO.bankAccountNr = this.salarie.bankAccountNr
              newBO.DatumIzvrsenja = this.salarie.bankPaymentDate     
              newBO.Opis1 = 'Porez + prirez'
              newBO.Opis3 = 'Porez + prirez'
              newBO.Iznos = item.FinTaxTotal
              newBO.Primatelj1 = (item.FinTax_CityName ? item.FinTax_CityName : '') + ' ' + (item.FinTax_CityCode ? item.FinTax_CityCode : '')
              newBO.PrimateljIBAN = item.FinTax_CityIBAN
              newBO.PrimateljModel = 'HR68'
              if (vrstaIsplate === 0) { // neto placa
                newBO.PrimateljPoziv = '1880-' + this.$store.state.companyActiv.CompanyVATID + '-' + this.$store.state.documentActivHead.BrojJoppd
              }
              if (vrstaIsplate === 1) { // autorski 
                newBO.PrimateljPoziv = '1945-' + this.$store.state.companyActiv.CompanyVATID + '-' + this.$store.state.documentActivHead.BrojJoppd
              }

              newBO.Grupa = 1
              newBO.FreeF2 = 1
              this.aBankOrders.push(newBO)
            }
          })
        }
      }
    },
    async doBankOrderPeople (tItems) {
      const opisIsplate = this.salarie.FreeF7 ? this.salarie.FreeF7 : ' '
      const vrstaIsplate = this.salarie.FreeF7 ? (this.allType.find(type => (type.text === (this.salarie.FreeF7)))).value : 0
      if (tItems) {
        tItems.map(item => {
          let imaSamoBolovanje = false
          console.log('item.FinNeto2', item.FinNeto2, typeof item.FinNeto2, item.NaHZZOiznos)
          if (item.FinNeto2 === 0 && item.NaHZZOiznos && item.NaHZZOiznos > 0) {
            imaSamoBolovanje = true
          }
          if (this.round(item.Fin1bankAmount,2) > 0) {
            let NetoPlaca = item.Fin1bankAmount
            //console.log('NetoPlaca', NetoPlaca)            

            if (item.FinAdd1_TypeId === 12 || item.FinAdd2_TypeId === 12 || item.FinAdd3_TypeId === 12 || item.FinAdd4_TypeId === 12)
            {
              let Opis1 = ''
              let Iznos = 0
              if (item.FinAdd1_TypeId === 12)
              {
                Opis1 = item.FinAdd1_Desc
                Iznos = item.FinAdd1_Amount
                const Razlika = this.round((NetoPlaca - item.FinAdd1_Amount),2)
                NetoPlaca =  NetoPlaca && NetoPlaca > 0  && Razlika > 0 ? NetoPlaca - item.FinAdd1_Amount : item.FinAdd1_Amount
              }
              if (item.FinAdd2_TypeId === 12)
              {
                Opis1 = item.FinAdd2_Desc
                Iznos = item.FinAdd2_Amount
                const Razlika = this.round((NetoPlaca - item.FinAdd2_Amount),2)
                NetoPlaca =  NetoPlaca && NetoPlaca > 0  && Razlika > 0 ? NetoPlaca - item.FinAdd2_Amount : item.FinAdd2_Amount
              }
              if (item.FinAdd3_TypeId === 12)
              {
                Opis1 = item.FinAdd3_Desc
                Iznos = item.FinAdd3_Amount
                const Razlika = this.round((NetoPlaca - item.FinAdd3_Amount),2)
                NetoPlaca =  NetoPlaca && NetoPlaca > 0  && Razlika > 0 ? NetoPlaca - item.FinAdd3_Amount : item.FinAdd3_Amount
              }
              if (item.FinAdd4_TypeId === 12)
              {
                Opis1 = item.FinAdd4_Desc
                Iznos = item.FinAdd4_Amount
                const Razlika = this.round((NetoPlaca - item.FinAdd4_Amount),2)
                NetoPlaca =  NetoPlaca && NetoPlaca > 0  && Razlika > 0 ? NetoPlaca - item.FinAdd4_Amount : item.FinAdd4_Amount
              }
              if (imaSamoBolovanje === false) {
                const newB = defBankOrder()
                const newBO = {...newB, ...defNewBankOrder()}
                newBO.PlatiteljIBAN = this.salarie.bankAccountNr
                newBO.PlatiteljModel = 'HR67'
                newBO.PlatiteljPoziv = this.$store.state.companyActiv.CompanyVATID + '-' + this.salarie.BrojJoppd + '-0'
                newBO.bankAccountName = this.salarie.bankAccountName
                newBO.bankAccountNr = this.salarie.bankAccountNr
                newBO.DatumIzvrsenja = this.salarie.bankPaymentDate     
                newBO.Opis1 = Opis1
                newBO.Opis2 = item.Month.toString() + '/' + item.Year.toString() + '  (' + item.Order.toString() + ')'
                newBO.Opis3 = item.First + ' ' + item.Last
                newBO.Iznos = Iznos
                newBO.Primatelj1 = (item.Fin1bankAccountName ? item.Fin1bankAccountName : '')
                newBO.PrimateljIBAN = item.Fin1bankAccountNr1 ? item.Fin1bankAccountNr1.trim() : ''
                newBO.PrimateljModel = item.Fin1bankAccountNr2 ? item.Fin1bankAccountNr2.trim() : ''
                newBO.PrimateljPoziv = item.Fin1bankAccountNr3 ? item.Fin1bankAccountNr3.trim() : ''
                newBO.Grupa = 2
                newBO.Hitno = true
                newBO.FreeF2 = 1
                //console.log('newBO', newBO)
                this.aBankOrders.push(newBO)
              }
            }

            if (item.NaHZZOiznos && item.NaHZZOiznos > 0) {
              const Iznos = item.NaHZZOiznos
              const Razlika = this.round((NetoPlaca - item.NaHZZOiznos),2)
              const naP = item.Fin1bankAccountNr3 ? item.Fin1bankAccountNr3.trim() : ''
              const dugo = naP && (naP.length - 3) > 0 ? (naP.length - 3) : 0
              const noviNa = naP.substr(0,dugo) + '230'
              NetoPlaca =  NetoPlaca && NetoPlaca > 0  && Razlika > 0 ? Razlika : item.NaHZZOiznos
              const newB = defBankOrder()
              const newBO = {...newB, ...defNewBankOrder()}
              newBO.PlatiteljIBAN = this.salarie.bankAccountNr
              newBO.PlatiteljModel = 'HR67'
              newBO.PlatiteljPoziv = this.$store.state.companyActiv.CompanyVATID + '-' + this.salarie.BrojJoppd + '-0'
              newBO.bankAccountName = this.salarie.bankAccountName
              newBO.bankAccountNr = this.salarie.bankAccountNr
              newBO.DatumIzvrsenja = this.salarie.bankPaymentDate     
              newBO.Opis1 = 'Bolovanje preko 42 dana ' + item.First + ' ' + item.Last
              newBO.Opis2 = item.Month.toString() + '/' + item.Year.toString() + '  (' + item.Order.toString() + ')'
              newBO.Opis3 = item.First + ' ' + item.Last
              newBO.Iznos = Iznos
              newBO.Primatelj1 = (item.Fin1bankAccountName ? item.Fin1bankAccountName : '')
              newBO.PrimateljIBAN = item.Fin1bankAccountNr1 ? item.Fin1bankAccountNr1.trim() : ''
              newBO.PrimateljModel = item.Fin1bankAccountNr2 ? item.Fin1bankAccountNr2.trim() : ''
              newBO.PrimateljPoziv = noviNa
              newBO.Grupa = 2
              newBO.Hitno = true
              newBO.FreeF2 = 1
             // console.log('bolovanje newBO', newBO)
              this.aBankOrders.push(newBO)
            }
            // 
            

            if (NetoPlaca && NetoPlaca > 0 && ((item.Ukupno && item.Ukupno > 0) && vrstaIsplate === 0)) {
              const newB = defBankOrder()
              const newBO = {...newB, ...defNewBankOrder()}
              const Razlika = this.round((NetoPlaca - item.NaHZZOiznos),2)
              if (imaSamoBolovanje === false) {
                NetoPlaca =  NetoPlaca && NetoPlaca > 0  && Razlika > 0 ? NetoPlaca - (item.NaHZZOiznos && item.NaHZZOiznos> 0 ? item.NaHZZOiznos : 0) : NetoPlaca
                newBO.PlatiteljIBAN = this.salarie.bankAccountNr
                newBO.PlatiteljModel = 'HR67'
                newBO.PlatiteljPoziv = this.$store.state.companyActiv.CompanyVATID + '-' + this.salarie.BrojJoppd + '-0'
                newBO.bankAccountName = this.salarie.bankAccountName
                newBO.bankAccountNr = this.salarie.bankAccountNr
                newBO.DatumIzvrsenja = this.salarie.bankPaymentDate     
                newBO.Opis1 = 'Neto plaća ' + item.First + ' ' + item.Last
                newBO.Opis2 = item.Month.toString() + '/' + item.Year.toString() + '  (' + item.Order.toString() + ')'
                newBO.Opis3 = item.First + ' ' + item.Last
                newBO.Iznos = NetoPlaca
                newBO.Primatelj1 = (item.Fin1bankAccountName ? item.Fin1bankAccountName : '')
                newBO.PrimateljIBAN = item.Fin1bankAccountNr1 ? item.Fin1bankAccountNr1.trim() : ''
                newBO.PrimateljModel = item.Fin1bankAccountNr2 ? item.Fin1bankAccountNr2.trim() : ''
                newBO.PrimateljPoziv = item.Fin1bankAccountNr3 ? item.Fin1bankAccountNr3.trim() : ''
                newBO.Grupa = 2
                newBO.Hitno = true
                newBO.FreeF2 = 1
                console.log('newBO', newBO)
                this.aBankOrders.push(newBO)
              }
            }
            if (NetoPlaca && NetoPlaca > 0 && ((item.Ukupno && item.Ukupno > 0) && vrstaIsplate !== 0)) {
              const newB = defBankOrder()
              const newBO = {...newB, ...defNewBankOrder()}
              const Razlika = this.round((NetoPlaca - item.NaHZZOiznos),2)
              if (imaSamoBolovanje === false) {
                NetoPlaca =  NetoPlaca && NetoPlaca > 0  && Razlika > 0 ? NetoPlaca - (item.NaHZZOiznos && item.NaHZZOiznos> 0 ? item.NaHZZOiznos : 0) : NetoPlaca
                newBO.PlatiteljIBAN = this.salarie.bankAccountNr
                newBO.PlatiteljModel = 'HR67'
                newBO.PlatiteljPoziv = this.$store.state.companyActiv.CompanyVATID + '-' + this.salarie.BrojJoppd + '-0'
                newBO.bankAccountName = this.salarie.bankAccountName
                newBO.bankAccountNr = this.salarie.bankAccountNr
                newBO.DatumIzvrsenja = this.salarie.bankPaymentDate     
                newBO.Opis1 = opisIsplate + ' ' + item.First + ' ' + item.Last
                newBO.Opis2 = item.Month.toString() + '/' + item.Year.toString() + '  (' + item.Order.toString() + ')'
                newBO.Opis3 = item.First + ' ' + item.Last
                newBO.Iznos = NetoPlaca
                newBO.Primatelj1 = (item.Fin1bankAccountName ? item.Fin1bankAccountName : '')
                newBO.PrimateljIBAN = item.Fin1bankAccountNr1 ? item.Fin1bankAccountNr1.trim() : ''
                newBO.PrimateljModel = item.Fin1bankAccountNr2 ? item.Fin1bankAccountNr2.trim() : ''
                newBO.PrimateljPoziv = item.Fin1bankAccountNr3 ? item.Fin1bankAccountNr3.trim() : ''
                newBO.Grupa = 2
                newBO.Hitno = true
                newBO.FreeF2 = 1
                console.log('newBO', newBO)
                this.aBankOrders.push(newBO)
              }
            }
          }
          if (this.round(item.Fin2bankAmount,2) > 0) {
            const newB = defBankOrder()
            const newBO = {...newB, ...defNewBankOrder()}
            newBO.PlatiteljIBAN = this.salarie.bankAccountNr
            newBO.PlatiteljModel = 'HR67'
            newBO.PlatiteljPoziv = this.$store.state.companyActiv.CompanyVATID + '-' + this.salarie.BrojJoppd + '-0'
            newBO.bankAccountName = this.salarie.bankAccountName
            newBO.bankAccountNr = this.salarie.bankAccountNr
            newBO.DatumIzvrsenja = this.salarie.bankPaymentDate 
            newBO.Opis1 = 'Neto plaća - zaštićeni dio ' + item.First + ' ' + item.Last
            newBO.Opis2 = item.Month.toString() + '/' + item.Year.toString() + '  (' + item.Order.toString() + ')'
            newBO.Opis3 = item.First + ' ' + item.Last
            newBO.Iznos = item.Fin2bankAmount
            newBO.Primatelj1 = (item.Fin2bankAccountName ? item.Fin2bankAccountName : '')
            newBO.PrimateljIBAN = item.Fin2bankAccountNr1 ?item.Fin2bankAccountNr1.trim() :''
            newBO.PrimateljModel = item.Fin2bankAccountNr2 ? item.Fin2bankAccountNr2.trim() : ''
            newBO.PrimateljPoziv = item.Fin2bankAccountNr3 ? item.Fin2bankAccountNr3.trim() : ''
            newBO.Grupa = 2
            newBO.Hitno = true
            newBO.FreeF2 = 1
            this.aBankOrders.push(newBO)
          }

          if (this.round(item.Fin1LoanBankAmount,2) > 0) {
            const newB = defBankOrder()
            const newBO = {...newB, ...defNewBankOrder()}
            newBO.PlatiteljIBAN = this.salarie.bankAccountNr
            newBO.PlatiteljModel = 'HR67'
            newBO.PlatiteljPoziv = this.$store.state.companyActiv.CompanyVATID + '-' + this.salarie.BrojJoppd + '-0'
            newBO.bankAccountName = this.salarie.bankAccountName
            newBO.bankAccountNr = this.salarie.bankAccountNr
            newBO.DatumIzvrsenja = this.salarie.bankPaymentDate
            newBO.Opis1 = 'Obustava ' + item.First + ' ' + item.Last
            newBO.Opis2 = item.Fin1Loan ? item.Fin1Loan : ''
            newBO.Opis3 = 'Obustava 1' 
            newBO.Iznos = item.Fin1LoanBankAmount
            newBO.Primatelj1 = (item.Fin1LoanBankAccountName ? item.Fin1LoanBankAccountName : '')
            newBO.PrimateljIBAN = item.Fin1LoanBankAccountNr1 ? item.Fin1LoanBankAccountNr1.trim() : ''
            newBO.PrimateljModel = item.Fin1LoanBankAccountNr2 ? item.Fin1LoanBankAccountNr2.trim() : ''
            newBO.PrimateljPoziv = item.Fin1LoanBankAccountNr3 ? item.Fin1LoanBankAccountNr3.trim() : ''
            newBO.Grupa = 2
            newBO.Hitno = true
            newBO.FreeF2 = 1
            this.aBankOrders.push(newBO)
          }
          if (this.round(item.Fin2LoanBankAmount,2) > 0) {
            const newB = defBankOrder()
            const newBO = {...newB, ...defNewBankOrder()}
            newBO.PlatiteljIBAN = this.salarie.bankAccountNr
            newBO.PlatiteljModel = 'HR67'
            newBO.PlatiteljPoziv = this.$store.state.companyActiv.CompanyVATID + '-' + this.salarie.BrojJoppd + '-0'
            newBO.bankAccountName = this.salarie.bankAccountName
            newBO.bankAccountNr = this.salarie.bankAccountNr
            newBO.DatumIzvrsenja = this.salarie.bankPaymentDate
            newBO.Opis1 = 'Obustava' + item.First + ' ' + item.Last
            newBO.Opis2 = item.Fin2Loan ? item.Fin2Loan : ''
            newBO.Opis3 = 'Obustava 2'
            newBO.Iznos = item.Fin2LoanBankAmount
            newBO.Primatelj1 = (item.Fin2LoanBankAccountName ? item.Fin2LoanBankAccountName : '')
            newBO.PrimateljIBAN = item.Fin2LoanBankAccountNr1 ? item.Fin2LoanBankAccountNr1.trim() :''
            newBO.PrimateljModel = item.Fin2LoanBankAccountNr2 ? item.Fin2LoanBankAccountNr2.trim() : ''
            newBO.PrimateljPoziv = item.Fin2LoanBankAccountNr3 ? item.Fin2LoanBankAccountNr3.trim() : ''
            newBO.Grupa = 2
            newBO.Hitno = true
            newBO.FreeF2 = 1
            this.aBankOrders.push(newBO)
          }
          if (this.round(item.Fin3LoanBankAmount,2) > 0) {
            const newB = defBankOrder()
            const newBO = {...newB, ...defNewBankOrder()}
            newBO.PlatiteljIBAN = this.salarie.bankAccountNr
            newBO.PlatiteljModel = 'HR67'
            newBO.PlatiteljPoziv = this.$store.state.companyActiv.CompanyVATID + '-' + this.salarie.BrojJoppd + '-0'
            newBO.bankAccountName = this.salarie.bankAccountName
            newBO.bankAccountNr = this.salarie.bankAccountNr
            newBO.DatumIzvrsenja = this.salarie.bankPaymentDate
            newBO.Opis1 = 'Obustava' + item.First + ' ' + item.Last
            newBO.Opis2 = item.Fin3Loan ? item.Fin3Loan : ''
            newBO.Opis3 = 'Obustava 3'
            newBO.Iznos = item.Fin3LoanBankAmount
            newBO.Primatelj1 = (item.Fin3LoanBankAccountName ? item.Fin3LoanBankAccountName : '')
            newBO.PrimateljIBAN = item.Fin3LoanBankAccountNr1 ? item.Fin3LoanBankAccountNr1.trim() : ''
            newBO.PrimateljModel = item.Fin3LoanBankAccountNr2 ? item.Fin3LoanBankAccountNr2.trim() : ''
            newBO.PrimateljPoziv = item.Fin3LoanBankAccountNr3 ? item.Fin3LoanBankAccountNr3.trim() : ''
            newBO.Grupa = 2
            newBO.Hitno = true
            newBO.FreeF2 = 1
            this.aBankOrders.push(newBO)
          }
        })
        
      }

      if (this.aBankOrders && this.aBankOrders.length > 0) {
        const saved = await BankOrderService.saveitems(this.aBankOrders)
        console.log('saved bank orders ', saved)
      }
    },
    getAge (birthDate) { 
      return Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10) 
    },

// today is 2018-06-13
// getAge('1994-06-14') // 23
// getAge('1994-06-13') // 24

    async doNewJoppd (tItems) {
      const joppdB = []
      let order = 0
      let people = 0
      let doOnce = 0
      const vrstaIsplate = this.salarie.FreeF7 ? (this.allType.find(type => (type.text === (this.salarie.FreeF7)))).value : 0
      if (tItems) {
        tItems.map(item => {
          doOnce = 0
       //   console.log('JOPPD item', item)
          const currEmp = this.allEmp.find(emp => emp.id === item.empID)
          const placeOfLiveCode = currEmp.WorkInCityCode ? currEmp.WorkInCityCode : '0000'
          const jopp71 = currEmp.Joppd71Oznaka ? currEmp.Joppd71Oznaka : 0
          const age = currEmp.DateOfBirht ? this.getAge(currEmp.DateOfBirht) : null
          if (item.FinNeto3 > 0) {
            people += 1
            // let y = parseInt(this.salarie.JoppdDate.substr(0,4)), m =  parseInt(this.salarie.JoppdDate.substr(5,2)) - 1;
            // const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
            // const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0)

            const now = new Date();
            const currYear = now.getFullYear()

// const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
// console.log(firstDay); // 👉️ Sun Jan 01 2023 ...

// const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
            let firstDay = this.Year + '-' + this.Month + '-01'
            let toDate = (new Date(this.Year, parseInt(this.Month) , 0))
            let lastDay = toDate.getFullYear() + '-' + ((toDate.getMonth() + 1).toString().length === 1 ? '0' + (toDate.getMonth() + 1).toString() :  (toDate.getMonth() + 1).toString()) + '-' + ((toDate.getDate()).toString().length === 1 ? '0' + (toDate.getDate()).toString() :  (toDate.getDate()).toString())

           // let firstDay2 = this.Year + '-' + (this.Month + 1).toString() + '-01'
           // let toDate2 = (new Date(this.Year, parseInt(this.Month) + 1 , 0))
           // let lastDay2 = toDate2.getFullYear() + '-' + ((toDate2.getMonth() + 1).toString().length === 1 ? '0' + (toDate2.getMonth() + 1).toString() :  (toDate2.getMonth() + 1).toString()) + '-' + ((toDate2.getDate()).toString().length === 1 ? '0' + (toDate2.getDate()).toString() :  (toDate2.getDate()).toString())

            const syear = dayjs([this.Year]).startOf('year').format('YYYY-MM-DD')
            const eyear = dayjs([this.Year]).endOf('year').format('YYYY-MM-DD')

            const firstDay2 = dayjs().startOf('month').format('YYYY-MM-DD')
            const lastDay2 = dayjs().endOf('month').format('YYYY-MM-DD')

            const newB = defJoppdB()
            const newBO = {...newB, ...defNewJoppdB()} 


            order += 1
            newBO.JoppdAID = this.joppdAid
            newBO.salarieID = this.$store.state.documentActivHead.id
            newBO.P1 = order
            
            // check how many rows in joppd form need to filled
            if (item.FinAdd1_TypeId) {
              doOnce = 1
            }
            if (item.FinAdd2_TypeId) {
              doOnce = 2
            }
            if (item.FinAdd3_TypeId) {
              doOnce += 1
            }
            if (item.FinAdd4_TypeId) {
              doOnce += 1
            }
            // ako ima zavrsni obracun poreza 
            if (item.FinTax3To && this.round(item.FinTax3To,2) !== 0) {
              doOnce += 2
            }
            // if (doOnce > 1) {
            //   console.log('Imam vise od jedno doOnce', doOnce )
            // }

            // provjeriti this.allEmp
            
            newBO.P2 = item.FinTaxOnTaxCityCode
            newBO.P3 = item.FinTaxOnTaxCityCode
            newBO.P4 = item.VatID
            newBO.P5 = item.First + ' ' + item.Last
            if (vrstaIsplate === 0) { // placa
              newBO.P61 = age && age < 30 && item.FinTo1_Amount && item.FinTo1_Amount === 0 ? '0010' : '0001'
              newBO.P62 = '0001'
            }
            if (vrstaIsplate === 1) { // ugovor o djelu
              newBO.P61 = '4002'
              newBO.P62 = '4030'
            }
            
            newBO.P71 = jopp71
            newBO.P72 = 0
            if (item.Joppd72 && item.Joppd72 > 0) {
              newBO.P72 = item.Joppd72
            }
            if (vrstaIsplate === 0) { // placa
              newBO.P8 = 3
              newBO.P9 = parseInt(currEmp.WeeklyWorkHours) < 40 ?  2 : 1
            }
            if (vrstaIsplate === 1) { // ugovor o djelu
              newBO.P8 = 0
              newBO.P9 = 0
            }

            const radno1 = (item.RedovanRad ? item.RedovanRad : 0) + (item.NocniRad ? item.NocniRad : 0) + (item.PrekovremeniRad ? item.PrekovremeniRad : 0) + (item.NedjeljaRad ? item.NedjeljaRad : 0) + (item.BlagdanRad ? item.BlagdanRad : 0) + (item.TerenRad ? item.TerenRad : 0) + (item.PreraspodjelaRada ? item.PreraspodjelaRada : 0) + (item.Pripravnost ? item.Pripravnost : 0) + (item.DnevniOdmor ? item.DnevniOdmor : 0)+ (item.TjedniOdmor ? item.TjedniOdmor : 0)
            const radno2 = (item.NeradniBlagdan ? item.NeradniBlagdan : 0) + (item.GodisnjiOdmor ? item.GodisnjiOdmor : 0) + (item.BolovanjeDo42Dana ? item.BolovanjeDo42Dana : 0) + (item.BolovanjeOd42Dana ? item.BolovanjeOd42Dana : 0) + (item.Rodiljni ? item.Rodiljni : 0) + (item.PlaceniDopust ? item.PlaceniDopust : 0) + (item.NeplaceniDopust ? item.NeplaceniDopust : 0) + (item.NenazocnostNaZahtjevRadnika ? item.NenazocnostNaZahtjevRadnika : 0) + (item.ZastojKrivnjomPoslodavca ? item.ZastojKrivnjomPoslodavca : 0)+ (item.Strajk ? item.Strajk : 0) + (item.Lockout ? item.Lockout : 0)+ (item.VrijemeMirovanjaRadnogOdnosa ? item.VrijemeMirovanjaRadnogOdnosa : 0)+ (item.Dodatno1 ? item.Dodatno1 : 0)+ (item.Dodatno2 ? item.Dodatno2 : 0)
            newBO.P10 = Math.round(radno1) + Math.round(radno2)
            newBO.P100 = 0

            if (vrstaIsplate === 0) { // placa
              newBO.P101 = firstDay
              newBO.P102 = lastDay

              if (item.StartInMonth) {
                newBO.P101 = item.StartInMonth
              }
              if (item.FinishInMonth) {
                newBO.P102 = item.FinishInMonth
              }
            }
            if (vrstaIsplate === 1) { // ugovor o djelu
              newBO.P101 = syear
              newBO.P102 = eyear
            }


            // newBO.P101 = dayjs([this.salarie.JoppdDate]).startOf('month').format('YYYY-MM-DD')
            // newBO.P102 = dayjs([this.salarie.JoppdDate]).endOf('month').format('YYYY-MM-DD')
            newBO.P11 = item.FinBruto2
            newBO.P12 = item.FinBruto2
            newBO.P121 = item.FinFrom1_Amount
            newBO.P122 = item.FinFrom2_Amount
            newBO.P123 = item.FinTo1_Amount
            newBO.P124 = item.FinTo2_Amount
            // doprinos za zaposljavanja ?? 
            newBO.P125 = 0
            // beneficirnan staz mio ??
            newBO.P126 = 0

            if (item.FinTo1_id === 5 || item.FinTo1_id === 7) {
              newBO.P126 = item.FinTo1_Amount > 0 ? item.FinTo1_Amount : 0
            }
            if (item.FinTo2_id === 5 || item.FinTo1_id === 7) {
              newBO.P126 = item.FinTo2_Amount > 0 ? item.FinTo2_Amount : 0
            }
            if (item.FinTo3_id === 5 || item.FinTo1_id === 7) {
              newBO.P126 = item.FinTo3_Amount > 0 ? item.FinTo3_Amount : 0
            }
            
            // beneficirani staz mio II stup
            newBO.P127 = 0
             if (item.FinTo1_id === 6) {
              newBO.P127 = item.FinTo1_Amount > 0 ? item.FinTo1_Amount : 0
            }
            if (item.FinTo2_id === 6) {
              newBO.P127 = item.FinTo2_Amount > 0 ? item.FinTo2_Amount : 0
            }
            if (item.FinTo3_id === 6) {
              newBO.P127 = item.FinTo3_Amount > 0 ? item.FinTo3_Amount : 0
            }
            // zadravstvena zastita u inozemstvu
            newBO.P128 = 0
            // doprinos za zaposljavanje osoba s invalidetom 
            newBO.P129 = 0
             // od 2024 ide umanjenje osnovice za MIO 1 
            if (item.Joppd129 && item.Joppd129 > 0) {
              newBO.P129 = item.Joppd129
            }

            // izdatak ??
            newBO.P131 = 0
            // izdataka uplaceni doprinosi za mirovinsko osiguranje
            newBO.P132 = this.round(item.FinBruto2,2) - this.round(item.FinNeto1ls, 2)
            // dohodak
            newBO.P133 = item.FinNeto1ls
            //osobni odbitak
            newBO.P134 = this.round((item.FinFreeTotalToUse) ,2) > this.round((item.FinNeto1ls) ,2) ? this.round((item.FinNeto1ls) ,2) : this.round((item.FinFreeTotalToUse) ,2) 
            // porezna osnovica
            newBO.P135 = item.FinTax1From
            //porez
            newBO.P141 = item.FinTax1Am
            //prirez
            newBO.P142 = item.FinTaxOnTaxAm
            //oznaka neoporezivog
            newBO.P151 = item.FinAdd1_TypeId
            //iznos neoporezivog
            newBO.P152 = item.FinAdd1_Amount

            newBO.P161 = 1
            newBO.P162 = item.FinNeto2 + item.FinAdd1_Amount
            if (vrstaIsplate === 0) { // placa
              newBO.P17 = item.FinBruto2
            }
            if (vrstaIsplate === 1) { // ugovor o djelu
              newBO.P17 = 0
            }
            joppdB.push(newBO)
          

            if (item.NaHZZOsifra && item.NaHZZOsifra.length > 0) {
                const newB = defJoppdB()
                const newBO = {...newB, ...defNewJoppdB()} 
                order += 1
                newBO.JoppdAID = this.joppdAid
                newBO.salarieID = this.$store.state.documentActivHead.id
                newBO.P1 = order
                // provjeriti this.allEmp
                newBO.P2 = item.FinTaxOnTaxCityCode
                newBO.P3 = '0000'
                newBO.P4 = item.VatID
                newBO.P5 = item.First + ' ' + item.Last
                newBO.P61 = item.NaHZZOsifra
                newBO.P62 = '0000'
                newBO.P10 = item.BolovanjeOd42Dana
                newBO.P100 = item.BolovanjeOd42Dana
                newBO.P101 = item.NaHZZOstart
                newBO.P102 = item.NaHZZOfinish
                //ako je na porodiljskom onda je 0, a ako je na bolovanju duze od 42 dana onda je 12
                newBO.P151 = this.round(item.NaHZZOiznos,2) === 0 ? 0 : 12
                //iznos neoporezivog
                newBO.P152 = item.NaHZZOiznos
                newBO.P161 = 1
                newBO.P162 = item.NaHZZOiznos
                joppdB.push(newBO)
            }
            if (item.Porodiljnisifra && item.Porodiljnisifra.length > 0) {
                const newB = defJoppdB()
                const newBO = {...newB, ...defNewJoppdB()} 
                order += 1
                newBO.JoppdAID = this.joppdAid
                newBO.salarieID = this.$store.state.documentActivHead.id
                newBO.P1 = order
                // provjeriti this.allEmp
                newBO.P2 = item.FinTaxOnTaxCityCode
                newBO.P3 = '0000'
                newBO.P4 = item.VatID
                newBO.P5 = item.First + ' ' + item.Last
                newBO.P61 = item.Porodiljnisifra
                newBO.P62 = '0000'
                newBO.P101 = item.Porodiljnistart
                newBO.P102 = item.Porodiljnifinish
                //ako je na porodiljskom onda je 0, a ako je na bolovanju duze od 42 dana onda je 12
                newBO.P151 = 0
                //iznos neoporezivog
                newBO.P152 = 0
                newBO.P161 = 0
                newBO.P162 = 0
                joppdB.push(newBO)
            }

            if (doOnce > 1) {
              if (item.FinAdd2_TypeId && item.FinAdd2_TypeId > 0) {
                  const newB = defJoppdB()
                  const newBO = {...newB, ...defNewJoppdB()} 
                  order += 1
                  newBO.JoppdAID = this.joppdAid
                  newBO.salarieID = this.$store.state.documentActivHead.id
                  newBO.P1 = order
                  // provjeriti this.allEmp
                  newBO.P2 = item.FinTaxOnTaxCityCode
                  newBO.P3 = '0000'
                  newBO.P4 = item.VatID
                  newBO.P5 = item.First + ' ' + item.Last
                  newBO.P61 = '0000'
                  newBO.P62 = '0000'
                  newBO.P101 = firstDay2
                  newBO.P102 = lastDay2
                  //oznaka neoporezivog
                  newBO.P151 = item.FinAdd2_TypeId
                  //iznos neoporezivog
                  newBO.P152 = item.FinAdd2_Amount
                  newBO.P161 = 1
                  newBO.P162 = item.FinAdd2_Amount
                  joppdB.push(newBO)
              }
              if (item.FinAdd3_TypeId && item.FinAdd3_TypeId > 0) {
                  const newB = defJoppdB()
                  const newBO = {...newB, ...defNewJoppdB()} 
                  order += 1
                  newBO.JoppdAID = this.joppdAid
                  newBO.salarieID = this.$store.state.documentActivHead.id
                  newBO.P1 = order
                  // provjeriti this.allEmp
                  newBO.P2 = item.FinTaxOnTaxCityCode
                  newBO.P3 = '0000'
                  newBO.P4 = item.VatID
                  newBO.P5 = item.First + ' ' + item.Last
                  newBO.P61 = '0000'
                  newBO.P62 = '0000'
                  newBO.P101 = firstDay2
                  newBO.P102 = lastDay2
                  //oznaka neoporezivog
                  newBO.P151 = item.FinAdd3_TypeId
                  //iznos neoporezivog
                  newBO.P152 = item.FinAdd3_Amount
                  newBO.P161 = 1
                  newBO.P162 = item.FinAdd3_Amount
                  joppdB.push(newBO)
              }
              if (item.FinAdd4_TypeId && item.FinAdd4_TypeId > 0) {
                  const newB = defJoppdB()
                  const newBO = {...newB, ...defNewJoppdB()} 
                  order += 1
                  newBO.JoppdAID = this.joppdAid
                  newBO.salarieID = this.$store.state.documentActivHead.id
                  newBO.P1 = order
                  // provjeriti this.allEmp
                  newBO.P2 = item.FinTaxOnTaxCityCode
                  newBO.P3 = '0000'
                  newBO.P4 = item.VatID
                  newBO.P5 = item.First + ' ' + item.Last
                  newBO.P61 = '0000'
                  newBO.P62 = '0000'
                  newBO.P101 = firstDay2
                  newBO.P102 = lastDay2
                  //oznaka neoporezivog
                  newBO.P151 = item.FinAdd4_TypeId
                  //iznos neoporezivog
                  newBO.P152 = item.FinAdd4_Amount
                  newBO.P161 = 1
                  newBO.P162 = item.FinAdd4_Amount
                  joppdB.push(newBO)
              }
              // ako ima zavrsni obracun porez dodaj red
              if (item.FinTax3To !== 0) { 

                let l134 = 0
                let l135 = 0
                let l141 = 0
                let l142 = 0
                let l161 = 1
                let l162 = 0
                if (item.FinTax3To > 0) {
                  l134 = Math.abs(item.FinTax2To)
                  l135 = Math.abs(item.FinTax2To)
                  l141 = 0
                  l142 = 0
                  l162 = Math.abs(item.FinTax3To) * - 1
                }

                if (item.FinTax3To < 0) {
                  l134 = Math.abs(item.FinTax2To) * -1
                  l135 = Math.abs(item.FinTax2To) * -1
                  l141 = 0
                  l142 = 0
                  l162 = Math.abs(item.FinTax3To)

                }
                const newB = defJoppdB()
                const newBO = {...newB, ...defNewJoppdB()} 
                order += 1
                newBO.JoppdAID = this.joppdAid
                newBO.salarieID = this.$store.state.documentActivHead.id
                newBO.P1 = order
                newBO.P2 = item.FinTaxOnTaxCityCode
                newBO.P3 = placeOfLiveCode
                newBO.P4 = item.VatID
                newBO.P5 = item.First + ' ' + item.Last
                newBO.P61 = age && age < 30 ? '0010' : '0001'
                newBO.P62 = '0406'
                newBO.P10 = 0
                newBO.P101 = syear
                newBO.P102 = eyear
                newBO.P11 = 0
                newBO.P12 = 0
                newBO.P121 = 0
                newBO.P122 = 0
                newBO.P123 = 0
                newBO.P124 = 0
                newBO.P125 = 0
                newBO.P126 = 0
                newBO.P127 = 0
                newBO.P128 = 0
                newBO.P129 = 0
                newBO.P131 = 0
                newBO.P132 = 0
                newBO.P133 = 0
                newBO.P134 = l134
                newBO.P135 = l135
                newBO.P141 = l141
                newBO.P161 = l161
                newBO.P162 = l162

                newBO.P141 = item.FinTax3To
                if (item.FinTax3To > 0) {
                  newBO.P162 = item.FinTax3To
                }
                
                newBO.P17 = 0
                joppdB.push(newBO)
              }
            }
          }
        }) 
      }

      if (joppdB && joppdB.length > 0) {
        const saved = await JoppdBService.saveitems(joppdB)
        if (saved && saved.status === 200) {
          let zB1 = 0
          let zB2 = 0
          let zB3 = 0
          let zB4 = 0
          let zB5 = 0
          let zB6 = 0
          let zB7 = 0
          let zB8 = 0
          // ========================= prvi dio
          joppdB.map(jB => {
            if (jB.P61) {
              const Stjec = parseInt(jB.P61)
              if (Stjec >= 1 && Stjec <=99) {
                zB1 += jB.P141
                zB2 += jB.P142
              }
              if (Stjec === 201) {
                zB1 += jB.P141
                zB2 += jB.P142
              }
              if (Stjec === 5403) {
                zB1 += jB.P141
                zB2 += jB.P142
              }
              if (Stjec >= 101 && Stjec <=119) {
                zB3 += jB.P141
                zB4 += jB.P142
              }
              if (Stjec === 121) {
                zB3 += jB.P141
                zB4 += jB.P142
              }
            }
          })
          if (vrstaIsplate === 0 ) { // ide samo ako je placa 
            this.joppdA.PP_P11 = this.round(zB1,2) + this.round(zB2, 2)
            this.joppdA.PP_P12 = this.round(zB3,2) + this.round(zB4, 2)
            this.joppdA.PP_P1 = this.round(zB1,2) + this.round(zB2, 2) + this.round(zB3,2) + this.round(zB4, 2)
          }
          // ======================= drugi dio
          zB1 = 0
          zB2 = 0
          zB3 = 0
          zB4 = 0
          joppdB.map(jB => {
            if (jB.P61) {
              const Stjec = parseInt(jB.P61)
              if (Stjec >= 1001 && Stjec <=1009) {
                zB1 += jB.P141
                zB2 += jB.P142
              }
              if (Stjec >= 2001 && Stjec <= 2009) {
                zB3 += jB.P141
                zB4 += jB.P142
              }
              if (Stjec >= 3001 && Stjec <= 3009) {
                zB5 += jB.P141
                zB6 += jB.P142
              }
              if (Stjec >= 4001 && Stjec <= 4009) {
                zB7 += jB.P141
                zB8 += jB.P142
              }
              if (Stjec === 5501) {
                zB7 += jB.P141
                zB8 += jB.P142
              }
            }
          })
          this.joppdA.PP_P2 = this.round(zB1,2) + this.round(zB2, 2)
          this.joppdA.PP_P3 = this.round(zB3,2) + this.round(zB4, 2)
          this.joppdA.PP_P4 = this.round(zB5,2) + this.round(zB6, 2)
          this.joppdA.PP_P5 = this.round(zB7,2) + this.round(zB8, 2)
          // fali kako se puni !! this.joppdA.PP_P6 ??

          // ================  treci dio
          zB1 = 0
          zB2 = 0
          zB3 = 0
          zB4 = 0
          zB5 = 0
          zB6 = 0
          zB7 = 0
          zB8 = 0
          joppdB.map(jB => {
            if (jB.P61) {
              const Stjec = parseInt(jB.P61)

              // gs_p1
              if (Stjec >= 1 && Stjec <=3) {
                zB1 += jB.P121
              }
              if (Stjec >= 5 && Stjec <= 10) {
                zB1 += jB.P121
              }
              if (Stjec >= 21 && Stjec <= 29) {
                zB1 += jB.P121
              }
              if (Stjec >= 5701 && Stjec <= 5799) {
                zB1 += jB.P121
              }

              // gs_p2
              if (Stjec === 201) {
                zB2 += jB.P121
              }
              if (Stjec >= 4001 && Stjec <=4999) {
                zB2 += jB.P121
              }

              // gs_p3
              if (Stjec >= 31 && Stjec <=39) {
                zB3 += jB.P121
              }

              // gs_p4
              if (Stjec >= 5401 && Stjec <= 5403) {
                zB4 += jB.P121
              }
              if (Stjec >= 5608) {
                zB4 += jB.P121
              }

              // gs_p5
              if (Stjec >= 5302) {
                zB5 += jB.P121
              }
              if (Stjec >= 5501) {
                zB5 += jB.P121
              }
              if (Stjec >= 5604) {
                zB5 += jB.P121
              }
              if (Stjec >= 5606) {
                zB5 += jB.P121
              }
              // if (Stjec >= 5607) {
              //   zB5 += jB.P126
              // }

              // gs_p6
              zB6 += jB.P126 ? jB.P126 : 0

            }
          })
          this.joppdA.GS_P1 = this.round(zB1,2)
          this.joppdA.GS_P2 = this.round(zB2,2) 
          this.joppdA.GS_P3 = this.round(zB3,2) 
          this.joppdA.GS_P4 = this.round(zB4,2) 
          this.joppdA.GS_P5 = this.round(zB5,2) 
          this.joppdA.GS_P6 = this.round(zB6,2) 

        // ================  cetvrti dio
          zB1 = 0
          zB2 = 0
          zB3 = 0
          zB4 = 0
          zB5 = 0
          zB6 = 0
          zB7 = 0
          zB8 = 0
          joppdB.map(jB => {
            if (jB.P61) {
              const Stjec = parseInt(jB.P61)

              // ks_p1
              if (Stjec >= 1 && Stjec <= 3) {
                zB1 += jB.P122
              }
              if (Stjec >= 5 && Stjec <= 10) {
                zB1 += jB.P122
              }
              if (Stjec >= 21 && Stjec <= 29) {
                zB1 += jB.P122
              }
              if (Stjec >= 5701 && Stjec <= 5799) {
                zB1 += jB.P122
              }

              // ks_p2
              if (Stjec === 201) {
                zB2 += jB.P122
              }
              if (Stjec >= 4001 && Stjec <= 4999) {
                zB2 += jB.P122
              }

              // ks_p3
              if (Stjec >= 31 && Stjec <=39) {
                zB3 += jB.P122
              }

              // ks_p4
              if (Stjec >= 5101 && Stjec <= 5103) {
                zB4 += jB.P122
              }
              if (Stjec >= 5201 && Stjec <= 5299) {
                zB4 += jB.P122
              }
              if (Stjec === 5301) {
                zB4 += jB.P122
              }
              if (Stjec >= 5401 && Stjec <= 5403) {
                zB4 += jB.P122
              }
              if (Stjec === 5608) {
                zB4 += jB.P122
              }

              // ks_p5
              zB5 += jB.P127 ? jB.P127 : 0

            }
          })
          this.joppdA.KS_P1 = this.round(zB1,2)
          this.joppdA.KS_P2 = this.round(zB2,2) 
          this.joppdA.KS_P3 = this.round(zB3,2) 
          this.joppdA.KS_P4 = this.round(zB4,2) 
          this.joppdA.KS_P5 = this.round(zB5,2) 
          //this.joppdA.KS_P6 = 0 ??

          // ================  peti dio
          zB1 = 0
          zB2 = 0
          zB3 = 0
          zB4 = 0
          zB5 = 0
          zB6 = 0
          zB7 = 0
          zB8 = 0
          let zB9 = 0
          let zB10 = 0 
          let zP1 = 0
          let zP2 = 0
          let NeoporeziviPrimici = 0
          //let zP3 = 0

          joppdB.map(jB => {
            if (jB.P61) {
              const Stjec = parseInt(jB.P61)

              // ZO_P1
              if (Stjec === 1 ) {
                zB1 += jB.P123
              }
              if (Stjec === 5) {
                zB1 += jB.P123
              }
              if (Stjec === 8) {
                zB1 += jB.P123
              }
              if (Stjec === 9) {
                zB1 += jB.P123
              }

              if (Stjec >= 21 && Stjec <= 29) {
                zB1 += jB.P123
              }
              if (Stjec >= 5701 && Stjec <= 5799) {
                zB1 += jB.P123
              }

              // ZO_P2
               if (Stjec === 1 ) {
                zB2 += jB.P124
              }
              if (Stjec === 5) {
                zB2 += jB.P124
              }
              if (Stjec === 8) {
                zB2 += jB.P124
              }
              if (Stjec === 9) {
                zB2 += jB.P124
              }
              if (Stjec >= 21 && Stjec <= 29) {
                zB2 += jB.P124
              }
              if (Stjec >= 5701 && Stjec <= 5799) {
                zB2 += jB.P124
              }

              // ZO_P3
              if (Stjec >= 31 && Stjec <=39) {
                zB3 += jB.P123
              }

              // ZO_P4
              if (Stjec >= 31 && Stjec <=39) {
                zB4 += jB.P124
              }

              // ZO_P5
              if (Stjec === 201) {
                zB5 += jB.P123
              }
              if (Stjec === 4002) {
                zB5 += jB.P123
              }
              // ZO_P6
              if (Stjec >= 1 && Stjec <=39) {
                zB6 += jB.P128
              }
              if (Stjec >= 5001 && Stjec <= 5009) {
                zB6 += jB.P128
              }
              if (Stjec === 5402) {
                zB6 += jB.P128
              }

              // ZO_P7
              if (Stjec >= 101 && Stjec <= 119) {
                zB7 += jB.P123
              }

              // ZO_P8
              if (Stjec === 5401 || Stjec === 5403 || Stjec === 5601 || Stjec === 5602 || Stjec === 5603 || Stjec === 5605 || Stjec === 5608) {
                zB8 += jB.P123
              }

              // ZO_P9
              if (Stjec === 5401 || Stjec === 5403 || Stjec === 5608) {
                zB9 += jB.P124
              }

              // ZO_P10
              if (Stjec === 5302 || Stjec === 5501 || Stjec === 5604 || Stjec === 5506 || Stjec === 5607) {
                zB10 += jB.P124
              }

              //ZP_P1
              zP1 += jB.P125
              //ZP_P2
              //zP2 += jB.P129

              NeoporeziviPrimici += jB.P152

            }
          })
          this.joppdA.ZO_P1 = this.round(zB1,2)
          this.joppdA.ZO_P2 = this.round(zB2,2) 
          this.joppdA.ZO_P3 = this.round(zB3,2) 
          this.joppdA.ZO_P4 = this.round(zB4,2) 
          this.joppdA.ZO_P5 = this.round(zB5,2)
          this.joppdA.ZO_P6 = this.round(zB6,2)
          this.joppdA.ZO_P7 = this.round(zB7,2)
          this.joppdA.ZO_P8 = this.round(zB8,2)
          this.joppdA.ZO_P9 = this.round(zB9,2)
          this.joppdA.ZO_P10 = this.round(zB10,2)
          // this.joppdA.ZO_P11 = 0
          // this.joppdA.ZO_P12 = 0

          this.joppdA.ZP_P1 = this.round(zP1,2)
          this.joppdA.ZP_P2 = this.round(zP2,2) 
          this.joppdA.IsplaceniNeoporeziviPrimici = NeoporeziviPrimici


          // update joppd A
          this.joppdA.BrojOsoba = people
          this.joppdA.BrojRedaka = order
          this.joppdA.Ime = 'Danijela'
          this.joppdA.Prezime = 'Kovač'
          await JoppdAService.put(this.joppdA)
          console.log('saved joppda ', saved)
        }
        
        
      }

    },
    calculateJoppd() {
      if (this.salarie.JoppdDate) {
        const tekDay = dayjs(this.salarie.JoppdDate).format('YYYY-MM-DD')
        if (tekDay) {
          const day = dayjs(tekDay).dayOfYear()
          const y = tekDay.toString().substring(0,4).slice(-2)
          let newDay = day.toString()
          if (day.toString().length === 1) {
            newDay = '00' + day.toString()
          }
          if (day.toString().length === 2) {
            newDay = '0' + day.toString()
          }
          this.salarie.BrojJoppd =  y + newDay
          const head = this.$store.state.documentActivHead
          head.JoppdDate = this.salarie.JoppdDate
          head.DatumIzvjesca = this.salarie.JoppdDate
          head.BrojJoppd = this.salarie.BrojJoppd
          console.log('1 head.JoppdDate', head.JoppdDate)
          console.log('1 this.salarie.BrojJoppd', this.salarie.BrojJoppd) 
          this.$store.dispatch('setDocumentActivHead', head)
      }
    }
    },
  },
  computed: {

  },
  watch: {
    'selectType' () {
      if (this.selectType) {
        this.salarie.FreeF7 = this.selectType.text
      }
    },
    'selectEmp' () {
     
    },
    'selectBank' () {
      if (this.selectBank.id) {
        this.findBank(this.selectBank.id)
      }
    },
    'salarie.JoppdDate' () { 
      console.log('this.salarie.BrojJoppd', this.salarie.BrojJoppd)     
      if (!this.salarie.JoppdDate) {
        this.salarie.BrojJoppd = '00000'
      }
      if (this.salarie.JoppdDate) {
        this.calculateJoppd()
      }
    },
    'salarie.JoppdPoslan' () {
      if (this.salarie.JoppdPoslan && !this.salarie.JoppdPoslanDate) {
        let d = new Date()
        this.salarie.JoppdPoslanDate = d.toISOString().slice(0,10)
      }
      if (!this.salarie.JoppdPoslan) {
        this.salarie.JoppdPoslanDate = null
      }
    },
    'salarie.NetoIsplacen' () {

      if (this.salarie.NetoIsplacen && !this.salarie.NetoIsplaceDate) {
        let d = new Date()
        this.salarie.NetoIsplaceDate = d.toISOString().slice(0,10)
      }
      if (!this.salarie.NetoIsplacen) {
       this.salarie.NetoIsplaceDate = null
      }
    },
    'salarie.DoprinosiPlaceni' () {
      if (this.salarie.DoprinosiPlaceni && !this.salarie.DoprinosiPlaceniDate) {
        let d = new Date()
        this.salarie.DoprinosiPlaceniDate = d.toISOString().slice(0,10)
      }
      if (!this.salarie.DoprinosiPlaceni) {
        this.salarie.DoprinosiPlaceniDate = null
      }
    },
  },
  components: {
    SalarieDetailList,
    DigitalDocShortList,
    SalariePrintTotal,
    SalariePrintMonthAll,
    SalariePrintSigns,
    SalariePrintSignsList,
    BankOrderIndex,
    SalariePrintFinalTax,
    SalariePrintSyntetic,
    SalariePrintSynteticExcel
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  ::v-deep .my-checkbox .v-label {
  font-size: 10px;
  padding:0px;
  margin: 0px;
  }
</style>

