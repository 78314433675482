<template>
  <div style="background-color: #F5F5F5">

    <v-container class="bg-surface-variant">
      <v-row no-gutters>
        <v-col cols="12" sm="6" >
        <p class="text-h4 text--primary">
          Obračun drugog dohotka
        </p>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="4" >
          <v-card class="mx-auto" max-width="500">
          <v-card-text>
            <div>1.osoba</div>
            <p class="text-h4 text--primary">
              Porezni obveznik
            </p>

            <p>podaci o osobi</p>

            <v-row dense>
              <!-- sifra i OIB  -->
              <v-col cols="12" sm="6">
                <v-autocomplete
                  :items="allEmp"
                  v-model="selectEmp"
                  :label="lang.SelectEmployee"
                  item-text="employeeFullName"
                  item-value="id"
                  return-object
                  persistent-hint
                  clearable
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  label="OIB "
                  required
                  :rules="[required]"
                  v-model="employee.VatID"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1">
                <v-icon @click="brutoKnSwitch">
                  {{checkedVATID ? 'mdi-check' : 'mdi-pencil'}}
                </v-icon>
              </v-col>
              </v-row>

            <v-row dense>
              <!-- ime i prezime  -->
             <v-col cols="12" sm="6">
              <v-text-field
                :label="lang.First"
                required
                :rules="[required]"
                v-model="employee.First"
                outlined
                dense
              ></v-text-field> 
             </v-col>
             <v-col cols="12" sm="6">
              <v-text-field
                :label="lang.Last"
                required
                :rules="[required]"
                v-model="employee.Last"
                outlined
                dense
                ></v-text-field>
              </v-col>
            </v-row>

            
            <v-row dense>
              <!-- ulica i mjesto  -->
             <v-col cols="12" sm="6">
              <v-text-field
                label="Ulica i broj"
                v-model="employee.PersonalID3"
                outlined
                dense
              ></v-text-field> 
             </v-col>
             <v-col cols="12" sm="6">
              <v-text-field
                label="Mjesto"
                v-model="employee.PersonalID4"
                outlined
                dense
              ></v-text-field> 
            </v-col>
            </v-row>

            <v-row dense>
              <!-- mjesto zivota  -->
             <v-col cols="12" sm="5">
              <v-autocomplete
              :items="cities"
              v-model="selectCity"
              :label="lang.CityTaxId"
              item-text="CityName"
              item-value="CityCode"
              return-object
              persistent-hint
              clearable
              dense
              outlined
            ></v-autocomplete> 

             </v-col>
             <v-col cols="12" sm="2">
              <v-text-field
                dense
                :label="lang.CityTaxId"
                v-model="employee.FinTaxOnTaxCityCode"
                type="text"
                disabled
                outlined
              ></v-text-field>
            </v-col>
             <v-col cols="12" sm="5">
              <v-text-field
                dense
                :label="lang.CityTaxName"
                v-model="employee.FinTaxOnTaxCityName"
                type="text"
                disabled
                outlined
                ></v-text-field> 
            </v-col>
            
            </v-row>

            <v-row dense>
              <!-- mjesto rada  -->
             <v-col cols="12" sm="5">
              <v-autocomplete
              :items="cities"
              v-model="selectCity"
              label="Šifra grada/opć.mjesta rada"
              item-text="CityName"
              item-value="CityCode"
              return-object
              persistent-hint
              clearable
              dense
              outlined
            ></v-autocomplete> 

             </v-col>
             <v-col cols="12" sm="2">
              <v-text-field
                dense
                label="Šif.m.r."
                v-model="employee.FinTaxOnTaxCityCode"
                type="text"
                disabled
                outlined
              ></v-text-field>
            </v-col>
             <v-col cols="12" sm="5">
              <v-text-field
                dense
                label="Naziv grada/opć.mjesta rada"
                v-model="employee.FinTaxOnTaxCityName"
                type="text"
                disabled
                outlined
                ></v-text-field> 
            </v-col>
            
            </v-row>

            <v-row dense>
              <!-- banka i ziro racun  -->
              <v-col cols="12" sm="5">
                <v-text-field
                  dense
                  label="Žiro rn.banke"
                  v-model="employee.Fin1bankAccountNr1"
                  outlined
                ></v-text-field> 
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  dense
                  outlined
                  label="Poziv Na"
                  v-model="employee.Fin1bankAccountNr2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  dense
                  outlined
                  label="Poziv Broj"
                  v-model="employee.Fin1bankAccountNr3"
                ></v-text-field>
              </v-col>
            
            </v-row>


          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="deep-purple accent-4"
            >
              Spremni podatke o osobi
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>


      <!-- PRIMITAK OBRACN NOVACA -->
      <v-col cols="12" sm="4" >
          <v-card class="mx-auto" max-width="500">
          <v-card-text>
            <div>2.financijski podaci</div>
            <p class="text-h4 text--primary">
              Primitak
            </p>
            <p>iznosi u EURima</p>
            <v-row dense>
              <!-- primitak  -->
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Primitak"
                  v-model="employee.FinFreeTotalPer"
                  type="number"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Doprinosi"
                  v-model="employee.FinFreeTotalPer"
                  type="number"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Stjecatelj"
                  v-model="employee.FinFreeTotalPer"
                  type="number"
                  outlined
                ></v-text-field>
              </v-col> 
            </v-row>
            <v-row dense>
              <!-- troskovi  -->
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Troškovi "
                  v-model="employee.FinFreeTotalPer"
                  type="number"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="troškovi"
                  v-model="employee.FinFreeTotalPer"
                  type="number"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Olakšica"
                  v-model="employee.FinFreeTotalPer"
                  type="number"
                  outlined
                ></v-text-field>
              </v-col> 
            </v-row>
            <v-row dense>
              <!-- troskovi  -->
              <v-col cols="12" sm="6">
                <v-text-field
                  dense
                  label="Bruto iznos "
                  v-model="employee.FinFreeTotalPer"
                  type="number"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  dense
                  label="Neto iznos"
                  v-model="employee.FinFreeTotalPer"
                  type="number"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            
            <v-row dense>
              <!-- primitak  -->
              <v-col cols="12" sm="6">
                <v-text-field
                  dense
                  label="Datum ugovora"
                  v-model="employee.FinFreeTotalPer"
                  type="number"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
              <v-text-field
                dense
                label="Broj ugovora"
                v-model="employee.FinTaxOnTaxCityName"
                type="text"
                outlined
                ></v-text-field> 
            </v-col>
            
            </v-row>
            <v-row dense>
              <!-- primitak  -->

              <v-col cols="12" sm="6">
                <v-text-field
                  dense
                  label="Datum isplate"
                  v-model="employee.FinFreeTotalPer"
                  type="number"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  dense
                  label="Način isplate"
                  v-model="employee.FinFreeTotalPer"
                  type="number"
                  outlined
                ></v-text-field>
              </v-col> 
            </v-row>

          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="deep-purple accent-4"
            >
              Learn More
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" >
          <v-card class="mx-auto" max-width="500">
          <v-card-text>
            <div>3.dodatni financijski podaci</div>
            <p class="text-h4 text--primary">
              Dodatni podaci
            </p>
            <p>Olakšica za umjetnike</p>
            <v-row dense>
              <!-- olaksica  -->
              <v-col cols="12" sm="6">
                <v-text-field
                  dense
                  label="Iznos olakšice"
                  v-model="employee.FinFreeTotalPer"
                  type="number"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  dense
                  label="Iznos umanje za olakšice"
                  v-model="employee.FinFreeTotalPer"
                  type="number"
                  outlined
                ></v-text-field>
              </v-col> 
            </v-row>
            <p>Troškovi</p>
            <v-row dense>
              <!-- Troškovi  -->
              <v-col cols="12" sm="6">
                <v-text-field
                  dense
                  label="Iznos troškova"
                  v-model="employee.FinFreeTotalPer"
                  type="number"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  dense
                  label="Iznos umanjen za troškove"
                  v-model="employee.FinFreeTotalPer"
                  type="number"
                  outlined
                ></v-text-field>
              </v-col> 
            </v-row>
            <p>Projekt</p>
            <v-row dense>
              <!-- projekt  -->
             <v-col cols="12" sm="6">
              <v-autocomplete
              :items="cities"
              v-model="selectCity"
              label="Projekt"
              item-text="CityName"
              item-value="CityCode"
              return-object
              persistent-hint
              clearable
              dense
              outlined
            ></v-autocomplete> 

             </v-col>
             <v-col cols="12" sm="6">
              <v-text-field
                dense
                label="Naziv projekta"
                v-model="employee.FinTaxOnTaxCityCode"
                type="text"
                outlined
              ></v-text-field>
            </v-col>

            
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="deep-purple accent-4"
            >
              SPREMI
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
    
  </div>
</template>

<script>
import EmployeeService from '@/services/EmployeeService'
import SalarieService from '@/services/SalarieService'
import SalarieDetailService from '@/services/SalarieDetailService'
import CityService from '@/services/CityService'
import SalariePrintCardEmpYear from '../Salarie/SalariePrintCardEmpYear'
import langEn from './empDescEn'
import langHr from './empDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'
import {dynamicSort, round} from '@/snippets/allSnippets'
//import {, round} from '@/snippets/allSnippets'

export default {
  name: 'employeedit3other',
  data () {
    return {
      checkedVATID: false,
      allEmp:[],
      selectEmp: {},
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      lang: {},
      langC: {},
      menu: false,
      modal: false,
      saving: false,
      msg: '',
      newEmployee: {},
      employee: { },
      required: (value) => !!value || 'Required.',
      error: '',
      salariesFrom: [],
      selectSalariesFrom1: {},
      selectSalariesFrom2: {},
      selectSalariesFrom3: {},
      selectSalariesFrom4: {},
      children: [],
      selectChildren: {},
      familysupport: [],
      selectFamilySupport: {},
      invalidtype: [],
      selectInvalidType: {},
      citytax: [],
      cities: [],
      selectCity: {},
      selectCitytax: {},
      salariesTo: [],
      selectSalariesTo1: {},
      selectSalariesTo2: {},
      selectSalariesTo3: {},
      notaxaddition: [],
      oneNotaxaddition: {},
      twoNotaxaddition: {},
      threeNotaxaddition: {},
      fourNotaxaddition: {},
      fiveNotaxaddition: {},
      oneBankAccountAdd: {},
      twoBankAccountAdd: {},
      threeBankAccountAdd: {},
      fourBankAccountAdd: {},
      joppd: [],
      oneJoppd: {},
      twoJoppd: {},
      threeJoppd: {},
      fourJoppd: {},
      fiveJoppd: {},
      actionType: 0,
      rules: [
         v => v >= 0 || 'Mora biti 0 ili više',
         v => v <= 500 || 'Ne može biti više od 500',
      ],
      rules20: [
         v => v >= 15 || 'Mora biti 15 ili više',
         v => v <= 23.60 || 'Ne može biti više od 23.60',
      ],
      rules30: [
         v => v >= 25 || 'Mora biti 25 ili više',
         v => v <= 36 || 'Ne može biti više od 36',
      ],
      banks: [],
      selectBank1: {},
      selectBank2: {},
      selectBankLoan1: {},
      selectBankLoan2: {},
      selectBankLoan3: {},
      dialog1: false,
      modal1: false,
      dialog2: false,
      modal2: false,
      dialog3: false,
      modal3: false,
      netoChange: true,
      FinBruto1Kn: 0,
      fromKnToEur: true,
      showNote: false,
      showAllHours: false,
      do2024: false,
      osobniOdbitak: 560,
      imam2025: false
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.documentActivHead.Month < 12 && this.$store.state.documentActivHead.Year < 2023) {
      this.do2024 = false
    }
    if (this.$store.state.documentActivHead.Month === 12 && this.$store.state.documentActivHead.Year === 2023) {
      this.do2024 = true
    }
    if (this.$store.state.documentActivHead.Year === 2024) {
      this.do2024 = true
    }
    if (this.$store.state.documentActivHead.Month === 12 && this.$store.state.documentActivHead.Year === 2024) {
      this.imam2025 = true
    }
    if (this.$store.state.documentActivHead.Year >= 2025) {
      this.imam2025 = true
    }
    this.osobniOdbitak = this.do2024 ? 560 :  530.90
    if (this.imam2025 === true) {
      this.osobniOdbitak = 600
    }
    this.saving = true
    this.salariesFrom = this.$store.state.salariesFrom
    this.children = this.$store.state.children
    this.familysupport = this.$store.state.familysupport
    this.invalidtype = this.$store.state.invalidtype
    this.citytax = this.$store.state.citytax
    this.salariesTo = this.$store.state.salariesTo
    this.notaxaddition = this.$store.state.joppdNotax
    this.joppd = this.$store.state.joppd

    this.actionType = this.$store.state.actionType
    this.banks = this.$store.state.banksHR
    const {data} = await CityService.index()
      if (data && data.cities !== undefined){
        this.cities = data.cities
      }

    if (this.$store.state.employeeid) {
      try {
        if (this.$store.state.actionType === 1) {
          const dbEmployee = await EmployeeService.show(this.$store.state.employeeid)
          this.employee = dbEmployee.data.employee

        }
        if (this.$store.state.actionType === 2) {

          const query = {}
          query.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
          query.id = this.$store.state.employeeid ? this.$store.state.employeeid: 0
          const dbEmployee = await SalarieDetailService.choose(query)
          //console.log(dbEmployee.data.salariedetails[0])
          this.employee = dbEmployee.data.salariedetails[0]
          const dbEmp = await EmployeeService.show(this.employee.empID)
          if (dbEmp && dbEmp.data && dbEmp.data.employee) {
            const tempEmp = dbEmp.data.employee
            this.employee.WorkYearsBefore = tempEmp.WorkYearsBefore ? tempEmp.WorkYearsBefore : 0
            this.employee.WorkMonthsBefore = tempEmp.WorkMonthsBefore ? tempEmp.WorkMonthsBefore : 0
            this.employee.WorkDaysBefore = tempEmp.WorkDaysBefore ? tempEmp.WorkDaysBefore : 0
            this.employee.StartInCompany = tempEmp.StartInCompany ? tempEmp.StartInCompany : null
          }
        }
        if (this.employee.FinFrom1Per === null || this.employee.FinFrom1Per === 0) {
          this.employee.FinFrom1Desc = 'Doprinos MIO 1.stup'
          this.employee.FinFrom1Per = 20
        }
        if (this.employee.FinFrom2Per === null || this.employee.FinFrom2Per === 0) {
          this.employee.FinFrom2Desc = 'Doprinos MIO 2.stup'
          this.employee.FinFrom2Per = 0
        }
      } catch (error) {
        this.error = error
      }

      if (!this.employee.FinFreeDisabled && this.round(this.employee.FinFree1Indeks, 2) === 0) {
        const basicAm = this.children.find(child => child.calc === 1)
        this.employee.FinFree1Indeks = 1.6
        this.employee.FinFree1OnAm = this.round((basicAm * 1.6), 2)
        this.employee.FinFreeTotal = this.round((basicAm * 1.6), 2)
        this.employee.FinFreeTotalPer = 100
        this.employee.FinFreeTotalToUse = this.round((basicAm * 1.6), 2)
      }
    //  if (this.round(this.employee.FinBruto1, 2) !== 0) {
        this.FinBruto1Kn = this.round((this.employee.FinBruto1 * 7.5345) ,2)
     // }
    }
    this.selectValues()
    this.saving = false
  },
  methods: {
    showNote1() {
      this.showNote = !this.showNote
    },
    showAllHours1() {
      this.showAllHours = !this.showAllHours
    },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    async salarieDelete() {
      const warningMessage = this.employee.First + ' ' + this.employee.Last + ' iz obračuna ' +this.$store.state.documentActivHead.Order + '/' + this.$store.state.documentActivHead.Month + '/' + this.$store.state.documentActivHead.Year
      if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!')) {
        // delete employeee from salarie liste
        const deletedEmp = await SalarieDetailService.delete(this.employee.id)
        console.log('Deleted emp ', deletedEmp)
        this.$router.push({
            name: 'salariedetail'
          })
      }
    },
    selectValues() {
      if (this.employee) {
        this.selectSalariesFrom1 = this.employee.FinFrom1_id ? this.salariesFrom.find(from1 => (parseInt(from1.value) === this.employee.FinFrom1_id )) : null
        this.selectSalariesFrom2 = this.employee.FinFrom2_id ? this.salariesFrom.find(from2 => (parseInt(from2.value) === this.employee.FinFrom2_id )) : null
        this.selectSalariesFrom3 = this.employee.FinFrom3_id ? this.salariesFrom.find(from3 => (parseInt(from3.value) === this.employee.FinFrom3_id )) : null
        this.selectSalariesFrom4 = this.employee.FinFrom4_id ? this.salariesFrom.find(from4 => (parseInt(from4.value) === this.employee.FinFrom4_id )) : null

        this.selectChildren = this.employee.FinFree2OnAm ? this.children.find(child => (parseInt(child.value) === this.employee.FinFree2OnAm )) : null
        this.selectFamilySupport = this.employee.FinFree3OnAm ? this.familysupport.find(fams => (parseInt(fams.value) === this.employee.FinFree3OnAm )) : null
        this.selectInvalidType = this.employee.FinFree4OnAm ? this.invalidtype.find(itype => (parseInt(itype.value) === this.employee.FinFree4OnAm )) : null

        this.oneNotaxaddition = this.employee.FinAdd1_TypeId ? this.notaxaddition.find(notax1 => (parseInt(notax1.value) === this.employee.FinAdd1_TypeId )) : null
        this.twoNotaxaddition = this.employee.FinAdd2_TypeId ? this.notaxaddition.find(notax2 => (parseInt(notax2.value) === this.employee.FinAdd2_TypeId )) : null
        this.threeNotaxaddition = this.employee.FinAdd3_TypeId ? this.notaxaddition.find(notax3 => (parseInt(notax3.value) === this.employee.FinAdd3_TypeId )) : null
        this.fourNotaxaddition = this.employee.FinAdd4_TypeId ? this.notaxaddition.find(notax4 => (parseInt(notax4.value) === this.employee.FinAdd4_TypeId )) : null

        this.oneBankAccountAdd = this.employee.FinAdd1_Percent ? this.$store.state.notaxaddition.find(notax1 => (parseInt(notax1.value) === parseInt(this.employee.FinAdd1_Percent) )) : null
        this.twoBankAccountAdd = this.employee.FinAdd2_Percent ? this.$store.state.notaxaddition.find(notax2 => (parseInt(notax2.value) === parseInt(this.employee.FinAdd2_Percent) )) : null
        this.threeBankAccountAdd = this.employee.FinAdd3_Percent ? this.$store.state.notaxaddition.find(notax3 => (parseInt(notax3.value) === parseInt(this.employee.FinAdd3_Percent) )) : null
        this.fourBankAccountAdd = this.employee.FinAdd4_Percent ? this.$store.state.notaxaddition.find(notax4 => (parseInt(notax4.value) === parseInt(this.employee.FinAdd4_Percent) )) : null

        this.selectCity = this.cities.find( city => city.CityCode === this.employee.FinTaxOnTaxCityCode)
        this.selectCitytax = this.employee.FinTaxOnTaxPer ? this.citytax.find(city => (parseFloat(city.value) === this.employee.FinTaxOnTaxPer )) : null

        this.oneJoppd = this.employee.FinAdd1_JoppId ? this.joppd.find(joppd1 => ((joppd1.value) === this.employee.FinAdd1_JoppId )) : null
        this.twoJoppd = this.employee.FinAdd2_JoppId ? this.joppd.find(joppd2 => ((joppd2.value) === this.employee.FinAdd2_JoppId )) : null
        this.threeJoppd = this.employee.FinAdd3_JoppId ? this.joppd.find(joppd3 => ((joppd3.value) === this.employee.FinAdd3_JoppId )) : null
        this.fourJoppd = this.employee.FinAdd4_JoppId ? this.joppd.find(joppd4 => ((joppd4.value) === this.employee.FinAdd4_JoppId )) : null

        this.selectSalariesTo1 = this.employee.FinTo1_id ? this.salariesTo.find(To1 => (parseInt(To1.value) === this.employee.FinTo1_id )) : null
        this.selectSalariesTo2 = this.employee.FinTo2_id ? this.salariesTo.find(To2 => (parseInt(To2.value) === this.employee.FinTo2_id )) : null
        this.selectSalariesTo3 = this.employee.FinTo3_id ? this.salariesTo.find(To3 => (parseInt(To3.value) === this.employee.FinTo3_id )) : null

        this.selectBank1 = this.employee.Fin1bankAccountId ? this.banks.find(bank1 => ((bank1.vatid) === this.employee.Fin1bankAccountId )) : null
        this.selectBank2 = this.employee.Fin2bankAccountId ? this.banks.find(bank2 => ((bank2.vatid) === this.employee.Fin2bankAccountId )) : null

        this.selectBankLoan1 = this.employee.Fin1LoanBankAccountId ? this.banks.find(bank1 => ((bank1.vatid) === this.employee.Fin1LoanBankAccountId )) : null
        this.selectBankLoan2 = this.employee.Fin2LoanBankAccountId ? this.banks.find(bank1 => ((bank1.vatid) === this.employee.Fin2LoanBankAccountId )) : null
        this.selectBankLoan3 = this.employee.Fin3LoanBankAccountId ? this.banks.find(bank1 => ((bank1.vatid) === this.employee.Fin3LoanBankAccountId )) : null
      }
    },
    checkSave() {

    },
    async register () {
      try {
        if (this.$store.state.user.email) {
          this.employee.email = this.$store.state.user.email
          this.employee.LastUpdateBy = this.$store.state.user.email
        }
        if (this.$store.state.actionType === 1) {
          await EmployeeService.put(this.employee)
          this.$router.push({
          name: 'employeeview'
        })
        }
        if (this.$store.state.actionType === 2) {
          console.log(' snimam this.employee', this.employee)
          await SalarieDetailService.put(this.employee)
          this.$router.push({
            name: 'salariedetail'
          })
        }
        
      } catch (error) {
        this.error = error.response.data.error
      }
    },
  employeeList () {

      //this.register()
      if (this.$store.state.actionType === 1) {
        this.$router.push({
          name: 'employeeview'
        })
      }
      if (this.$store.state.actionType === 2) {
        this.$router.push({
          name: 'salariedetail'
        })
      }

    },
    netoSwitch() {
      if (this.netoChange === false) {
        if (!this.employee.FinNeto2) {
          this.employee.FinNeto2 = 0
        }
        if (this.employee.FinNeto2 > 1000000) {
          this.employee.FinNeto2 = 0
        }
        
        this.employee.FinBruto0 = this.round(this.calcBrutoIzNeto(), 2)
        this.employee.FinBruto1 = this.employee.FinBruto0
        this.calculateSal()
      }
      this.netoChange = !this.netoChange
    },
    brutoKnSwitch() {

      if (this.fromKnToEur === false) {
        this.employee.FinBruto0 = this.round((this.FinBruto1Kn / 7.5345), 2) !== 0 ? this.round((this.FinBruto1Kn / 7.5345), 2) : 0
        this.calculateSal()
      }
      this.fromKnToEur = !this.fromKnToEur
    },
    calcBrutoIzNeto() {
      let Bp = 0
      if (this.employee.FinNeto2 && this.employee.FinNeto2 > 0) {
        const N = this.round(this.employee.FinNeto2, 2)
        const O = this.round(this.employee.FinFreeTotalToUse, 2)
        let koefFirst = 1.25
        const brutoPosto = this.round((this.round(this.employee.FinFrom1_Percent, 2) + this.round(this.employee.FinFrom2_Percent, 2)),2)
        if (brutoPosto === 10) {
          koefFirst = 1.111111
        }
        
        let haveIt = false
        // case 1 if N < O
        if (N < O) {
          Bp = N * koefFirst
          haveIt = true
        }
        // 6000 kn = 796.44
        // 4000 kn = 530.90
        // 2500 kn = 331.81

        // case 2 if N < 3981.69 - 796.44 * kpr + O
        if ((N < 3981.69 - 796.44 * this.kpr + O) && !haveIt) {
          Bp = (((N - O) * this.kppr20) + O) / 0.8
        }
        
        // case 3 if N > 3981.69 - 796.44 * kpr + O  - bez ogranicenja
        if ((N > 3981.69 - 796.44 * this.kpr + O) && !haveIt) {
          Bp = (3981.69 + O + (N - (3981.69 - 796.44 * this.kpr + O)) * this.kppr30) / 0.8
        }
        
        // case 4 if N > 3981.69 - 796.44 * kpr + O - sa ogranicenjem !!!! do not use it for now
        // if (N > 3981.69 - 796.44 * this.kpr + O) {
        //   Bp = (3981.69 + O + (N - (3981.69-796.44 * this.kpr + O)) * this.kppr30) + 11440
        //   console.log('4 Bp ', Bp)
        // }
        
        return Bp
        //this.employee.FinBruto2 = this.round(Bp, 2)
        //this.employee.FinBruto1 = this.employee.FinBruto2  -  this.employee.FinKoef1 - this.employee.FinKoef2
      }
    },


    // todo doraditi obracun za djecu 
    calcPersonalFree () {
      if (!this.do2024) {
        let childFaktor = 0
        if (this.employee.Child1) {
          childFaktor = 0.7
        }
        if (this.employee.Child2) {
          childFaktor = this.round((childFaktor  + 1),2)
        }
        if (this.employee.Child3) {
          childFaktor = this.round((childFaktor + 1.4),2)
        }
        if (this.employee.Child4) {
          childFaktor = this.round((childFaktor + 1.9),2)
        }
        const basicAm = this.children.find(child => child.calc === 1)

        if (basicAm) {
          this.employee.FinFree2Indeks = childFaktor
          this.employee.FinFree2OnAm = basicAm.value
          this.employee.FinFree2Am = this.round((childFaktor * basicAm.amount * (this.employee.ChildPer ? this.employee.ChildPer/100 : 0)),2)
        }
        this.employee.FinFreeTotal = this.round(this.employee.FinFree1OnAm, 2) + this.round(this.employee.FinFree2Am, 2) + this.round(this.employee.FinFree3Am, 2) + this.round(this.employee.FinFree4Am, 2)
        this.employee.FinFreeTotalToUse = this.round((this.employee.FinFreeTotal * (this.employee.FinFreeTotalPer / 100)) ,2)
      }
      if (this.do2024) {
        let childAmount = 0
        let childFaktor = 0
        if (this.employee.Child1) {
          childAmount = this.round((0.5 * 560 * (this.employee.ChildPer ? this.employee.ChildPer/100 : 0)),2)
          childFaktor = 0.5
        }
        if (this.employee.Child2) {
          childAmount += this.round((0.7 * 560 * (this.employee.ChildPer ? this.employee.ChildPer/100 : 0)),2)
          childFaktor += 0.7
        }
        if (this.employee.Child3) {
          childAmount += this.round((1 * 560 * (this.employee.ChildPer ? this.employee.ChildPer/100 : 0)),2)
          childFaktor += 1
        }
        if (this.employee.Child4) {
          childAmount += this.round((1.4 * 560 * (this.employee.ChildPer ? this.employee.ChildPer/100 : 0)),2)
          childFaktor += 1.4
        }
        const basicAm = this.children.find(child => child.calc === 1)
        if (basicAm) {
          this.employee.FinFree2Indeks = childFaktor
          this.employee.FinFree2OnAm = basicAm.value
          this.employee.FinFree2Am = childAmount
        }
        this.employee.FinFreeTotal = this.round(this.employee.FinFree1OnAm, 2) + this.round(this.employee.FinFree2Am, 2) + this.round(this.employee.FinFree3Am, 2) + this.round(this.employee.FinFree4Am, 2)
        this.employee.FinFreeTotalToUse = this.round((this.employee.FinFreeTotal * (this.employee.FinFreeTotalPer / 100)) ,2)
      }
    },

    // ----- allCalc
    calculateSal() {
      

      // this.employee.FinBruto0 = typeof this.employee.FinBruto0 === 'string' ? 0 : round(this.employee.FinBruto0, 2)
      if (typeof this.employee.FinBruto0 === 'string'){
        if(this.employee.FinBruto0.length === 0){this.employee.FinBruto0 = 0}
        if(this.employee.FinBruto0.length !== 0){this.employee.FinBruto0 = round(parseFloat(this.employee.FinBruto0),2)}
      }
      if (typeof this.employee.FinBruto1 === 'string'){
        if(this.employee.FinBruto1.length === 0){this.employee.FinBruto1 = 0}
        if(this.employee.FinBruto1.length !== 0){this.employee.FinBruto1 = round(parseFloat(this.employee.FinBruto1),2)}
      }
      // this.employee.FinBruto1 = typeof this.employee.FinBruto1 === 'string' ? 0 : round(this.employee.FinBruto1, 2)
      if (typeof this.employee.FinAdd1_Amount === 'string'){
        if(this.employee.FinAdd1_Amount.length === 0){this.employee.FinAdd1_Amount = 0}
        if(this.employee.FinAdd1_Amount.length !== 0){this.employee.FinAdd1_Amount = round(parseFloat(this.employee.FinAdd1_Amount),2)}
      }
      if (typeof this.employee.FinAdd2_Amount === 'string'){
        if(this.employee.FinAdd2_Amount.length === 0){this.employee.FinAdd2_Amount = 0}
        if(this.employee.FinAdd2_Amount.length !== 0){this.employee.FinAdd2_Amount = round(parseFloat(this.employee.FinAdd2_Amount),2)}
      }
      if (typeof this.employee.FinAdd3_Amount === 'string'){
        if(this.employee.FinAdd3_Amount.length === 0){this.employee.FinAdd3_Amount = 0}
        if(this.employee.FinAdd3_Amount.length !== 0){this.employee.FinAdd3_Amount = round(parseFloat(this.employee.FinAdd3_Amount),2)}
      }
      if (typeof this.employee.FinAdd4_Amount === 'string'){
        if(this.employee.FinAdd4_Amount.length === 0){this.employee.FinAdd4_Amount = 0}
        if(this.employee.FinAdd4_Amount.length !== 0){this.employee.FinAdd4_Amount = round(parseFloat(this.employee.FinAdd4_Amount),2)}
      }
      
      if (typeof this.employee.NaHZZOiznos === 'string'){
        if(this.employee.NaHZZOiznos.length === 0){this.employee.NaHZZOiznos = 0}
        if(this.employee.NaHZZOiznos.length !== 0){this.employee.NaHZZOiznos = round(parseFloat(this.employee.NaHZZOiznos),2)}
      }
      if (typeof this.employee.FinBruto2 === 'string'){
        if(this.employee.FinBruto2.length === 0){this.employee.FinBruto2 = 0}
        if(this.employee.FinBruto2.length !== 0){this.employee.FinBruto2 = round(parseFloat(this.employee.FinBruto2),2)}
      }
      if (typeof this.employee.FinBrutoOther === 'string'){
        if(this.employee.FinBrutoOther.length === 0){this.employee.FinBrutoOther = 0}
        if(this.employee.FinBrutoOther.length !== 0){this.employee.FinBrutoOther = round(parseFloat(this.employee.FinBrutoOther),2)}
      }
      if (typeof this.employee.IskoristenoPravo === 'string'){
        if(this.employee.IskoristenoPravo.length === 0){this.employee.IskoristenoPravo = 0}
        if(this.employee.IskoristenoPravo.length !== 0){this.employee.IskoristenoPravo = round(parseFloat(this.employee.IskoristenoPravo),2)}
      }

      if (typeof this.employee.Joppd72 === 'string'){
        if(this.employee.Joppd72.length === 0){this.employee.Joppd72 = 0}
        if(this.employee.Joppd72.length !== 0){this.employee.Joppd72 = round(parseFloat(this.employee.Joppd72),2)}
      }
      if (typeof this.employee.Joppd129 === 'string'){
        if(this.employee.Joppd129.length === 0){this.employee.Joppd129 = 0}
        if(this.employee.Joppd129.length !== 0){this.employee.Joppd129 = round(parseFloat(this.employee.Joppd129),2)}
      }
      if (typeof this.employee.Fin1LoanBankAmount === 'string'){
        if(this.employee.Fin1LoanBankAmount.length === 0){this.employee.Fin1LoanBankAmount = 0}
        if(this.employee.Fin1LoanBankAmount.length !== 0){this.employee.Fin1LoanBankAmount = round(parseFloat(this.employee.Fin1LoanBankAmount),2)}
      }
      if (typeof this.employee.Fin2LoanBankAmount === 'string'){
        if(this.employee.Fin2LoanBankAmount.length === 0){this.employee.Fin2LoanBankAmount = 0}
        if(this.employee.Fin2LoanBankAmount.length !== 0){this.employee.Fin2LoanBankAmount = round(parseFloat(this.employee.Fin2LoanBankAmount),2)}
      }
      if (typeof this.employee.Fin3LoanBankAmount === 'string'){
        if(this.employee.Fin3LoanBankAmount.length === 0){this.employee.Fin3LoanBankAmount = 0}
        if(this.employee.Fin3LoanBankAmount.length !== 0){this.employee.Fin3LoanBankAmount = round(parseFloat(this.employee.Fin3LoanBankAmount),2)}
      }
      // this.employee.FinAdd1_Amount = typeof this.employee.FinAdd1_Amount === 'string' ? 0 : round(this.employee.FinAdd1_Amount, 2)
      // this.employee.FinAdd2_Amount = typeof this.employee.FinAdd2_Amount === 'string' ? 0 : round(this.employee.FinAdd2_Amount, 2)
      // this.employee.FinAdd3_Amount = typeof this.employee.FinAdd3_Amount === 'string' ? 0 : round(this.employee.FinAdd3_Amount, 2)
      // this.employee.FinAdd4_Amount = typeof this.employee.FinAdd4_Amount === 'string' ? 0 : round(this.employee.FinAdd4_Amount, 2)
      // this.employee.FinBruto2 = typeof this.employee.FinBruto2 === 'string' ? 0 : round(this.employee.FinBruto2, 2)
      // this.employee.FinBrutoOther = typeof this.employee.FinBrutoOther === 'string' ? 0 : round(this.employee.FinBrutoOther, 2)
      // this.employee.IskoristenoPravo = typeof this.employee.IskoristenoPravo === 'string' ? 0 : round(this.employee.IskoristenoPravo, 2)
      // this.employee.Joppd72 = typeof this.employee.Joppd72 === 'string' ? 0 : round(this.employee.Joppd72, 2)
      // this.employee.Joppd129 = typeof this.employee.Joppd129 === 'string' ? 0 : round(this.employee.Joppd129, 2)
      // this.employee.Fin1LoanBankAmount = typeof this.employee.Fin1LoanBankAmount === 'string' ? 0 : round(this.employee.Fin1LoanBankAmount,2)
      // this.employee.Fin2LoanBankAmount = typeof this.employee.Fin2LoanBankAmount === 'string' ? 0 : round(this.employee.Fin2LoanBankAmount,2)
      // this.employee.Fin3LoanBankAmount = typeof this.employee.Fin3LoanBankAmount === 'string' ? 0 : round(this.employee.Fin3LoanBankAmount,2)

      if (!this.employee.CalculateByHours) {
        this.employee.FinBruto1 = this.employee.FinBruto0 && this.round(this.employee.FinBruto0,2) >= 0 ? this.round(this.employee.FinBruto0,2) : this.employee.FinBruto1
      }
      if (this.employee.FinBruto0 === 0){
        this.employee.FinBruto1 = 0
        this.FinBruto1Kn = this.round((this.employee.FinBruto1 * 7.5345) ,2)
      }
      this.employee.FinBruto1 = this.employee.FinBruto1 ? round(this.employee.FinBruto1,2): 0
      if (this.employee.FinTaxOnTaxVukovar) {
        
        // if have years past to calculate 
        let allYears = 0
        if (this.employee.WorkYearsBefore) {
          allYears = this.employee.WorkYearsBefore * 365
        }
        if (this.employee.WorkYearsBefore) {
          allYears += this.employee.WorkMonthsBefore * 30
        }
        if (this.employee.WorkYearsBefore) {
          allYears += this.employee.WorkDaysBefore
        }
        
        if (this.employee.StartInCompany) {
          let date_1 = new Date(this.employee.StartInCompany)
          let date_2 = new Date()
          let difference = date_2.getTime() - date_1.getTime();
          let TotalDays = Math.ceil(difference / (1000 * 3600 * 24))
          allYears += TotalDays
          this.employee.FinKoef1 = this.employee.FinBruto1  * ((parseInt(allYears/365) * 0.5) / 100)
          this.employee.FinKoef1Desc = 'Uvećanje od 0,5% za svaku navr.god.radnog staža' + '(Uk.god.:' + parseInt(allYears/365) + ')'
        }
      }
      this.employee.FinKoef1 = this.employee.FinKoef1 ? round(this.employee.FinKoef1, 2): 0
      this.employee.FinKoef2 = this.employee.FinKoef2 ? round(this.employee.FinKoef2,2): 0
      this.employee.FinBruto2 = round((this.employee.FinBruto1 + this.employee.FinKoef1 + this.employee.FinKoef2), 2)
      // console.log('this.employee.FinBruto2', this.employee.FinBruto2)
      if (!this.do2024) {
        this.employee.FinFrom1_Amount = round((this.employee.FinBruto2 * (this.employee.FinFrom1_Percent / 100)), 2)
        this.employee.FinFrom2_Amount = round((this.employee.FinBruto2 * (this.employee.FinFrom2_Percent / 100)), 2)
        this.employee.FinFrom3_Amount = round((this.employee.FinBruto2 * (this.employee.FinFrom3_Percent / 100)), 2)
        this.employee.FinFrom4_Amount = round((this.employee.FinBruto2 * (this.employee.FinFrom4_Percent / 100)), 2)
        this.employee.FinNeto1ls = round((this.employee.FinBruto2 - this.employee.FinFrom1_Amount - this.employee.FinFrom2_Amount - this.employee.FinFrom3_Amount - this.employee.FinFrom4_Amount), 2)
      }
      if (this.do2024) {

        let baseFrom1 = this.employee.FinBruto2
        let baseFrom2 = this.employee.FinBruto2
        let firstPer = 0
        let secPer = 100
        let per = 0
        let imaoUmanjenje = false
        this.employee.Joppd72 = 0
        this.employee.Joppd129 = 0

        // imao je prije nesto bruto 
        if (this.round(this.employee.FinBrutoOther,2) > 0) {
            imaoUmanjenje = true
            per = this.round(this.employee.FinBrutoOther + this.employee.FinBruto2, 2) / 100
            firstPer = this.round(this.employee.FinBrutoOther, 2) / per
            secPer = this.round(this.employee.FinBruto2, 2) / per
        }
        if (this.employee.NeRacunajUmanjenje === true) {
          this.employee.FinBrutoOther = 0
          this.employee.IskoristenoPravo = 0
          this.employee.Joppd72 = 0
          this.employee.Joppd129 = 0
        }

        // -------------------------------------------------------->> NORMALNO 
        if (!imaoUmanjenje && this.employee.NeRacunajUmanjenje === false) {

          // ako je ukupna placa niza od ukupnog umanjenja 300 
          if (this.round(this.employee.FinBruto2,2) <= 300) {
            this.employee.Joppd72 = 1
            this.employee.Joppd129 = round(this.employee.FinBruto2,2)
            baseFrom2 = 0
          }

          // treba jos vidjeti da li se oduzima 300 i ako nije na puno radno vrijeme
          if (this.round(this.employee.FinBruto2,2) > 300  && this.round(this.employee.FinBruto2,2) <= 700) {
            this.employee.Joppd72 = 1
            this.employee.Joppd129 = 300
            let Osnovica1 = this.employee.FinBruto2

            // ako je na bolovanju cijeli mjesec (70% place) i osnovica je manja od najnize osnovice onda se za 2023 uzima 519.53 za obracun doprinosa
            if (this.employee.BolCijeliMjesecNaPoslodavca) {
              Osnovica1 = 519.53
            }

            baseFrom2 = Osnovica1 - 300
            if (baseFrom2 < 0 ) {
              baseFrom2 = 0
            }
          }
          if (this.round(this.employee.FinBruto2,2) >= 700.01 && this.round(this.employee.FinBruto2,2) <= 1300) {
            this.employee.Joppd72 = 1
            this.employee.Joppd129 = round(((1300 - this.employee.FinBruto2) * 0.5),2)
            baseFrom2 = this.employee.FinBruto2 - this.employee.Joppd129
          }
        }

        // -------------------------------------------------------->> Ima vec isplaceni bruto 
        if (imaoUmanjenje && this.employee.NeRacunajUmanjenje === false) {

          const ukupniBruto = this.round(this.employee.FinBrutoOther,2) + this.round(this.employee.FinBruto2,2)
          let ukupnoPravo = 0

          if (ukupniBruto <= 300.00) {
            baseFrom2 = 0
          }

          if (this.round(ukupniBruto,2) > 300  && this.round(ukupniBruto,2) <= 700) {
            baseFrom2 = 300 - this.employee.IskoristenoPravo
            this.employee.Joppd72 = 3
            this.employee.Joppd129 = round((300 - this.employee.IskoristenoPravo),2)
          }

          if (ukupniBruto >= 700.01 && ukupniBruto <= 1300) {
            ukupnoPravo = this.round(((1300 - ukupniBruto) * 0.5),2)
            baseFrom2 = this.employee.FinBruto2 - (ukupnoPravo - this.employee.IskoristenoPravo)
            this.employee.Joppd72 = 3
            this.employee.Joppd129 = round((ukupnoPravo - this.employee.IskoristenoPravo),2)
          }
          if (ukupniBruto > 1300) {
            baseFrom2 = ukupniBruto
          }
        }

        this.employee.FinFrom1_Amount = this.round((baseFrom2 * (this.employee.FinFrom1_Percent / 100)), 2)
        this.employee.FinFrom2_Amount = this.round((baseFrom1 * (this.employee.FinFrom2_Percent / 100)), 2)
        this.employee.FinFrom3_Amount = this.round((baseFrom1 * (this.employee.FinFrom3_Percent / 100)), 2)
        this.employee.FinFrom4_Amount = this.round((baseFrom1 * (this.employee.FinFrom4_Percent / 100)), 2)
        this.employee.FinNeto1ls = this.round((this.employee.FinBruto2 - this.employee.FinFrom1_Amount - this.employee.FinFrom2_Amount - this.employee.FinFrom3_Amount - this.employee.FinFrom4_Amount), 2)
      }
      // tax
      if ((this.employee.FinNeto1ls - this.employee.FinFreeTotalToUse) > 0 ) {
        // have tax

        const taxBase = this.round((this.employee.FinNeto1ls - this.employee.FinFreeTotalToUse) ,2)

        if (!this.do2024) {

          if (taxBase > 0 && taxBase <= 3981.69) {
            this.employee.FinTax1From = taxBase
            this.employee.FinTax1Am = this.round((taxBase * 0.2), 2)
            this.employee.FinTax2From = 0
            this.employee.FinTax2Am = 0
          }
          if (taxBase > 3981.69) {
            this.employee.FinTax1From = taxBase
            this.employee.FinTax1Am = 796.44
            this.employee.FinTax2From = (taxBase - 3981.69)
            this.employee.FinTax2Am = this.round(((taxBase - 3981.69) * 0.3), 2)
          }

          if (this.round(this.employee.FinTaxOnTaxPer ,2) > 0) {
            this.employee.FinTaxOnTaxAm = this.round(((this.employee.FinTax1Am + this.employee.FinTax2Am) * (this.employee.FinTaxOnTaxPer / 100)), 2)
          }
        }
        if (this.do2024) {
          this.employee.FinTaxOnTaxAm = 0
          if (taxBase > 0 && taxBase <= 4200) {
            this.employee.FinTax1From = taxBase
            this.employee.FinTax1Am = this.round((taxBase * (this.employee.FinTax1Per/100)), 2)
            this.employee.FinTax2From = 0
            this.employee.FinTax2Am = 0
          }
          if (taxBase > 4200) {
            this.employee.FinTax1From = taxBase
            this.employee.FinTax1Am = this.round((taxBase * (this.employee.FinTax1Per/100)), 2)
            this.employee.FinTax2From = (taxBase - 4200)
            this.employee.FinTax2Am = this.round(((taxBase - 4200) * (this.employee.FinTax2Per/100)), 2)
          }

        }

        this.employee.FinTaxTotal = this.round((this.employee.FinTax1Am + this.employee.FinTax2Am + this.employee.FinTaxOnTaxAm + this.employee.FinTax3To), 2)
      }
      if ((this.employee.FinNeto1ls - this.employee.FinFreeTotalToUse) <= 0 ) {

        // dont have tax
          this.employee.FinTax1From = 0
          this.employee.FinTax1Am = 0
          this.employee.FinTax2From = 0
          this.employee.FinTax2Am = 0

          this.employee.FinTax1From = 0
          this.employee.FinTax1Am = 0
          this.employee.FinTax2From = 0
          this.employee.FinTax2Am = 0

          this.employee.FinTaxOnTaxAm = this.round(((this.employee.FinTax1Am + this.employee.FinTax2Am) * this.round(this.employee.FinTaxOnTaxPer / 100 ,2)), 2)
        
          this.employee.FinTaxTotal = this.round((this.employee.FinTax1Am + this.employee.FinTax2Am + this.employee.FinTaxOnTaxAm), 2)
      }
      let addTaxReturn = 0

      // ako ima povrat poreza onda se dodaje na neto 
      if (this.$store.state.documentActivHead.Month === 11 && this.round(this.employee.FinTax3To,2) < 0) {
        if (this.employee.FinTaxTotal !== 0) {
          addTaxReturn= 0
        } else {
          addTaxReturn= this.round(Math.abs(this.employee.FinTax3To),2)
        }
      }

      // Neto 2
      this.employee.FinNeto2 = this.round((this.employee.FinNeto1ls - this.employee.FinTaxTotal + addTaxReturn), 2)

      //Neto 3
      this.employee.FinNeto3 = this.round((this.employee.FinNeto2 + this.employee.FinAdd1_Amount  + this.employee.FinAdd2_Amount  + this.employee.FinAdd3_Amount  + this.employee.FinAdd4_Amount + this.employee.NaHZZOiznos - (this.employee.Fin1LoanBankAmount) - (this.employee.Fin2LoanBankAmount) - (this.employee.Fin3LoanBankAmount)), 2)

      this.employee.Fin1bankAmount = this.round((this.employee.Fin2bankAmount), 2) > 0 ? this.employee.FinNeto3 - this.round((this.employee.Fin2bankAmount), 2) : this.employee.FinNeto3
      this.employee.FinTo1_Amount = this.employee.FinTo1_Percent && this.employee.FinTo1_Percent > 0 ? this.round((this.employee.FinBruto2 * (this.employee.FinTo1_Percent / 100)), 2) : 0
      this.employee.FinTo2_Amount = this.employee.FinTo2_Percent && this.employee.FinTo2_Percent > 0 ? this.round((this.employee.FinBruto2 * (this.employee.FinTo2_Percent / 100)), 2) : 0
      this.employee.FinTo3_Amount = this.employee.FinTo3_Percent && this.employee.FinTo3_Percent > 0 ? this.round((this.employee.FinBruto2 * (this.employee.FinTo3_Percent / 100)), 2) : 0
      const FinToAll = this.round((this.employee.FinTo1_Amount + this.employee.FinTo2_Amount + this.employee.FinTo3_Amount), 2)
      const FinAddAll = this.round((this.employee.FinAdd1_Amount  + this.employee.FinAdd2_Amount  + this.employee.FinAdd3_Amount  + this.employee.FinAdd4_Amount + this.employee.NaHZZOiznos), 2)
      this.employee.FinBruto3 = this.round((this.employee.FinBruto2 + FinAddAll + FinToAll), 2)
    },
    calculateHours() {
      this.employee.RedovanRadIznos = this.round((this.employee.RedovanRad * this.employee.FinWHvalue * (this.employee.RedovanRadPosto / 100)), 2)
      this.employee.NocniRadIznos = this.round((this.employee.NocniRad * this.employee.FinWHvalue * (this.employee.NocniRadPosto / 100)), 2)
      this.employee.PrekovremeniRadIznos = this.round((this.employee.PrekovremeniRad * this.employee.FinWHvalue * (this.employee.PrekovremeniRadPosto / 100)), 2)
      this.employee.NedjeljaRadIznos = this.round((this.employee.NedjeljaRad * this.employee.FinWHvalue * (this.employee.NedjeljaRadPosto / 100)), 2)
      this.employee.BlagdanRadIznos = this.round((this.employee.BlagdanRad * this.employee.FinWHvalue * (this.employee.BlagdanRadPosto / 100)), 2)
      this.employee.TerenRadIznos = this.round((this.employee.TerenRad * this.employee.FinWHvalue * (this.employee.TerenRadPosto / 100)), 2)
      this.employee.PreraspodjelaRadaIznos = this.round((this.employee.PreraspodjelaRada * this.employee.FinWHvalue * (this.employee.PreraspodjelaRadaPosto / 100)), 2)
      this.employee.PripravnostIznos = this.round((this.employee.Pripravnost * this.employee.FinWHvalue * (this.employee.PripravnostPosto / 100)), 2)
      this.employee.TjedniOdmorIznos = this.round((this.employee.TjedniOdmor * this.employee.FinWHvalue * (this.employee.TjedniOdmorPosto / 100)), 2)
      this.employee.NeradniBlagdanIznos = this.round((this.employee.NeradniBlagdan * this.employee.FinWHvalue * (this.employee.NeradniBlagdanPosto / 100)), 2)
      this.employee.GodisnjiOdmorIznos = this.round((this.employee.GodisnjiOdmor * this.employee.FinWHvalue * (this.employee.GodisnjiOdmorPosto / 100)), 2)
      this.employee.BolovanjeDo42DanaIznos = this.round((this.employee.BolovanjeDo42Dana * this.employee.FinWHvalue * (this.employee.BolovanjeDo42DanaPosto / 100)), 2)
      this.employee.BolovanjeOd42DanaIznos = this.round((this.employee.BolovanjeOd42Dana * this.employee.FinWHvalue * (this.employee.BolovanjeOd42DanaPosto / 100)), 2)
      this.employee.RodiljniIznos = this.round((this.employee.Rodiljni * this.employee.FinWHvalue * (this.employee.RodiljniPosto / 100)), 2)
      this.employee.PlaceniDopustIznos = this.round((this.employee.PlaceniDopust * this.employee.FinWHvalue * (this.employee.PlaceniDopustPosto / 100)), 2)
      this.employee.NeplaceniDopustIznos = this.round((this.employee.NeplaceniDopust * this.employee.FinWHvalue * (this.employee.NeplaceniDopustPosto / 100)), 2)
      this.employee.NenazocnostNaZahtjevRadnikaIznos = this.round((this.employee.NenazocnostNaZahtjevRadnika * this.employee.FinWHvalue * (this.employee.NenazocnostNaZahtjevRadnikaPosto / 100)), 2)
      this.employee.ZastojKrivnjomPoslodavcaIznos = this.round((this.employee.ZastojKrivnjomPoslodavca * this.employee.FinWHvalue * (this.employee.ZastojKrivnjomPoslodavcaPosto / 100)), 2)
      this.employee.StrajkIznos = this.round((this.employee.Strajk * this.employee.FinWHvalue * (this.employee.StrajkPosto / 100)), 2)
      this.employee.LockoutIznos = this.round((this.employee.Lockout * this.employee.FinWHvalue * (this.employee.LockoutPosto / 100)), 2)
      this.employee.VrijemeMirovanjaRadnogOdnosaIznos = this.round((this.employee.VrijemeMirovanjaRadnogOdnosa * this.employee.FinWHvalue * (this.employee.VrijemeMirovanjaRadnogOdnosaPosto / 100)), 2)
      this.employee.Dodatno1Iznos = this.round((this.employee.Dodatno1 * this.employee.FinWHvalue * (this.employee.Dodatno1Posto / 100)), 2)
      this.employee.Dodatno2Iznos = this.round((this.employee.Dodatno2 * this.employee.FinWHvalue * (this.employee.Dodatno2Posto / 100)), 2)

      this.employee.Ukupno = this.employee.RedovanRad + this.employee.NocniRad + this.employee.PrekovremeniRad + this.employee.NedjeljaRad + this.employee.BlagdanRad
      this.employee.Ukupno += this.employee.TerenRad + this.employee.PreraspodjelaRada + this.employee.Pripravnost + this.employee.TjedniOdmor
      this.employee.Ukupno += this.employee.NeradniBlagdan + this.employee.GodisnjiOdmor + this.employee.BolovanjeDo42Dana + this.employee.BolovanjeOd42Dana
      this.employee.Ukupno += this.employee.Rodiljni + this.employee.PlaceniDopust + this.employee.NeplaceniDopust + this.employee.NenazocnostNaZahtjevRadnika
      this.employee.Ukupno += this.employee.ZastojKrivnjomPoslodavca + this.employee.Strajk + this.employee.Lockout + this.employee.VrijemeMirovanjaRadnogOdnosa
      this.employee.Ukupno += this.employee.Dodatno1 + this.employee.Dodatno2

      this.employee.UkupnoIznos = this.employee.RedovanRadIznos + this.employee.NocniRadIznos + this.employee.PrekovremeniRadIznos + this.employee.NedjeljaRadIznos + this.employee.BlagdanRadIznos
      this.employee.UkupnoIznos += this.employee.TerenRadIznos + this.employee.PreraspodjelaRadaIznos + this.employee.PripravnostIznos + this.employee.TjedniOdmorIznos
      this.employee.UkupnoIznos += this.employee.NeradniBlagdanIznos + this.employee.GodisnjiOdmorIznos + this.employee.BolovanjeDo42DanaIznos + this.employee.BolovanjeOd42DanaIznos
      this.employee.UkupnoIznos += this.employee.RodiljniIznos + this.employee.PlaceniDopustIznos + this.employee.NeplaceniDopustIznos + this.employee.NenazocnostNaZahtjevRadnikaIznos
      this.employee.UkupnoIznos += this.employee.ZastojKrivnjomPoslodavcaIznos + this.employee.StrajkIznos + this.employee.LockoutIznos + this.employee.VrijemeMirovanjaRadnogOdnosaIznos
      this.employee.UkupnoIznos += this.employee.Dodatno1Iznos + this.employee.Dodatno2Iznos
      this.employee.UkupnoIznos = this.round(this.employee.UkupnoIznos, 2)
      if (this.employee.CalculateByHours === true) {
        this.employee.FinBruto1 = this.employee.UkupnoIznos
      }
    },
    async createYearCard() {
        const reportArr = []
        const allYear = []
        const totals = {}

        const query = {}
        query.CompanyId = this.$store.state.companyid
        query.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2023
        //query.fromDate = dayjs([bYear]).startOf('year').format('YYYY-MM-DD')
        //query.toDate = dayjs([bYear]).endOf('year').format('YYYY-MM-DD')
        console.log('query', query)
 
         await SalarieService.choose(query)
         .then(async(res) => {
          console.log('res', res)
           const allSalaries = res && res.data && res.data.salaries ? res.data.salaries : []

            console.log('1 allSalaries', allSalaries)
            if (allSalaries) {
                const arrySalarieIDs = []
                allSalaries.map(sal => {
                  arrySalarieIDs.push(parseInt(sal.id))
                })
                const qry ={}
                qry.CompanyId = this.$store.state.companyid
                qry.arrySalarieIDs = [...arrySalarieIDs]
                qry.empID = parseInt(this.employee.empID)
                const res2 = await SalarieDetailService.choose(qry)
                const allYear = res2 && res2.data && res2.data.salariedetails ? res2.data.salariedetails : null

              // let results = allSalaries.map(async(salarie) => {
              //   const qry ={}
              //   qry.CompanyId = this.$store.state.companyid
              //   qry.databaseDocID = salarie && salarie.id ? salarie.id : 999999999
              //   qry.empID = parseInt(this.employee.empID)
              //   const res2 = await SalarieDetailService.choose(qry)
              //   const detData = res2 && res2.data && res2.data.salariedetails ? res2.data.salariedetails : null
              //   if (detData) {
              //     allYear.push(...detData)
              //   }
              // })
              // results = await Promise.all(results)

              console.log('2 allYear', allYear)

              
              totals.FinTax = 0
              totals.Porez = 0
              totals.razlikaPoreza = 0
              if (allYear) {
                  let FinBruto2 = 0
                  let FinFrom1_Amount = 0
                  let FinFrom2_Amount = 0
                  let FinFrom3_Amount = 0
                  let FinFrom4_Amount = 0
                  let FinNeto1ls = 0
                  let FinFreeTotalToUse = 0
                  let FinTax1Am = 0
                  let FinTax2Am = 0
                  let Porez20 = 0
                  let Porez30 = 0
                  let FinTaxOnTaxAm = 0
                  let FinNeto2 = 0
        
                  if (allYear && allYear.length > 0) {

                      allYear.map(detEmp => {
                        // go calculate
                        FinBruto2 += round((detEmp.FinBruto2), 2)
                        FinFrom1_Amount += round((detEmp.FinFrom1_Amount),2)
                        FinFrom2_Amount += round((detEmp.FinFrom2_Amount),2)
                        FinFrom3_Amount += round((detEmp.FinFrom3_Amount),2)
                        FinFrom4_Amount += round((detEmp.FinFrom4_Amount),2)
                        const DoprinosiIZTotal = round((detEmp.FinFrom1_Amount),2) + round((detEmp.FinFrom2_Amount),2) + round((detEmp.FinFrom3_Amount),2) + round((detEmp.FinFrom4_Amount),2)
                        FinNeto1ls += round((detEmp.FinNeto1ls),2)
                        FinFreeTotalToUse += round((detEmp.FinFreeTotalToUse),2)
                        FinTax1Am += round((detEmp.FinTax1Am),2)
                        FinTax2Am += round((detEmp.FinTax2Am),2)
                        FinTaxOnTaxAm += round((detEmp.FinTaxOnTaxAm), 2)
                        FinNeto2 = round((detEmp.FinNeto2), 2)
                        const reportItem = {}
                        reportItem.empID = detEmp.empID
                        reportItem.eemail = detEmp.eemail
                        reportItem.First = detEmp.First
                        reportItem.Last = detEmp.Last
                        reportItem.VatID = detEmp.VatID
                        reportItem.Mjesec = parseInt(detEmp.Month)
                        reportItem.Godina = parseInt(detEmp.Year)
                        reportItem.Month = detEmp.Month.toString() + '/' + detEmp.Year.toString()
                        reportItem.FinBruto2 = round((detEmp.FinBruto2), 2)
                        reportItem.DoprinosiIZTotal = DoprinosiIZTotal
                        reportItem.FinNeto1ls = round((detEmp.FinNeto1ls),2)
                        reportItem.FinFreeTotalToUse = round((detEmp.FinFreeTotalToUse),2)
                        reportItem.FinTax1Am = round((detEmp.FinTax1Am),2)
                        reportItem.FinTax2Am = round((detEmp.FinTax2Am),2)
                        reportItem.Prirez = round(FinTaxOnTaxAm,2)
                        reportItem.UkPorezPrirez = round((detEmp.FinTax1Am),2) + round((detEmp.FinTax2Am),2)  + round(FinTaxOnTaxAm,2)
                        reportItem.Neto = round(FinNeto2,2)
                        reportArr.push(reportItem)
                        totals.eemail = detEmp.eemail
                        totals.First = detEmp.First
                        totals.Last = detEmp.Last
                        totals.VatID = detEmp.VatID
                      })
                      
                      totals.FinBruto2 = round((FinBruto2), 2)
                      totals.DoprinosiIZTotal = round((FinFrom1_Amount),2) + round((FinFrom2_Amount),2) + round((FinFrom3_Amount),2) + round((FinFrom4_Amount),2)
                      totals.FinNeto1ls = round((FinNeto1ls),2)
                      totals.FinFreeTotalToUse = round((FinFreeTotalToUse),2)
                      totals.FinTax1Am = round((FinTax1Am),2)
                      totals.FinTax2Am = round((FinTax2Am),2)
                      totals.Prirez = round(FinTaxOnTaxAm,2)
                      totals.UkPorezPrirez = round((FinTax1Am),2) + round((FinTax2Am),2)  + round(FinTaxOnTaxAm,2)
                      totals.Neto = round(FinNeto2,2)
                      let OsnovicaZaPorez = FinNeto1ls - round(FinFreeTotalToUse,2)
                      if (this.round(OsnovicaZaPorez,2) < 0) {
                        OsnovicaZaPorez = 0
                      }

                      if (OsnovicaZaPorez <= 47780.28) {
                        Porez20 = round((OsnovicaZaPorez * 0.2),2)
                      }
                      if (OsnovicaZaPorez > 47780.28) {
                        Porez20 = round((47780.28 * 0.2),2)
                        Porez30 = round(((OsnovicaZaPorez - 47780.28) * 0.3),2)
                      }

                      totals.stariPorez = round(((FinTax1Am + FinTax2Am) + FinTaxOnTaxAm),2)
                      totals.noviPorez = round(((Porez20 + Porez30) + round(((Porez20 + Porez30) * (employee.FinTaxOnTaxPer / 100)),2)),2)
                      totals.razlikaPoreza =  totals.stariPorez - totals.noviPorez
                      totals.FinTax += round((FinTax1Am + FinTax2Am)+ FinTaxOnTaxAm, 2)
                      totals.Porez += round((totals.noviPorez),2)
                  }
              }
            }
        })
        .catch(err=> {
          console.log('err', err)
        })
        if (reportArr && reportArr.length > 0) {
          reportArr.sort(dynamicSort("Mjesec"))
        }
        const card = {...this.$store.state.documentActivHead}
        card.head1 = reportArr
        card.head2 = 'KARTICA GODIŠNJEG OBRAČUNA POREZA NA DOHODAK ZA GODINU ' +  (this.$store.state.businessYear ? this.$store.state.businessYear.toString() : '2023')
        card.head3 = totals ? totals : {}
        card.head4 = totals.First + ' ' + totals.Last + ', ' +  totals.eemail + ', OIB: ' + totals.VatID
        card.head5 = null
        await this.$store.dispatch('setPrintObj', card)
        await this.$refs.prnt5.print()
      
    }
  },
  computed: {
    kpr() {
      return (this.round(this.employee.CityTax,2) / 100 ) + 1 
    },
    kppr20() {
      return ((20 * this.kpr) / (100-(20 * this.kpr))) + 1 
    },
    kppr30() {
      return ((30 * this.kpr) / (100-(30 * this.kpr))) + 1
    },

  },
  watch: {
    'employee.FinFreeDisabled' () {
      if (!this.employee.FinFreeDisabled) {
        this.employee.FinFree1Indeks = 1.6
        this.employee.FinFree1OnAm = this.osobniOdbitak
        this.employee.ChildPer = 100
      }
      if (this.employee.FinFreeDisabled) {
        this.employee.FinFree1Indeks = 1.6
        this.employee.FinFree1OnAm = 0
        this.employee.FinFree2OnAm = 0
        this.employee.FinFree3OnAm = 0
        this.employee.FinFree4OnAm = 0
        this.employee.ChildPer = 0
      }
      this.calcPersonalFree()
      this.calculateSal()
    },
    'employee.Child1' () {
      this.calcPersonalFree()
      this.calculateSal()
    },
    'employee.Child2' () {
      this.calcPersonalFree()
      this.calculateSal()
    },
    'employee.Child3' () {
      this.calcPersonalFree()
      this.calculateSal()
    },
    'employee.Child4' () {
      this.calcPersonalFree()
      this.calculateSal()
    },
    'employee.ChildPer' () {
      if (this.employee.ChildPer === null) {
        this.employee.ChildPer = 0
      }
      this.calcPersonalFree()
      this.calculateSal()
    },
    'employee.FinFree1OnAm' () {
      this.calcPersonalFree()
      this.calculateSal()
    },
    'selectBank1' () {
      if (this.selectBank1) {
        this.employee.Fin1bankAccountId = this.selectBank1.vatid
        this.employee.Fin1bankAccountName = this.selectBank1.text
        }
     // if (Object.keys(this.selectBank1).length === 0) {this.employee.Fin1bankAccountId = null}
    },
    'selectBank2' () {
      if (this.selectBank2) {
        this.employee.Fin2bankAccountId = this.selectBank2.vatid
        this.employee.Fin2bankAccountName = this.selectBank2.text
      }
//if (Object.keys(this.selectBank2).length === 0) {this.employee.Fin2bankAccountId = null}
    },
    'selectBankLoan1' () {
      if (this.selectBankLoan1) {this.employee.Fin1LoanBankAccountId = this.selectBankLoan1.vatid}
//if (Object.keys(this.selectBankLoan1).length === 0) {this.employee.Fin1LoanBankAccountId = null}
    },
    'selectBankLoan2' () {
      if (this.selectBankLoan2) {this.employee.Fin2LoanBankAccountId = this.selectBankLoan2.vatid}
//if (Object.keys(this.selectBankLoan2).length === 0) {this.employee.Fin2LoanBankAccountId = null}
    },
    'selectBankLoan3' () {
      if (this.selectBankLoan3) {this.employee.Fin3LoanBankAccountId = this.selectBankLoan3.vatid}
  //    if (Object.keys(this.selectBankLoan3).length === 0) {this.employee.Fin3LoanBankAccountId = null}
    },

    'selectSalariesFrom1' () {
      if (this.selectSalariesFrom1 && this.selectSalariesFrom1.value) {
        this.employee.FinFrom1_id = this.selectSalariesFrom1 && this.selectSalariesFrom1.value ? this.selectSalariesFrom1.value : null
        this.employee.FinFrom1_Desc = this.selectSalariesFrom1 && this.selectSalariesFrom1.descHR ? this.selectSalariesFrom1.descHR : null
        this.employee.FinFrom1_Percent = this.selectSalariesFrom1 && this.selectSalariesFrom1.per ? this.selectSalariesFrom1.per : 0
        this.employee.FinFrom1_Amount = 0
      }
      if (!this.selectSalariesFrom1) {
        this.employee.FinFrom1_id = null
        this.employee.FinFrom1_Desc = null
        this.employee.FinFrom1_Percent = 0
        this.employee.FinFrom1_Amount = 0
      }
      this.calculateSal()    
    },
    'selectSalariesFrom2' () {
      if (this.selectSalariesFrom2 && this.selectSalariesFrom2.value) {
        this.employee.FinFrom2_id = this.selectSalariesFrom2 && this.selectSalariesFrom2.value ? this.selectSalariesFrom2.value : null
        this.employee.FinFrom2_Desc = this.selectSalariesFrom2 && this.selectSalariesFrom2.descHR ? this.selectSalariesFrom2.descHR : null
        this.employee.FinFrom2_Percent = this.selectSalariesFrom2 && this.selectSalariesFrom2.per ? this.selectSalariesFrom2.per : 0
        this.employee.FinFrom2_Amount = 0
      }
      if (!this.selectSalariesFrom2) {
        this.employee.FinFrom2_id = null
        this.employee.FinFrom2_Desc = null
        this.employee.FinFrom2_Percent = 0
        this.employee.FinFrom2_Amount = 0
      }
      this.calculateSal()   
    },
    'selectSalariesFrom3' () {
      if (this.selectSalariesFrom3 && this.selectSalariesFrom3.value) {
        this.employee.FinFrom3_id = this.selectSalariesFrom3 && this.selectSalariesFrom3.value ? this.selectSalariesFrom3.value : null
        this.employee.FinFrom3_Desc = this.selectSalariesFrom3 && this.selectSalariesFrom3.descHR ? this.selectSalariesFrom3.descHR : null
        this.employee.FinFrom3_Percent = this.selectSalariesFrom3 && this.selectSalariesFrom3.per ? this.selectSalariesFrom3.per : 0
        this.employee.FinFrom3_Amount = 0
      }
      if (!this.selectSalariesFrom3) {
        this.employee.FinFrom3_id = null
        this.employee.FinFrom3_Desc = null
        this.employee.FinFrom3_Percent = 0
        this.employee.FinFrom3_Amount = 0
      }
      this.calculateSal()
    },
    'selectSalariesFrom4' () {
      if (this.selectSalariesFrom4 && this.selectSalariesFrom4.value) {
        this.employee.FinFrom4_id = this.selectSalariesFrom4 && this.selectSalariesFrom4.value ? this.selectSalariesFrom4.value : null
        this.employee.FinFrom4_Desc = this.selectSalariesFrom4 && this.selectSalariesFrom4.descHR ? this.selectSalariesFrom4.descHR : null
        this.employee.FinFrom4_Percent = this.selectSalariesFrom4 && this.selectSalariesFrom4.per ? this.selectSalariesFrom4.per : 0
        this.employee.FinFrom4_Amount = 0
      }
      if (!this.selectSalariesFrom4) {
        this.employee.FinFrom4_id = null
        this.employee.FinFrom4_Desc = null
        this.employee.FinFrom4_Percent = 0
        this.employee.FinFrom4_Amount = 0
      }
      this.calculateSal()
    },
    'selectSalariesTo1' () {
      if (this.selectSalariesTo1 && this.selectSalariesTo1.value) {
        this.employee.FinTo1_id = this.selectSalariesTo1 && this.selectSalariesTo1.value ? this.selectSalariesTo1.value : null
        this.employee.FinTo1_Desc = this.selectSalariesTo1 && this.selectSalariesTo1.descHR ? this.selectSalariesTo1.descHR : null
        this.employee.FinTo1_Percent = this.selectSalariesTo1 && this.selectSalariesTo1.per ? this.selectSalariesTo1.per : 0
        this.employee.FinTo1_Amount = 0
      }
      if (!this.selectSalariesTo1) {
        this.employee.FinTo1_id = null
        this.employee.FinTo1_Desc = null
        this.employee.FinTo1_Percent = 0
        this.employee.FinTo1_Amount = 0
      }
      this.calculateSal()
    },
    'selectSalariesTo2' () {
      if (this.selectSalariesTo2 && this.selectSalariesTo2.value) {
        this.employee.FinTo2_id = this.selectSalariesTo2 && this.selectSalariesTo2.value ? this.selectSalariesTo2.value : null
        this.employee.FinTo2_Desc = this.selectSalariesTo2 && this.selectSalariesTo2.descHR ? this.selectSalariesTo2.descHR : null
        this.employee.FinTo2_Percent = this.selectSalariesTo2 && this.selectSalariesTo2.per ? this.selectSalariesTo2.per : 0
        this.employee.FinTo2_Amount = 0
      }
      if (!this.selectSalariesTo2) {
        this.employee.FinTo2_id = null
        this.employee.FinTo2_Desc = null
        this.employee.FinTo2_Percent = 0
        this.employee.FinTo2_Amount = 0
      }
      this.calculateSal()
    },
    'selectSalariesTo3' () {
      if (this.selectSalariesTo3 && this.selectSalariesTo3.value) {
        this.employee.FinTo3_id = this.selectSalariesTo3 && this.selectSalariesTo3.value ? this.selectSalariesTo3.value : null
        this.employee.FinTo3_Desc = this.selectSalariesTo3 && this.selectSalariesTo3.descHR ? this.selectSalariesTo3.descHR : null
        this.employee.FinTo3_Percent = this.selectSalariesTo3 && this.selectSalariesTo3.per ? this.selectSalariesTo3.per : 0
        this.employee.FinTo3_Amount = 0
      }
      if (!this.selectSalariesTo3) {
        this.employee.FinTo3_id = null
        this.employee.FinTo3_Desc = null
        this.employee.FinTo3_Percent = 0
        this.employee.FinTo3_Amount = 0
      }
      this.calculateSal() 
    },
    'selectChildren' () {
      if (this.selectChildren) {
        this.employee.FinFree2Indeks = this.selectChildren.calc ? this.selectChildren.calc : 0
        this.employee.FinFree2OnAm = this.selectChildren.value ? this.selectChildren.value : 0
        this.employee.FinFree2Am = this.selectChildren.amount ? this.selectChildren.amount : 0
      }
      if (!this.selectChildren) {
        this.employee.FinFree2Indeks = 0
        this.employee.FinFree2OnAm = null
        this.employee.FinFree2Am = 0
      }
      this.calcPersonalFree()
      this.calculateSal()
    },
    'selectFamilySupport' () {
      if (this.selectFamilySupport) {
        this.employee.FinFree3Indeks = this.selectFamilySupport && this.selectFamilySupport.calc ? this.selectFamilySupport.calc : 0
        this.employee.FinFree3OnAm = this.selectFamilySupport && this.selectFamilySupport.value ? this.selectFamilySupport.value : 0
        this.employee.FinFree3Am = this.selectFamilySupport && this.selectFamilySupport.amount ? this.selectFamilySupport.amount : 0
      }
      if (!this.selectFamilySupport) {
        this.employee.FinFree3Indeks = 0
        this.employee.FinFree3OnAm = null
        this.employee.FinFree3Am = 0
      }
      this.calcPersonalFree()
      this.calculateSal()
    },
    'selectInvalidType' () {
      if (this.selectInvalidType) {
        this.employee.FinFree4In = this.selectInvalidType && this.selectInvalidType.calc ? this.selectInvalidType.calc : 0
        this.employee.FinFree4OnAm = this.selectInvalidType && this.selectInvalidType.value ? this.selectInvalidType.value : 0
        this.employee.FinFree4Am = this.selectInvalidType && this.selectInvalidType.amount ? this.selectInvalidType.amount : 0
      }
      if (!this.selectInvalidType) {
        this.employee.FinFree4In = 0
        this.employee.FinFree4OnAm = null
        this.employee.FinFree4Am = 0
      }
      this.calcPersonalFree()
      this.calculateSal()
    },
    'employee.FinFreeTotalPer' () {
      if (this.employee.FinFreeTotalPer) {
        //this.employee.FinFreeTotalPer = parseFloat(this.employee.FinFreeTotalPer)
        this.employee.FinFreeTotalPer = this.employee.FinFreeTotalPer > 100 ? 100 : this.employee.FinFreeTotalPer
      }
      if (!this.employee.FinFreeTotalPer) {
        this.employee.FinFreeTotalPer = 0
      }
      this.calcPersonalFree()
      this.calculateSal()
    },
    'employee.FinKoef1' () {
      if (this.employee.FinKoef1 === null) {
        this.employee.FinKoef1 = 0
      }
      //this.employee.FinKoef1 = parseFloat(this.employee.FinKoef1)
      this.calculateSal()
    },
    'employee.FinKoef2' () {
      if (this.employee.FinKoef2 === null) {
        this.employee.FinKoef2 = 0
      }
     // this.employee.FinKoef2 = parseFloat(this.employee.FinKoef2)
      this.calculateSal()
    },
    'employee.FinBruto0' () {
      if (this.employee.FinBruto0 === null) {
        this.employee.FinBruto0 = 0
      }
      //this.employee.FinBruto0 = parseFloat(this.employee.FinBruto0)
      if (this.employee.FinWH && this.round(this.employee.FinWH,2) > 0) {
        this.employee.FinWHvalue = this.round((this.employee.FinBruto0 / this.round(this.employee.FinWH,2)), 5)
      }
      this.calculateHours()
      this.calculateSal()
    },
    'employee.FinBrutoOther' () {
      this.calculateSal()
    },
    'employee.IskoristenoPravo' () {
      this.calculateSal()
    },
    'employee.Joppd129' () {
      this.calculateSal()
    },
    'employee.FinBruto1' () {
      if (round(this.employee.FinBruto1, 2) !== 0) {
        this.FinBruto1Kn = this.round((this.employee.FinBruto1 * 7.5345) ,2)
      }
      this.calculateSal()
    },
    'employee.FinAdd1_Amount' () {
      this.calculateSal()
    },
    'employee.FinAdd2_Amount' () {
      this.calculateSal()
    },
    'employee.FinAdd3_Amount' () {
      this.calculateSal()
    },
    'employee.FinAdd4_Amount' () {
      this.calculateSal()
    },
    'employee.NaHZZOiznos' () {
      this.calculateSal()
    },
    'employee.Fin1LoanBankAmount' () {
      this.calculateSal()
    },
    'employee.Fin2LoanBankAmount' () {
      this.calculateSal()
    },
    'employee.Fin3LoanBankAmount' () {
      this.calculateSal()
    },
    'employee.NeRacunajUmanjenje' () {
      this.calculateSal()
    },
    'oneNotaxaddition' () {
      if (this.oneNotaxaddition) {
        this.employee.FinAdd1_TypeId = this.oneNotaxaddition.value
        this.employee.FinAdd1_TypeProtected = this.oneNotaxaddition.protected
      }
      if (!this.oneNotaxaddition) {
        this.employee.FinAdd1_TypeId = 0
        this.employee.FinAdd1_TypeProtected = false
      }
    },
    'twoNotaxaddition' () {
      if (this.twoNotaxaddition) {
        this.employee.FinAdd2_TypeId = this.twoNotaxaddition.value
        this.employee.FinAdd2_TypeProtected = this.twoNotaxaddition.protected
      }
      if (!this.twoNotaxaddition) {
        this.employee.FinAdd2_TypeId = 0
        this.employee.FinAdd2_TypeProtected = false
      }
    },
    'threeNotaxaddition' () {
      if (this.threeNotaxaddition) {
        this.employee.FinAdd3_TypeId = this.threeNotaxaddition.value
        this.employee.FinAdd3_TypeProtected = this.threeNotaxaddition.protected
      }
      if (!this.threeNotaxaddition) {
        this.employee.FinAdd3_TypeId = 0
        this.employee.FinAdd3_TypeProtected = false
      }
    },
    'fourNotaxaddition' () {
      if (this.fourNotaxaddition) {
        this.employee.FinAdd4_TypeId = this.fourNotaxaddition.value
        this.employee.FinAdd4_TypeProtected = this.fourNotaxaddition.protected
      }
      if (!this.fourNotaxaddition) {
        this.employee.FinAdd4_TypeId = 0
        this.employee.FinAdd4_TypeProtected = false
      }
    },

    'oneJoppd' () {
      this.employee.FinAdd1_JoppId = this.oneJoppd ? this.oneJoppd.value : 0
    },
    'twoJoppd' () {
      this.employee.FinAdd2_JoppId = this.twoJoppd ? this.twoJoppd.value : 0
    },
    'threeJoppd' () {
      this.employee.FinAdd3_JoppId = this.threeJoppd ? this.threeJoppd.value : 0
    },
    'fourJoppd' () {
      this.employee.FinAdd4_JoppId = this.fourJoppd ? this.fourJoppd.value : 0
    },
    'selectCitytax' () {
      
      if (this.selectCitytax) {
        this.employee.FinTaxOnTaxPer = parseFloat(this.selectCitytax.value)
      }
      if (!this.selectCitytax) {
        this.employee.CityTax = 0
        this.employee.FinTaxOnTaxPer = 0
        this.employee.FinTaxOnTaxAm = 0
      }
      this.calculateSal()
    },
    // 'employee.FinTaxOnTaxPer' () {
    //   if (this.employee.FinTaxOnTaxPer === null) {
    //     this.employee.FinTaxOnTaxPer = 0
    //   }
    //   this.calculateSal()
    // },
    'selectCity' () {
      if (this.selectCity) {
        this.employee.FinTaxOnTaxCityCode = this.selectCity.CityCode
        this.employee.FinTaxOnTaxCityName = this.selectCity.CityName
      }
      if (!this.selectCity) {
        this.employee.FinTaxOnTaxCityCode = null
        this.employee.FinTaxOnTaxCityName = null
      }
    },
    'employee.FinTax1Per' () {
      if (this.employee.FinTax1Per === null) {
        this.employee.FinTax1Per = 0
      }
 
      this.calculateSal()
    },
    'employee.FinTax2Per' () {
      if (this.employee.FinTax2Per === null) {
        this.employee.FinTax2Per = 0
      }
      this.calculateSal()
    },
    'employee.RedovanRad' () {
      if (this.employee.RedovanRad === null) {
        this.employee.RedovanRad = 0
      }
      if (this.employee.RedovanRad > 500) {
        this.employee.RedovanRad = 0
      }
      this.calculateHours()
    },
    'employee.NocniRad' () {
      if (this.employee.NocniRad === null) {
        this.employee.NocniRad = 0
      }
      if (this.employee.NocniRad > 500) {
        this.employee.NocniRad = 0
      }
      this.calculateHours()
    },
    'employee.PrekovremeniRad' () {
      if (this.employee.PrekovremeniRad === null) {
        this.employee.PrekovremeniRad = 0
      }
      if (this.employee.PrekovremeniRad > 500) {
        this.employee.PrekovremeniRad = 0
      }
      this.calculateHours()
    },
    'employee.NedjeljaRad' () {
      if (this.employee.NedjeljaRad === null) {
        this.employee.NedjeljaRad = 0
      }
      if (this.employee.NedjeljaRad > 500) {
        this.employee.NedjeljaRad = 0
      }
      this.calculateHours()
    },
    'employee.BlagdanRad' () {
      if (this.employee.BlagdanRad  === null) {
        this.employee.BlagdanRad = 0
      }
      if (this.employee.BlagdanRad > 500) {
        this.employee.BlagdanRad = 0
      }
      this.calculateHours()
    },
    'employee.TerenRad' () {
      if (this.employee.TerenRad === null) {
        this.employee.TerenRad = 0
      }
      if (this.employee.TerenRad > 500) {
        this.employee.TerenRad = 0
      }
      this.calculateHours()
    },
    'employee.PreraspodjelaRada' () {
      if (this.employee.PreraspodjelaRada === null) {
        this.employee.PreraspodjelaRada = 0
      }
      if (this.employee.PreraspodjelaRada > 500) {
        this.employee.PreraspodjelaRada = 0
      }
      this.calculateHours()
    },
    'employee.Pripravnost' () {
      if (!this.employee.Pripravnost) {
        this.employee.Pripravnost = 0
      }
      if (this.employee.Pripravnost > 500) {
        this.employee.Pripravnost = 0
      }
      this.calculateHours()
    },
    'employee.DnevniOdmor' () {
      if (this.employee.DnevniOdmor === null) {
        this.employee.DnevniOdmor = 0
      }
      if (this.employee.DnevniOdmor > 500) {
        this.employee.DnevniOdmor = 0
      }
      this.calculateHours()
    },
    'employee.TjedniOdmor' () {
      if (this.employee.TjedniOdmor === null) {
        this.employee.TjedniOdmor = 0
      }
      if (this.employee.TjedniOdmor > 500) {
        this.employee.TjedniOdmor = 0
      }
      this.calculateHours()
    },
    'employee.NeradniBlagdan' () {
      if (this.employee.NeradniBlagdan === null) {
        this.employee.NeradniBlagdan = 0
      }
      if (this.employee.NeradniBlagdan > 500) {
        this.employee.NeradniBlagdan = 0
      }
      this.calculateHours()
    },
    'employee.GodisnjiOdmor' () {
      if (this.employee.GodisnjiOdmor === null) {
        this.employee.GodisnjiOdmor = 0
      }
      if (this.employee.GodisnjiOdmor > 500) {
        this.employee.GodisnjiOdmor = 0
      }
      this.calculateHours()
    },
    'employee.BolovanjeDo42Dana' () {
      if (this.employee.BolovanjeDo42Dana === null) {
        this.employee.BolovanjeDo42Dana = 0
      }
      if (this.employee.BolovanjeDo42Dana > 500) {
        this.employee.BolovanjeDo42Dana = 0
      }
      this.calculateHours()
    },
    'employee.BolovanjeOd42Dana' () {
      if (this.employee.BolovanjeOd42Dana === null) {
        this.employee.BolovanjeOd42Dana = 0
      }
      if (this.employee.BolovanjeOd42Dana > 500) {
        this.employee.BolovanjeOd42Dana = 0
      }
      this.calculateHours()
    },
    'employee.Rodiljni' () {
      if (this.employee.Rodiljni === null) {
        this.employee.Rodiljni = 0
      }
      if (this.employee.Rodiljni > 500) {
        this.employee.Rodiljni = 0
      }
      this.calculateHours()
    },
    'employee.PlaceniDopust' () {
      if (!this.employee.PlaceniDopust) {
        this.employee.PlaceniDopust = 0
      }
      if (this.employee.PlaceniDopust > 500) {
        this.employee.PlaceniDopust = 0
      }
      this.calculateHours()
    },
    'employee.NeplaceniDopust' () {
      if (!this.employee.NeplaceniDopust) {
        this.employee.NeplaceniDopust = 0
      }
      if (this.employee.NeplaceniDopust > 500) {
        this.employee.NeplaceniDopust = 0
      }
      this.calculateHours()
    },
    'employee.NenazocnostNaZahtjevRadnika' () {
      if (!this.employee.NenazocnostNaZahtjevRadnika) {
        this.employee.NenazocnostNaZahtjevRadnika = 0
      }
      if (this.employee.NenazocnostNaZahtjevRadnika > 500) {
        this.employee.NenazocnostNaZahtjevRadnika = 0
      }
      this.calculateHours()
    },
    'employee.ZastojKrivnjomPoslodavca' () {
      if (!this.employee.ZastojKrivnjomPoslodavca) {
        this.employee.ZastojKrivnjomPoslodavca = 0
      }
      if (this.employee.ZastojKrivnjomPoslodavca > 500) {
        this.employee.ZastojKrivnjomPoslodavca = 0
      }
      this.calculateHours()
    },
    'employee.Strajk' () {
      if (!this.employee.Strajk) {
        this.employee.Strajk = 0
      }
      if (this.employee.Strajk > 500) {
        this.employee.Strajk = 0
      }
      this.calculateHours()
    },
    'employee.Lockout' () {
      if (!this.employee.Lockout) {
        this.employee.Lockout = 0
      }
      if (this.employee.Lockout > 500) {
        this.employee.Lockout = 0
      }
      this.calculateHours()
    },
    'employee.VrijemeMirovanjaRadnogOdnosa' () {
      if (!this.employee.VrijemeMirovanjaRadnogOdnosa) {
        this.employee.VrijemeMirovanjaRadnogOdnosa = 0
      }
      if (this.employee.VrijemeMirovanjaRadnogOdnosa > 500) {
        this.employee.VrijemeMirovanjaRadnogOdnosa = 0
      }
      this.calculateHours()
    },
    'employee.Dodatno1' () {
      if (!this.employee.Dodatno1) {
        this.employee.Dodatno1 = 0
      }
      if (this.employee.Dodatno1 > 500) {
        this.employee.Dodatno1 = 0
      }
      this.calculateHours()
    },
    'employee.Dodatno2' () {
      if (!this.employee.Dodatno2) {
        this.employee.Dodatno2 = 0
      }
      if (this.employee.Dodatno2 > 500) {
        this.employee.Dodatno2 = 0
      }
      this.calculateHours()
    },
  // --------------------------------------------------------------------
    'employee.RedovanRadPosto' () {
      if (!this.employee.RedovanRadPosto) {
        this.employee.RedovanRadPosto = 0
      }
      if (this.employee.RedovanRadPosto > 500) {
        this.employee.RedovanRadPosto = 0
      }
      this.calculateHours()
    },
    'employee.NocniRadPosto' () {
      if (!this.employee.NocniRadPosto) {
        this.employee.NocniRadPosto = 0
      }
      if (this.employee.NocniRadPosto > 500) {
        this.employee.NocniRadPosto = 0
      }
      this.calculateHours()
    },
    'employee.PrekovremeniRadPosto' () {
      if (!this.employee.PrekovremeniRadPosto) {
        this.employee.PrekovremeniRadPosto = 0
      }
      if (this.employee.PrekovremeniRadPosto > 500) {
        this.employee.PrekovremeniRadPosto = 0
      }
      this.calculateHours()
    },
    'employee.NedjeljaRadPosto' () {
      if (!this.employee.NedjeljaRadPosto) {
        this.employee.NedjeljaRadPosto = 0
      }
      if (this.employee.NedjeljaRadPosto > 500) {
        this.employee.NedjeljaRadPosto= 0
      }
      this.calculateHours()
    },
    'employee.BlagdanRadPosto' () {
      if (!this.employee.BlagdanRadPosto) {
        this.employee.BlagdanRadPosto = 0
      }
      if (this.employee.BlagdanRadPosto > 500) {
        this.employee.BlagdanRadPosto = 0
      }
      this.calculateHours()
    },
    'employee.TerenRadPosto' () {
      if (!this.employee.TerenRadPosto) {
        this.employee.TerenRadPosto = 0
      }
      if (this.employee.TerenRadPosto > 500) {
        this.employee.TerenRadPosto = 0
      }
      this.calculateHours()
    },
    'employee.PreraspodjelaRadaPosto' () {
      if (!this.employee.PreraspodjelaRadaPosto) {
        this.employee.PreraspodjelaRadaPosto = 0
      }
      if (this.employee.PreraspodjelaRadaPosto > 500) {
        this.employee.PreraspodjelaRadaPosto = 0
      }
      this.calculateHours()
    },
    'employee.PripravnostPosto' () {
      if (!this.employee.PripravnostPosto) {
        this.employee.PripravnostPosto = 0
      }
      if (this.employee.PripravnostPosto > 500) {
        this.employee.PripravnostPosto = 0
      }
      this.calculateHours()
    },
    'employee.DnevniOdmorPosto' () {
      if (!this.employee.DnevniOdmorPosto) {
        this.employee.DnevniOdmorPosto = 0
      }
      if (this.employee.DnevniOdmorPosto > 500) {
        this.employee.DnevniOdmorPosto = 0
      }
      this.calculateHours()
    },
    'employee.TjedniOdmorPosto' () {
      if (!this.employee.TjedniOdmorPosto) {
        this.employee.TjedniOdmorPosto = 0
      }
      if (this.employee.TjedniOdmorPosto > 500) {
        this.employee.TjedniOdmorPosto = 0
      }
      this.calculateHours()
    },
    'employee.NeradniBlagdanPosto' () {
      if (!this.employee.NeradniBlagdanPosto) {
        this.employee.NeradniBlagdanPosto = 0
      }
      if (this.employee.NeradniBlagdanPosto > 500) {
        this.employee.NeradniBlagdanPosto = 0
      }
      this.calculateHours()
    },
    'employee.GodisnjiOdmorPosto' () {
      if (!this.employee.GodisnjiOdmorPosto) {
        this.employee.GodisnjiOdmorPosto = 0
      }
      if (this.employee.GodisnjiOdmorPosto > 500) {
        this.employee.GodisnjiOdmorPosto = 0
      }
      this.calculateHours()
    },
    'employee.BolovanjeDo42DanaPosto' () {
      if (!this.employee.BolovanjeDo42DanaPosto) {
        this.employee.BolovanjeDo42DanaPosto = 0
      }
      if (this.employee.BolovanjeDo42DanaPosto > 500) {
        this.employee.BolovanjeDo42DanaPosto = 0
      }
      this.calculateHours()
    },
    'employee.BolovanjeOd42DanaPosto' () {
      if (!this.employee.BolovanjeOd42DanaPosto) {
        this.employee.BolovanjeOd42DanaPosto = 0
      }
      if (this.employee.BolovanjeOd42DanaPosto > 500) {
        this.employee.BolovanjeOd42DanaPosto = 0
      }
      this.calculateHours()
    },
    'employee.RodiljniPosto' () {
      if (!this.employee.RodiljniPosto) {
        this.employee.RodiljniPosto = 0
      }
      if (this.employee.RodiljniPosto > 500) {
        this.employee.RodiljniPosto= 0
      }
      this.calculateHours()
    },
    'employee.PlaceniDopustPosto' () {
      if (!this.employee.PlaceniDopustPosto) {
        this.employee.PlaceniDopustPosto = 0
      }
      if (this.employee.PlaceniDopustPosto > 500) {
        this.employee.PlaceniDopustPosto = 0
      }
      this.calculateHours()
    },
    'employee.NeplaceniDopustPosto' () {
      if (!this.employee.NeplaceniDopustPosto) {
        this.employee.NeplaceniDopustPosto = 0
      }
      if (this.employee.NeplaceniDopustPosto > 500) {
        this.employee.NeplaceniDopustPosto = 0
      }
      this.calculateHours()
    },
    'employee.NenazocnostNaZahtjevRadnikaPosto' () {
      if (!this.employee.NenazocnostNaZahtjevRadnikaPosto) {
        this.employee.NenazocnostNaZahtjevRadnikaPosto = 0
      }
      if (this.employee.NenazocnostNaZahtjevRadnikaPosto > 500) {
        this.employee.NenazocnostNaZahtjevRadnikaPosto = 0
      }
      this.calculateHours()
    },
    'employee.ZastojKrivnjomPoslodavcaPosto' () {
      if (!this.employee.ZastojKrivnjomPoslodavcaPosto) {
        this.employee.ZastojKrivnjomPoslodavcaPosto = 0
      }
      if (this.employee.ZastojKrivnjomPoslodavcaPosto > 500) {
        this.employee.ZastojKrivnjomPoslodavcaPosto = 0
      }
      this.calculateHours()
    },
    'employee.StrajkPosto' () {
      if (!this.employee.StrajkPosto) {
        this.employee.StrajkPosto = 0
      }
      if (this.employee.StrajkPosto > 500) {
        this.employee.StrajkPosto = 0
      }
      this.calculateHours()
    },
    'employee.LockoutPosto' () {
      if (!this.employee.LockoutPosto
      ) {
        this.employee.LockoutPosto = 0
      }
      if (this.employee.LockoutPosto > 500) {
        this.employee.LockoutPosto = 0
      }
      this.calculateHours()
    },
    'employee.VrijemeMirovanjaRadnogOdnosaPosto' () {
      if (!this.employee.VrijemeMirovanjaRadnogOdnosaPosto) {
        this.employee.VrijemeMirovanjaRadnogOdnosaPosto = 0
      }
      if (this.employee.VrijemeMirovanjaRadnogOdnosaPosto > 500) {
        this.employee.VrijemeMirovanjaRadnogOdnosaPosto = 0
      }
      this.calculateHours()
    },
    'employee.Dodatno1Posto' () {
      if (!this.employee.Dodatno1Posto) {
        this.employee.Dodatno1Posto = 0
      }
      if (this.employee.Dodatno1Posto > 500) {
        this.employee.Dodatno1Posto = 0
      }
      this.calculateHours()
    },
    'employee.Dodatno2Posto' () {
      if (!this.employee.Dodatno2Posto) {
        this.employee.Dodatno2Posto = 0
      }
      if (this.employee.Dodatno2Posto > 500) {
        this.employee.Dodatno2Posto = 0
      }
      this.calculateHours()
    },
    'employee.FinTaxOnTaxVukovar' () {
      if (this.employee.FinTaxOnTaxVukovar) {
        this.employee.FinKoef1Desc = 'Uvećanje od 0,5% za svaku navr.god.radnog staža'
      }
      if (!this.employee.FinTaxOnTaxVukovar) {
        this.employee.FinKoef1Desc = ''
        this.employee.FinKoef1 = 0
      }
      this.calculateSal()
    },
    'employee.Porodiljnifinish' () {
      if (this.employee.Porodiljnifinish) {
        this.employee.Porodiljnisifra = '5203'
      }
      if (!this.employee.Porodiljnifinish) {
        this.employee.Porodiljnisifra = null
        this.employee.Porodiljnifinish = null
      }
    },
    'employee.Porodiljnistart' () {
      if (!this.employee.Porodiljnistart) {
        this.employee.Porodiljnisifra = null
        this.employee.Porodiljnistart = null
      }
    }

  },
  components: {
    SalariePrintCardEmpYear
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .v-input--checkbox .v-label {
    font-size: 10px;
  }
</style>
