<template>
  <div class="task">
    <v-progress-circular v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>

  <v-row>
    <v-col cols="12" sm="12" > 
      <v-card>
        <v-row align="center" class="list px-3 mx-auto">
          <v-col cols="4" sm="2">
            <v-select
              v-model="searchOption"
              :items="searchOptions"
              :label="langC.SearchBy"
              clearable
              @change="handleSearchOption"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field v-model="searchName" :label="langC.SearchBy"></v-text-field>
          </v-col>
          <v-col cols="12" sm="1">
            <v-btn small @click="page = 1; defaultInit();">
              {{langC.Search}}
            </v-btn>
          </v-col>
          <v-col cols="5" sm="4">
            <v-pagination
              v-model="page"
              :length="totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChange"
            ></v-pagination>
            
          </v-col>
          <v-col cols="4" sm="1" >
            Total:{{totalItems}}
          </v-col>
          <v-col cols="4" sm="1" >
            <v-select
              v-model="pageSize"
              :items="pageSizes"
              :label="langC.ItemsPerPage"
              @change="handlePageSizeChange"
            ></v-select>
        </v-col>
      </v-row>
     </v-card>
    </v-col>
  </v-row> 

  <v-row>
    <v-col cols="12" sm="12"> 
      <v-card>
        <v-card-title>
          {{ lang.SalarieList }}
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="documentId"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"
          >

          <template v-slot:[`item.Order`]="{ item }">
              <span class="title indigo--text">{{ parseInt(item.Order) }}</span>
          </template>
          <template v-slot:[`item.Locked`]="{ item }">
            <span v-if="item.Locked"><v-icon>mdi-check</v-icon></span>
          </template>
          <template v-slot:[`item.Month`]="{ item }">
            <span class="title purple--text">{{ parseInt(item.Month) }}</span>
          </template>
          <template v-slot:[`item.Year`]="{ item }">
            <span class="title green--text">{{ parseInt(item.Year) }}</span>
          </template>

          <template v-slot:[`item.DateOpen`]="{ item }">
              <span>{{ localDate(item.DateOpen) }}</span>
          </template>
          <template v-slot:[`item.NetoIsplaceDate`]="{ item }">
              <span>{{ (item.NetoIsplaceDate ? localDate(item.NetoIsplaceDate) : null) }}</span>
          </template>
          <template v-slot:[`item.DoprinosiPlaceniDate`]="{ item }">
              <span>{{ (item.DoprinosiPlaceniDate ? localDate(item.DoprinosiPlaceniDate) : null) }}</span>
          </template>
          <template v-slot:[`item.JoppdPoslanDate`]="{ item }">
              <span>{{ (item.JoppdPoslanDate ? localDate(item.JoppdPoslanDate) : null) }}</span>
          </template>

          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>
     </v-col>
    </v-row> 
  </div>
</template>

<script>
import SalarieService from '@/services/SalarieService'
import ledgerEN from './salarieDescEn'
import ledgerHR from './salarieDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import moneyFormat from '@/snippets/moneyFormat'
import dayjs from 'dayjs'

export default {
  name: 'salarielist',
  data () {
    return {
      documentName: '',
      documentNameLoc: '',
      docside: 0,
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        MLType: 1
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
      searchName: "",
      page: 1,
      totalPages: 0,
      pageSize: 30,
      pageSizes: [10, 30, 50],
      searchOption: '',
      searchOptions: [],
      totalItems: 0,
      bookTypeSelected: {},
      show: 0
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
        this.lang = ledgerEN
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = ledgerHR
        this.langC = commHr;
      }
    this.defaultInit()
  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    localDate(dateToFormat) {
      let ret1 = ''
      if (dateToFormat) {
        const haveYear = (dateToFormat.substr(0,4))
        const haveMonth = (dateToFormat.substr(5,2))
        const haveDay = (dateToFormat.substr(8,2))
        ret1 =haveDay + '.' +haveMonth + '.' + haveYear
      }
      return ret1
    },
    async defaultInit() {
      this.$store.dispatch('seteditItem', 0)
      this.headers = []
      this.searchOptions = []
       
      const query = this.setQuery()
      if (query.searchItem && this.searchName) {
        query.searchName =  this.searchName.trim()
        await this.$store.dispatch('setOnPage', query)
      }
      if (query.searchItem && query.searchName) {
        this.searchOption = query.searchItem
        this.searchName = query.searchName 
      }
      this.saving = true
 
      await SalarieService.indexlist(query)
      .then(res => {
        this.items = res.data.salaries ? res.data.salaries : []
        this.totalItems = res.data.totalPages
        this.totalPages = parseInt(res.data.totalPages / this.pageSize) + 1
      })
      .catch(err => {
        this.havealert = !this.havealert
        this.havemssgalert = 'Error' + err
      })
      this.saving = false
      this.documentName = this.$store.state.documentActiv
      this.documentNameLoc = this.$store.state.documentActivLoc

      this.headers.push(
        {
          text: 'R.b.', align: 'left', sortable: true, value: 'Order', width: '40px'
        },
        { text: 'Vrsta', align: 'left', sortable: true, value: 'FreeF7', width: '110px' },
        { text: 'Vrsta', align: 'left', sortable: true, value: 'FreeF8', width: '60px' },
        { text: this.lang.Month, align: 'center', sortable: true, value: 'Month', width: '40px' },
        { text: this.lang.Year, align: 'left', sortable: true, value: 'Year', width: '40px' },       
        { text: 'Br.zap.', align: 'left', sortable: true, value: 'NumberOfEmp', width: '60px' },
        { text: this.lang.HoursNormal, align: 'center', value: 'HoursNormal', width: '70px' },
        { text: this.lang.HoursHoliday, align: 'left', sortable: true, value: 'HoursHoliday', width: '70px' },
        { text: 'Datum kre.', align: 'right', sortable: true, value: 'DateOpen', width: '80px' },
        { text: 'Neto ispl.', align: 'right', sortable: true, value: 'NetoIsplaceDate', width: '80px' },
        { text: 'Doprinosi pl.', align: 'right', sortable: true, value: 'DoprinosiPlaceniDate', width: '80px' },
        { text: 'Jopp poslan', align: 'right', sortable: true, value: 'JoppdPoslanDate', width: '80px' },
        { text: this.lang.Locked, align: 'left', sortable: true, value: 'Locked', width: '40px' },
        { text: 'DbId', value: 'id', width: '60px' }
      )
      this.searchOptions.push(
        {
          text: this.lang.Month, align: 'left', sortable: true, value: 'BookID', width: '60px'
        },
        { text: this.lang.Year, align: 'left', sortable: true, value: 'Year', width: '50px' },
        { text: this.lang.HoursNormal, align: 'center', value: 'HoursNormal', width: '100px' },
      )
    },
    async navigateTo (params) {
      console.log('Params ', params)
      const index = this.items.indexOf(params)
      const head = {...this.items[index]}
      head.documentId = params.id
      head.documentName = this.$store.state.documentActiv
      head.documentIdGeneral = this.$store.state.companyName + '-' + this.$store.state.businessYear + '-' + this.$store.state.documentActiv + '-' + params.id.toString()  
      await this.$store.dispatch('setDocumentActivHead', head)
      //await this.$store.dispatch('setDocumentActivHead', this.items[index])
      await this.$store.dispatch('seteditItem', 1)
      this.$store.dispatch('setretPath', 'salarie')
      await this.$store.dispatch('setDatabaseDocID', params.id)
      this.$router.push({
        name: 'salariedetail'
      })
    },
    
    // handlePageChange(value) {
    //   this.page = value;
    //   this.defaultInit();
    // },
    // handlePageSizeChange(size) {
    //   this.pageSize = size;
    //   this.page = 1;
    //   this.defaultInit();
    // },
    // handleSearchOption(option) {
    //   this.searchOption = option
    // },
    // setQuery() {
    //   const query = {}
    //   if (this.$store.state.companyid) {
    //     query.CompanyId = this.$store.state.companyid
    //   }
    //   if (this.$store.state.businessYear) {
    //     query.BusinessYear = this.$store.state.businessYear
    //   }
    //   if (this.$store.state.documentActiv) {
    //     query.documentName = this.$store.state.documentActiv
    //   }
    //   if (this.$store.state.documentSide) {
    //     this.docSide = this.$store.state.documentSide
    //     query.MLType = this.$store.state.documentSide
    //   }
    //   // if (this.$store.state.bookTypeSelected) {
    //   //   query.BookTypeID = this.$store.state.bookTypeSelected.value
    //   // }
    //   query.searchName = this.searchName ? this.searchName.trim() : null
    //   query.page = this.page ? this.page - 1 : 0
    //   query.pageSize = this.pageSize ? this.pageSize : 30
    //   query.searchItem = this.searchOption ? this.searchOption : null
    //   return query
    // },
    async handlePageChange(value) {      
      const query = this.$store.state.onPage
      query.page = value - 1;
      this.page = value
      await this.$store.dispatch('setOnPage', query)
      this.defaultInit();
    },

    async handlePageSizeChange(size) {
      const query = this.$store.state.onPage
      query.pageSize = size;
      query.page = 1;
      this.pageSize = size
      await this.$store.dispatch('setOnPage', query)
      this.defaultInit();
    },

    async handleSearchOption(option) {
      this.searchOption = option
      const query = this.$store.state.onPage
      query.searchItem = option
      query.page = 0
      await this.$store.dispatch('setOnPage', query)
    },

    setQuery() {
      const haveOnPage = this.$store.state.onPage
      const query = {}
      if (this.$store.state.companyid) {
        query.CompanyId = this.$store.state.companyid
      }
      // if (this.$store.state.businessYear) {
      //   query.BusinessYear = this.$store.state.businessYear
      // }
      query.searchName = haveOnPage.searchName ? haveOnPage.searchName.trim() : null
      query.page = haveOnPage.page ? haveOnPage.page : 0
      this.page  = query.page + 1
      query.pageSize = haveOnPage.pageSize ? haveOnPage.pageSize : 30
      this.pageSize = query.pageSize
      query.searchItem = haveOnPage.searchItem ? haveOnPage.searchItem : null
      return query
    },
    async defaultQuery() {
      const query = {}
      if (this.$store.state.companyid) {
        query.CompanyId = this.$store.state.companyid
      }
      // if (this.$store.state.businessYear) {
      //   query.BusinessYear = this.$store.state.businessYear
      // }
      query.searchName = null
      query.page =  0
      query.pageSize =  30
      query.searchItem =  null
      await this.$store.dispatch('setOnPage', query)
      this.searchName = ""
      this.page = 1
      this.totalPages = 0
      this.pageSize = 30
      this.mainQuery = query
    },
    async initAgain () {
      this.defaultQuery()
      this.defaultInit()
    }
  },
  computed: {

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
