<template>

    <v-row dense>
      <v-col cols="12" sm="8" xs="8" md="8"  lg="8" xl="8" class="mr-2">
        Datum JOOPD: <input type="date" v-model="joppddate" />
      </v-col>
      <v-col cols="12" sm="3" xs="3" md="3"  lg="3" xl="3">
        <h2>{{joppd}}</h2>
      </v-col>
    </v-row>

</template>

<script>

import {months, years} from '@/snippets/allSnippets.js'
import dayjs from 'dayjs'

export default {
  name: 'joppdnumber',
  data () {
    return {
      msg: '',
      lang: {},
      langC: {},
      locMonths: [],
      locYears: [],
      monthSelected: null,
      yearSelected: null,
      empSelected: null,
      emplys: [],
      newWH: {},
      newWorkHours: [],
      mainQuery: {},
      days:[],
      refresh: 0,
      saving: false,
      postNew: false,
      workDays: 0,
      holiDays: 0,
      salarieID: null,
      updateObj: {},
      modal1: false,
      dialog1: false,
      joppddate: null,
      joppd: 0
    }
  },
  beforeMount() {
    this.joppddate = dayjs().format('YYYY-MM-DD')
  },
  mounted () {

    if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
    }
    if (this.$store.state.businessYear) {
      this.mainQuery.businessYear = this.$store.state.businessYear
    }
    this.locMonths = months()
    this.locYears = years()
    this.calculateJoppd()    
  },
  methods: {
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },
    calculateJoppd() {
      // const y = this.joppddate.substr(0,4).substr(2,2)
      // const day = dayjs(this.joppddate).dayOfYear()
      const now = new Date(this.joppddate);
      const start = new Date(now.getFullYear(), 0, 0);
      const diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
      const oneDay = 1000 * 60 * 60 * 24;
      const day = Math.floor(diff / oneDay);
     // const y = new Date().getFullYear().toString().substring(2)
      const y = this.joppddate.toString().substring(0,4).slice(-2)

      let newDay = day.toString()
      if (day.toString().length === 1) {
        newDay = '00' + day.toString()
      }
      if (day.toString().length === 2) {
        newDay = '0' + day.toString()
      }
      this.joppd =  y + newDay
    },

  },
  computed: {

  },
  watch: {
    'joppddate' () {
      if (!this.joppddate) {
        this.joppd = '00000'
      }
      if (this.joppddate) {
        this.calculateJoppd()
      }
    }
  },
  components: {

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
