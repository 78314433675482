import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login'
import Crm from '@/components/crm'
import Home from '@/components/Home'
import CompIndex from '@/components/Companies/CompIndex'
import CompCreate from '@/components/Companies/CompCreate'
import CompCreateUser from '@/components/Companies/CompCreateUser'
import CompDelete from '@/components/Companies/CompDelete'
import CompEdit from '@/components/Companies/CompEdit'
import CompView from '@/components/Companies/CompView'
import CompSelect from '@/components/Companies/CompSelect'
import LanguageSelect from '@/components/Companies/LanguageSelect'
import CompUserCRUD from '@/components/Companies/CompUserCRUD'
import CompBusinessYearCRUD from '@/components/Companies/CompBusinessYearCRUD'
import CompBusinessYearSelect from '@/components/Companies/CompBusinessYearSelect'
import CalendarCRUD from '@/components/Calendar/CalendarCRUD'
import UserCRUD from '@/components/Users/UserCRUD'
import UserRegister from '@/components/Users/UserRegister'
import WHIndex from '@/components/WorkHour/WHIndex'
import WhCRUD from '@/components/WorkHour/WhCRUD'
import EmployeeIndex from '@/components/Employees/EmployeeIndex'
import EmployeeCreate from '@/components/Employees/EmployeeCreate'
import EmployeeDelete from '@/components/Employees/EmployeeDelete'
import EmployeeEdit2 from '@/components/Employees/EmployeeEdit2'
import EmployeeEdit3 from '@/components/Employees/EmployeeEdit3'
import EmployeeEdit3Other from '@/components/Employees/EmployeeEdit3Other'
import EmployeeList2 from '@/components/Employees/EmployeeList2'
import EmployeeAnalysis from '@/components/Employees/EmployeeAnalysis'
import EmployeeView from '@/components/Employees/EmployeeView'
import EmplDayIndex from '@/components/Employees/EmplDayIndex'
import EmplDayCreate from '@/components/Employees/EmplDayCreate'
import EmplDayDelete from '@/components/Employees/EmplDayDelete'
import EmplDayEdit from '@/components/Employees/EmplDayEdit'
import EmplDayView from '@/components/Employees/EmplDayView'
import GroupIndex from '@/components/Group/GroupIndex'
import GroupCreate from '@/components/Group/GroupCreate'
import GroupDelete from '@/components/Group/GroupDelete'
import GroupEdit from '@/components/Group/GroupEdit'
import GroupView from '@/components/Group/GroupView'
import ItemIndex from '@/components/Item/ItemIndex'
import ItemCreate from '@/components/Item/ItemCreate'
import ItemDelete from '@/components/Item/ItemDelete'
import ItemEdit from '@/components/Item/ItemEdit'
import ItemView from '@/components/Item/ItemView'
import PartnerIndex from '@/components/Partner/PartnerIndex'
import PartnerCreate from '@/components/Partner/PartnerCreate'
import PartnerCreate2 from '@/components/Partner/PartnerCreate2'
import PartnerDelete from '@/components/Partner/PartnerDelete'
import PartnerView from '@/components/Partner/PartnerView'
import WareHouseMarkCRUD from '@/components/WareHouse/WareHouseMarkCRUD'
import WareHouseUserCRUD from '@/components/WareHouse/WareHouseUserCRUD'
import TaskIndex from '@/components/Task/TaskIndex'
import TaskCreate from '@/components/Task/TaskCreate'
import TaskCreateEmployee from '@/components/Task/TaskCreateEmployee'
import TaskDelete from '@/components/Task/TaskDelete'
import TaskAnalysis from '@/components/Task/TaskAnalysis'
import TaskTypeCRUD from '@/components/Task/TaskTypeCRUD'
import TaskDepartmentCRUD from '@/components/Task/TaskDepartmentCRUD'
import BankAccountIndex from '@/components/BankAccount/BankAccountIndex'
import BankAccountCreateEdit from '@/components/BankAccount/BankAccountCreateEdit'
import BankAccountDelete from '@/components/BankAccount/BankAccountDelete'
import BankAccountView from '@/components/BankAccount/BankAccountView'
import DocumentIndex from '@/components/Document/DocumentIndex'
import DocumentCreate from '@/components/Document/DocumentCreate'
import DocumentCreateService from '@/components/Document/DocumentCreateService'
import DocumentCopy from '@/components/Document/DocumentCopy'
import DocumentDelete from '@/components/Document/DocumentDelete'
import DocumentView from '@/components/Document/DocumentView'
import DocumentView3 from '@/components/Document/DocumentView3'
import DocumentPrint from '@/components/Document/DocumentPrint3'
import DocumentPrint4 from '@/components/Document/DocumentPrint4'
import DocumentPrint5 from '@/components/Document/DocumentPrint5'
import DocumentPdf from '@/components/Document/DocumentPdf'
import DocumentDetailCreate from '@/components/Document/DocumentDetailCreate'
import DocumentDetailCreate2 from '@/components/Document/DocumentDetailCreate2'
import DocumentDetailCreateEdit3 from '@/components/Document/DocumentDetailCreateEdit3'
import DocumentDetailUpload from '@/components/Document/DocumentDetailUpload'
import DocumentPOS from '@/components/Document/DocumentPOS'
import DocumentPOSAgain from '@/components/Document/DocumentPOSAgain'
import DocumentPOSList from '@/components/Document/DocumentPOSList'
import DocumentPurchaseAnalysis from '@/components/Document/DocumentPurchaseAnalysis'
import DocumentSalesAnalysis from '@/components/Document/DocumentSalesAnalysis'
import DocumentProductionIndex from '@/components/Document/DocumentProductionIndex'
import DocumentStatusCRUD from '@/components/Document/DocumentStatusCRUD'
import Account1Index from '@/components/MainLedger/Account1Index'
import Account2Index from '@/components/MainLedger/Account2Index'
import Account3Index from '@/components/MainLedger/Account3Index'
import Account4Index from '@/components/MainLedger/Account4Index'
import MainLedgerIndex from '@/components/MainLedger/MainLedgerIndex'
import MainLedgerIndex3 from '@/components/MainLedger/MainLedgerIndex3'
import MainLedgerIndex4 from '@/components/MainLedger/MainLedgerIndex4'
import MainLedgerIndexVAT from '@/components/MainLedger/MainLedgerIndexVAT'
import MainLedgerCreate from '@/components/MainLedger/MainLedgerCreate'
import MainLedgerCreate3 from '@/components/MainLedger/MainLedgerCreate3'
import MainLedgerCreateVAT from '@/components/MainLedger/MainLedgerCreateVAT'
import MainLedgerDelete from '@/components/MainLedger/MainLedgerDelete'
import MainLedgerView from '@/components/MainLedger/MainLedgerView'
import MainLedgerCompindex from '@/components/MainLedger/MainLedgerCompindex'
import MainLedgerPrint from '@/components/MainLedger/MainLedgerPrint'
import MainLedgerUpload from '@/components/MainLedger/MainLedgerUpload'
import MainLedgerDetailIndex from '@/components/MainLedger/MainLedgerDetailIndex'
import MainLedgerDetailList from '@/components/MainLedger/MainLedgerDetailList'
import MainLedgerDetailCreate from '@/components/MainLedger/MainLedgerDetailCreate'
import MainLedgerAnalysis from '@/components/MainLedger/MainLedgerAnalysis'
import MainLedgerPay from '@/components/MainLedger/MainLedgerPay'
import MainLedgerReports from '@/components/MainLedger/MainLedgerReports'
import MainLedgerLLA from '@/components/MainLedger/MainLedgerLLA'
import MainBookIndex from '@/components/MainBook/MainBookIndex'
import MainBookCreate from '@/components/MainBook/MainBookCreate'
import MainLedgerProjectTypeCRUD from '@/components/MainLedger/MainLedgerProjectTypeCRUD'
import MainBookCompIndex from '@/components/MainBookComp/MainBookCompIndex'
import MainBookCompCreate from '@/components/MainBookComp/MainBookCompCreate'
import MainBookCompPSList from '@/components/MainBookComp/MainBookCompPSList'
import MainBookCompPSCreate from '@/components/MainBookComp/MainBookCompPSCreate'
import TravelOrderIndex from '@/components/TravelOrder/TravelOrderIndex'
import TravelOrderCreate from '@/components/TravelOrder/TravelOrderCreate'
import SerialsIndex from '@/components/Serials/SerialsIndex'
import SalarieDetailIndex from '@/components/Salarie/SalarieDetailIndex'
import SalarieIndex from '@/components/Salarie/SalarieIndex'
import SalarieTaxFreeIndex from '@/components/Salarie/SalarieTaxFreeIndex'
import JoppdBIndex from '@/components/Salarie/JoppdBIndex'
import JoppdAIndex from '@/components/Salarie/JoppdAIndex'
import JoppdADetail from '@/components/Salarie/JoppdADetail'
import JoppdBDetail from '@/components/Salarie/JoppdBDetail'
import FormIndex from '@/components/Form/FormIndex'
import FormList from '@/components/Form/FormList'
import FormHeadCreate from '@/components/Form/FormHeadCreate'
import FormItemsCRUD from '@/components/Form/FormItemsCRUD'
import CardTypeIndex from '@/components/CardType/CardTypeIndex'
import CityIndex from '@/components/City/CityIndex'
import BankOrderIndex from '@/components/BankOrder/BankOrderIndex'
import CashDeskIndex from '@/components/CashDesk/CashDeskIndex'
import OpzstatHeadIndex from '@/components/Form/OpzstatHeadIndex'
import OpzstatHeadDetail from '@/components/Form/OpzstatHeadDetail'
import AppSettings from '@/components/AppSettings'
import AppInProgress from '@/components/AppInProgress'

Vue.use(Router)

export default new Router({
  routes: [{
      path: '/',
      name: 'Login',
      component: Login
    },
    {
      path: '/crm',
      name: 'crm',
      component: Crm,
      children: [{
          path: 'home',
          name: 'home',
          component: Home
        },
        {
          path: 'user',
          name: 'user',
          component: UserCRUD
        },
        {
          path: 'calendar',
          name: 'calendar',
          component: CalendarCRUD
        },
        {
          path: 'user/register',
          name: 'userregister',
          component: UserRegister
        },
        {
          path: 'company',
          name: 'company',
          component: CompIndex
        },
        {
          path: 'company/new',
          name: 'companycreate',
          component: CompCreate
        },
        {
          path: 'company/user',
          name: 'companycreateuser',
          component: CompCreateUser
        },
        {
          path: 'company/delete',
          name: 'companydelete',
          component: CompDelete
        },
        {
          path: 'company/view',
          name: 'companyview',
          component: CompView
        },
        {
          path: 'company/edit',
          name: 'companyedit',
          component: CompEdit
        },
        {
          path: 'company/select',
          name: 'companyselect',
          component: CompSelect
        },
        {
          path: 'company/langselect',
          name: 'languageselect',
          component: LanguageSelect
        },
        {
          path: 'companyuser',
          name: 'companyuser',
          component: CompUserCRUD
        },
        {
          path: 'companybusinessyear',
          name: 'companybusinessyear',
          component: CompBusinessYearCRUD
        },
        {
          path: 'company/companybusinessyearselect',
          name: 'companybusinessyearselect',
          component: CompBusinessYearSelect
        },
        {
          path: 'cardtype',
          name: 'cardtype',
          component: CardTypeIndex
        },
        {
          path: 'city',
          name: 'city',
          component: CityIndex
        },
        {
          path: 'bankorder',
          name: 'bankorder',
          component: BankOrderIndex
        },
        {
          path: 'workhour',
          name: 'workhour',
          component: WHIndex
        },
        {
          path: 'workhour/crud',
          name: 'whCRUD',
          component: WhCRUD,
          props: true
        },
        {
          path: 'employee',
          name: 'employee',
          component: EmployeeIndex
        },
        {
          path: 'employee/new',
          name: 'employeecreate',
          component: EmployeeCreate
        },
        {
          path: 'employee/delete',
          name: 'employeedelete',
          component: EmployeeDelete
        },
        {
          path: 'employee/view',
          name: 'employeeview',
          component: EmployeeView
        },
        {
          path: 'employee/edit2',
          name: 'employeedit2',
          component: EmployeeEdit2
        },
        {
          path: 'employee/edit3',
          name: 'employeedit3',
          component: EmployeeEdit3
        },
        {
          path: 'employee/editother',
          name: 'employeeditother',
          component: EmployeeEdit3Other
        },
        {
          path: 'employee/list2',
          name: 'employeelist2',
          component: EmployeeList2
        },
        {
          path: 'emplday',
          name: 'emplday',
          component: EmplDayIndex
        },
        {
          path: 'emplday/new',
          name: 'empldaycreate',
          component: EmplDayCreate
        },
        {
          path: 'emplday/delete',
          name: 'empldaydelete',
          component: EmplDayDelete
        },
        {
          path: 'emplday/view',
          name: 'empldayview',
          component: EmplDayView
        },
        {
          path: 'emplday/employeeanalysis',
          name: 'employeeanalysis',
          component: EmployeeAnalysis
        },
        {
          path: 'emplday/edit',
          name: 'empldayedit',
          component: EmplDayEdit
        },
        {
          path: 'group',
          name: 'group',
          component: GroupIndex
        },
        {
          path: 'group/new',
          name: 'groupcreate',
          component: GroupCreate
        },
        {
          path: 'group/delete',
          name: 'groupdelete',
          component: GroupDelete
        },
        {
          path: 'group/view',
          name: 'groupview',
          component: GroupView
        },
        {
          path: 'group/edit',
          name: 'groupedit',
          component: GroupEdit
        },
        {
          path: 'item',
          name: 'item',
          component: ItemIndex
        },
        {
          path: 'item/new',
          name: 'itemcreate',
          component: ItemCreate
        },
        {
          path: 'item/delete',
          name: 'itemdelete',
          component: ItemDelete
        },
        {
          path: 'item/view',
          name: 'itemview',
          component: ItemView
        },
        {
          path: 'item/edit',
          name: 'itemedit',
          component: ItemEdit
        },
        {
          path: 'partner',
          name: 'partner',
          component: PartnerIndex
        },
        {
          path: 'partner/new',
          name: 'partnercreate',
          component: PartnerCreate
        },
        {
          path: 'partner/new2',
          name: 'partnercreate2',
          component: PartnerCreate2
        },
        {
          path: 'partner/delete',
          name: 'partnerdelete',
          component: PartnerDelete
        },
        {
          path: 'partner/view',
          name: 'partnerview',
          component: PartnerView
        },
        {
          path: 'warehousemark',
          name: 'warehousemark',
          component: WareHouseMarkCRUD
        },
        {
          path: 'warehouseuser',
          name: 'warehouseuser',
          component: WareHouseUserCRUD
        },
        {
          path: 'task',
          name: 'task',
          component: TaskIndex
        },
        {
          path: 'task/new',
          name: 'taskcreate',
          component: TaskCreate
        },
        {
          path: 'task/newemployee',
          name: 'taskcreateemployee',
          component: TaskCreateEmployee
        },
        {
          path: 'task/delete',
          name: 'taskdelete',
          component: TaskDelete
        },
        {
          path: 'task/analysis',
          name: 'taskanalysis',
          component: TaskAnalysis
        },
        {
          path: 'tasktype',
          name: 'tasktype',
          component: TaskTypeCRUD
        },
        {
          path: 'taskdepartment',
          name: 'taskdepartment',
          component: TaskDepartmentCRUD
        },
        {
          path: 'bankaccount',
          name: 'bankaccount',
          component: BankAccountIndex
        },
        {
          path: 'bankaccount/new',
          name: 'bankaccountcreateedit',
          component: BankAccountCreateEdit
        },
        {
          path: 'bankaccount/delete',
          name: 'bankaccountdelete',
          component: BankAccountDelete
        },
        {
          path: 'bankaccount/view',
          name: 'bankaccountview',
          component: BankAccountView
        },
        {
          path: 'document',
          name: 'document',
          component: DocumentIndex
        },
        {
          path: 'document/new',
          name: 'documentcreate',
          component: DocumentCreate
        },
        {
          path: 'document/newservicevehicle',
          name: 'documentcreateservice',
          component: DocumentCreateService
        },
        {
          path: 'document/copy',
          name: 'documentcopy',
          component: DocumentCopy
        },
        {
          path: 'document/delete',
          name: 'documentdelete',
          component: DocumentDelete
        },
        {
          path: 'document/view',
          name: 'documentview',
          component: DocumentView
        },
        {
          path: 'document/view3',
          name: 'documentview3',
          component: DocumentView3
        },
        {
          path: 'document/print',
          name: 'documentprint',
          component: DocumentPrint
        },
        {
          path: 'document/print4',
          name: 'documentprint4',
          component: DocumentPrint4
        },
        {
          path: 'document/print5',
          name: 'documentprint5',
          component: DocumentPrint5
        },
        {
          path: 'document/purchaseanalysis',
          name: 'documentpurchaseanalysis',
          component: DocumentPurchaseAnalysis
        },
        {
          path: 'document/salesanalysis',
          name: 'documentsalesanalysis',
          component: DocumentSalesAnalysis
        },
        {
          path: 'document/pdf',
          name: 'documentpdf',
          component: DocumentPdf
        },
        {
          path: 'document/production',
          name: 'documentproduction',
          component: DocumentProductionIndex
        },
        {
          path: 'document/pos',
          name: 'documentpos',
          component: DocumentPOS
        },
        {
          path: 'document/poslist',
          name: 'documentposlist',
          component: DocumentPOSList
        },
        {
          path: 'document/posagain',
          name: 'documentposagain',
          component: DocumentPOSAgain
        },
        {
          path: 'documentstatus',
          name: 'documentstatus',
          component: DocumentStatusCRUD
        },
        {
          path: 'documentdetail/new',
          name: 'documentdetailcreate',
          component: DocumentDetailCreate
        },
        {
          path: 'documentdetail/new2',
          name: 'documentdetailcreate2',
          component: DocumentDetailCreate2
        },
        {
          path: 'documentdetail/new3',
          name: 'documentdetailcreateedit3',
          component: DocumentDetailCreateEdit3
        },
        {
          path: 'documentdetail/upload',
          name: 'documentdetailupload',
          component: DocumentDetailUpload
        },
        {
          path: 'account1',
          name: 'account1',
          component: Account1Index
        },
        {
          path: 'account2',
          name: 'account2',
          component: Account2Index
        },
        {
          path: 'account3',
          name: 'account3',
          component: Account3Index
        },
        {
          path: 'account4',
          name: 'account4',
          component: Account4Index
        },
        {
          path: 'mainledger',
          name: 'mainledger',
          component: MainLedgerIndex
        },
        {
          path: 'mainledger3',
          name: 'mainledger3',
          component: MainLedgerIndex3
        },
        {
          path: 'mainledger4',
          name: 'mainledger4',
          component: MainLedgerIndex4
        },
        {
          path: 'mainledgervat',
          name: 'mainledgervat',
          component: MainLedgerIndexVAT
        },
        {
          path: 'mainledger/new',
          name: 'mainledgercreate',
          component: MainLedgerCreate
        },
        {
          path: 'mainledger/new3',
          name: 'mainledgercreate3',
          component: MainLedgerCreate3
        },
        {
          path: 'mainledger/newvat',
          name: 'mainledgercreatevat',
          component: MainLedgerCreateVAT
        },
        {
          path: 'mainledger/delete',
          name: 'mainledgerdelete',
          component: MainLedgerDelete
        },
        {
          path: 'mainledger/view',
          name: 'mainledgerview',
          component: MainLedgerView
        },
        {
          path: 'mainledger/compindex',
          name: 'mainledgercompindex',
          component: MainLedgerCompindex
        },
        {
          path: 'mainledger/print',
          name: 'mainledgerprint',
          component: MainLedgerPrint
        },
        {
          path: 'mainledger/upload',
          name: 'mainledgerupload',
          component: MainLedgerUpload
        },
        {
          path: 'mainledgerdetail',
          name: 'mainledgerdetail',
          component: MainLedgerDetailIndex
        },
        {
          path: 'mainledgerdetaillist',
          name: 'mainledgerdetaillist',
          component: MainLedgerDetailList
        },
        {
          path: 'mainledgerdetailcreate',
          name: 'mainledgerdetailcreate',
          component: MainLedgerDetailCreate
        },
        {
          path: 'mainledger/analysis',
          name: 'mainledgeranalysis',
          component: MainLedgerAnalysis
        },
        {
          path: 'mainledger/reports',
          name: 'mainledgerreports',
          component: MainLedgerReports
        },
        {
          path: 'mainledger/pay',
          name: 'mainledgerpay',
          component: MainLedgerPay
        },
        {
          path: 'mainledgerlla',
          name: 'mainledgerLLA',
          component: MainLedgerLLA
        },
        {
          path: 'mainledgerprojecttype',
          name: 'mainledgerprojecttype',
          component: MainLedgerProjectTypeCRUD
        },
        {
          path: 'mainbook',
          name: 'mainbook',
          component: MainBookIndex
        },
        {
          path: 'mainbookcreate',
          name: 'mainbookcreate',
          component: MainBookCreate
        },
        {
          path: 'mainbookcomp',
          name: 'mainbookcomp',
          component: MainBookCompIndex
        },
        {
          path: 'mainbookcompcreate',
          name: 'mainbookcompcreate',
          component: MainBookCompCreate
        },
        {
          path: 'mainbookcomppscreate',
          name: 'mainbookcomppscreate',
          component: MainBookCompPSCreate
        },
        {
          path: 'mainbookcomppslist',
          name: 'mainbookcomppslist',
          component: MainBookCompPSList
        },
        {
          path: 'travelorder',
          name: 'travelorder',
          component: TravelOrderIndex
        },
        {
          path: 'travelordercreate',
          name: 'travelordercreate',
          component: TravelOrderCreate
        },
        {
          path: 'serials',
          name: 'serials',
          component: SerialsIndex
        },
        {
          path: 'salarie',
          name: 'salarie',
          component: SalarieIndex
        },
        {
          path: 'salariedetail',
          name: 'salariedetail',
          component: SalarieDetailIndex
        },
        {
          path: 'salarietaxfree',
          name: 'salarietaxfree',
          component: SalarieTaxFreeIndex
        },
        {
          path: 'joppda',
          name: 'joppda',
          component: JoppdAIndex
        },
        {
          path: 'joppdadetail',
          name: 'joppdadetail',
          component: JoppdADetail
        },
        {
          path: 'joppdb',
          name: 'joppdb',
          component: JoppdBIndex
        },
        {
          path: 'joppdbdetail',
          name: 'joppdbdetail',
          component: JoppdBDetail
        },
        {
          path: 'cashdesk',
          name: 'cashdesk',
          component: CashDeskIndex
        },
        {
          path: 'formindex',
          name: 'formindex',
          component: FormIndex
        },
        {
          path: 'formlist',
          name: 'formlist',
          component: FormList,
          props: true
        },
        {
          path: 'formheadcreate',
          name: 'formheadcreate',
          component: FormHeadCreate,
          props: true
        },
        {
          path: 'formitemscrud',
          name: 'formitemscrud',
          component: FormItemsCRUD,
          props: true
        },
        {
          path: 'opzstatheadindex',
          name: 'opzstatheadindex',
          component: OpzstatHeadIndex
        },
        {
          path: 'opzstatheaddetail',
          name: 'opzstatheaddetail',
          component: OpzstatHeadDetail
        },
        {
          path: 'appsettings',
          name: 'appsettings',
          component: AppSettings
        },
        {
          path: 'appinprogress',
          name: 'appinprogress',
          component: AppInProgress
        }
      ]
    }
  ]
})